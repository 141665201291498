export default function NotificationIcon() {
    return (
        <svg width="20" height="23" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.00039 14.4705C12.7937 14.4705 15.0112 13.8556 15.2254 11.3874C15.2254 8.92097 13.6794 9.07958 13.6794 6.05334C13.6794 3.68952 11.4388 1 8.00039 1C4.56194 1 2.32141 3.68952 2.32141 6.05334C2.32141 9.07958 0.775391 8.92097 0.775391 11.3874C0.9904 13.8649 3.2079 14.4705 8.00039 14.4705Z"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M10.0315 17.0273C8.87199 18.3149 7.06318 18.3301 5.89258 17.0273"
                stroke="white"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
}
