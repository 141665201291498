import { MappingType } from '@pages/components';
import _ from 'lodash';

export enum SortMode {
    ASC = 'asc',
    DESC = 'desc',
}

export interface SortCriterion<T> {
    property: keyof T;
    order: SortMode;
}

interface SortParams<T> {
    data: T[];
    orderBy: SortCriterion<T>[];
    dataTypes: { [key in keyof T]: DataTypes };
    valueMappings?: { [key in keyof T]?: MappingType };
}

export type DataTypes = 'string' | 'number' | 'date' | 'boolean' | 'object' | 'array' | 'enum' | 'any';

type SortFunction = (a: any, b: any, order: SortMode, valueMapping?: MappingType) => number;

const sortString: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const stringA = a.toString();
    const stringB = b.toString();
    return order === SortMode.ASC ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
};

const sortNumber: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    return order === SortMode.ASC ? a - b : b - a;
};

const sortDate: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const dateA = new Date(a).getTime();
    const dateB = new Date(b).getTime();
    return order === SortMode.ASC ? dateA - dateB : dateB - dateA;
};

const sortBoolean: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const orderMapping = order === SortMode.ASC ? [true, false] : [false, true];
    const indexA = orderMapping.indexOf(!!a);
    const indexB = orderMapping.indexOf(!!b);
    return indexA - indexB;
};

const sortObject: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const key = 'name';
    const stringA = a[key]?.toString() || '';
    const stringB = b[key]?.toString() || '';
    return order === SortMode.ASC ? stringA.localeCompare(stringB) : stringB.localeCompare(stringA);
};

const sortArray: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const lengthA = a.length;
    const lengthB = b.length;
    return order === SortMode.ASC ? lengthA - lengthB : lengthB - lengthA;
};

const sortEnum: SortFunction = (a, b, order, valueMapping) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    const mappedA = valueMapping?.[a] || 'N/A';
    const mappedB = valueMapping?.[b] || 'N/A';
    return order === SortMode.ASC ? mappedA.localeCompare(mappedB) : mappedB.localeCompare(mappedA);
};

const sortAny: SortFunction = (a, b, order) => {
    if (_.isNil(a) && _.isNil(b)) return 0;
    if (_.isNil(a)) return order === SortMode.ASC ? -1 : 1;
    if (_.isNil(b)) return order === SortMode.ASC ? 1 : -1;

    return sortString(a, b, order);
};

const sortFunctions: { [key in DataTypes]: SortFunction } = {
    string: sortString,
    number: sortNumber,
    date: sortDate,
    boolean: sortBoolean,
    object: sortObject,
    array: sortArray,
    enum: sortEnum,
    any: sortAny,
};

export const sortValueDataInColumnTable = <T>({ data, orderBy, dataTypes, valueMappings }: SortParams<T>): T[] => {
    return data.sort((a, b) => {
        for (const { property: id, order: currentOrder } of orderBy) {
            const dataType = dataTypes[id];
            const valueMapping = valueMappings?.[id];

            const valueA = a[id];
            const valueB = b[id];

            const isValueANull = valueA === null || valueA === undefined;
            const isValueBNull = valueB === null || valueB === undefined;

            if (isValueANull && isValueBNull) continue;
            if (isValueANull) return currentOrder === SortMode.ASC ? -1 : 1;
            if (isValueBNull) return currentOrder === SortMode.ASC ? 1 : -1;

            const comparison = sortFunctions[dataType](valueA, valueB, currentOrder, valueMapping);

            if (comparison !== 0) return comparison;
        }

        return 0;
    });
};
