import { CameraNotificationStatus } from '@EcamModel/model/CameraNotification';
import { Typography } from '@mui/material';
import { findKeyByValue } from '@pages/overview/smartFilterHighLight/configs';
import { idToFieldMapping } from '@pages/overview/smartFilterHighLight/helpers';
import color from '@theme/Colors';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../hooks/NotificationProvider';
import { hoverTextSx } from '../style/sx-props';
import LayoutNotificationItem, { NotificationItemProps } from './LayoutNotificationItem';

export default function CameraNotificationItem(props: NotificationItemProps) {
    const navigate = useNavigate();
    const { markRead, handleClose } = useNotification();
    const { item, index } = props;

    const isUnread = item?.Status === CameraNotificationStatus.Unread;
    const options = item?.Thresholds?.[0].Options;

    const [visibleOptions, setVisibleOptions] = useState(2);

    const handleMarkRead = () => {
        isUnread && markRead([item?.Id!]);
        handleClose();
        navigate(`/camera-overview`, {
            state: { cameraName: item?.Camera?.Name },
        });
    };

    return (
        <LayoutNotificationItem
            handleMarkRead={handleMarkRead}
            index={index}
            item={item}
            children={
                <>
                    {options?.slice(0, visibleOptions).map((item, index) => {
                        return (
                            <Typography key={index}>
                                [
                                <Typography variant="caption" fontWeight={500}>
                                    {findKeyByValue(idToFieldMapping, item?.Field)}
                                </Typography>
                                ] {item?.Condition}{' '}
                                {item?.Condition != 'Between' ? (
                                    <Span text={item?.Value as number} />
                                ) : (
                                    <Typography variant="caption">
                                        from <Span text={item?.Value![0]} /> to <Span text={item?.Value![1]} />{' '}
                                    </Typography>
                                )}{' '}
                                has a satisfaction value of <Span text={item?.CurrentValue as any} />
                            </Typography>
                        );
                    })}
                    {(options ?? [])?.length > visibleOptions && (
                        <Typography
                            variant="caption"
                            color={color.secondary}
                            onClick={(event) => {
                                event.stopPropagation();
                                setVisibleOptions((prev) => Math.min(prev + 2, (options ?? [])?.length!));
                                isUnread && markRead([item?.Id!], true);
                            }}
                            sx={hoverTextSx}
                        >
                            See more...({(options ?? [])?.length! - visibleOptions})
                        </Typography>
                    )}
                </>
            }
        />
    );
}

const Span = ({ text, _color }: { text: string | number; _color?: React.CSSProperties['color'] }) => {
    return (
        <Typography variant="caption" color={_color ?? color.secondary}>
            {text}
        </Typography>
    );
};
