import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function ChangeTypePhotoGeoZone(props: Props) {
    return (
        <svg
            width={props.width ?? '20px'}
            height={props.height ?? '20px'}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M2 11V9c0-3.5 2-5 5-5h10c3 0 5 1.5 5 5v6c0 3.5-2 5-5 5h-5"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5m6.5-5v5M2 15.5h5.34c.64 0 1.16.52 1.16 1.16v1.28"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.22 14.28 2 15.5l1.22 1.22m5.28 4.06H3.16c-.64 0-1.16-.52-1.16-1.16v-1.28"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="m7.281 22 1.22-1.22-1.22-1.22"
                stroke="#FFFFFF"
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
