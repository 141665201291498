import ArrowDownButtonEndIcon from '@assets/jsx-icon/ArrowDownButtonEndIcon';
import FilterIcon from '@assets/jsx-icon/FilterIcon';
import {
    Badge,
    Box,
    Button,
    ClickAwayListener,
    Fade,
    Grid,
    MenuItem,
    Popper,
    Stack,
    styled,
    SxProps,
    Theme,
    Typography,
} from '@mui/material';
import { colorHighlight } from '@pages/overview/smartFilterHighLight/helpers';
import color from '@theme/Colors';
import React, { ReactNode, useRef, useState } from 'react';

type BaseFilterPropsBase = {
    rightTitleContent?: React.ReactNode;
    handleResetFilter: () => void;
    filterChild?: React.ReactNode;
    baseStyle?: SxProps<Theme>;
    refreshFilter?: ReactNode;
    smartFilterFieldBadgeCount?: number;
    highlightFilterFieldBadgeCount?: number;
    isButtonAdvancedFilter?: boolean;
    menuButtonItems?: MenuButtonItem[];
    activeItemId?: number | null;
};

type BaseFilterProps = BaseFilterPropsBase &
    (
        | { isButtonAdvancedFilter: true; menuButtonItems: MenuButtonItem[] }
        | { isButtonAdvancedFilter?: false; menuButtonItems?: undefined }
    );

export interface MenuButtonItem {
    itemId?: number | null;
    title: string;
    badgeCount?: number;
    isDisabled?: boolean;
    onClick: () => void;
}

export default function FilterRoot(props: BaseFilterProps) {
    const anchorRef = useRef<HTMLButtonElement>(null);
    const [menuAnchorElement, setMenuAnchorElement] = useState<boolean>(false);

    const handleMenuButtonClick = () => {
        setMenuAnchorElement((prevOpen) => !prevOpen);
    };
    const handleClose = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setMenuAnchorElement(false);
    };

    return (
        <BoxRadiusGrey sx={{ ...props.baseStyle }}>
            <Grid container gap={2}>
                <GFlexRow item xs={12} justifyContent={props.rightTitleContent ? 'space-between' : 'left'}>
                    <GFlexRowCenter item gap={2}>
                        <FilterIcon />
                        <Typography>Filter</Typography>
                        {props.isButtonAdvancedFilter && props.menuButtonItems?.some((item) => item.onClick) && (
                            <>
                                <Button
                                    ref={anchorRef}
                                    onClick={handleMenuButtonClick}
                                    endIcon={
                                        <ArrowDownButtonEndIcon
                                            style={{
                                                marginBottom: '2px',
                                                transform: menuAnchorElement ? 'rotate(-180deg)' : 'rotate(0deg)',
                                                transition: 'transform 0.3s ease',
                                            }}
                                        />
                                    }
                                    sx={{
                                        padding: '0.25rem 1rem',
                                        fontSize: '0.75rem',
                                        lineHeight: '1rem',
                                    }}
                                    variant="contained"
                                >
                                    <Badge
                                        badgeContent={
                                            menuAnchorElement
                                                ? null
                                                : (props.highlightFilterFieldBadgeCount || 0) +
                                                  (props.smartFilterFieldBadgeCount || 0)
                                        }
                                        sx={commonBadgeStyle({
                                            backgroundColor: colorHighlight,
                                            textColor: color.textPrimary,
                                        })}
                                    >
                                        Advanced filter
                                    </Badge>
                                </Button>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <Popper
                                        anchorEl={anchorRef.current}
                                        open={menuAnchorElement}
                                        sx={{
                                            backgroundColor: color.white,
                                            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                            p: '4px 4px',
                                            minWidth: 135,
                                            transition: 'opacity 0.3s ease',
                                            zIndex: 15,
                                        }}
                                        placement="bottom-start"
                                        transition
                                    >
                                        {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={200}>
                                                <Stack direction="column" spacing={1} p={'4px'}>
                                                    {props.menuButtonItems?.map((item, index) => {
                                                        const isActive = props.activeItemId === item.itemId!;

                                                        return (
                                                            <MenuItem
                                                                key={index}
                                                                onClick={() => {
                                                                    item.onClick();
                                                                    setMenuAnchorElement(false);
                                                                }}
                                                                sx={{
                                                                    fontSize: '0.75rem',
                                                                    backgroundColor: isActive
                                                                        ? '#E5F5ED'
                                                                        : 'transparent',
                                                                    color: isActive ? color.success : 'inherit',
                                                                    transition: 'background-color 0.2s ease',
                                                                    px: 1,
                                                                    borderRadius: 1,
                                                                    ':hover': {
                                                                        backgroundColor: isActive
                                                                            ? '#E5F5ED'
                                                                            : color.grey200,
                                                                        color: isActive ? color.success : 'inherit',
                                                                    },
                                                                }}
                                                            >
                                                                {item.title}{' '}
                                                                {item.badgeCount !== undefined
                                                                    ? `(${item.badgeCount})`
                                                                    : undefined}
                                                            </MenuItem>
                                                        );
                                                    })}
                                                </Stack>
                                            </Fade>
                                        )}
                                    </Popper>
                                </ClickAwayListener>
                            </>
                        )}
                        <Button variant="cancelSmall" onClick={props.handleResetFilter}>
                            Reset filter
                        </Button>
                        {props.refreshFilter && <>{props.refreshFilter}</>}
                    </GFlexRowCenter>
                    {props.rightTitleContent}
                </GFlexRow>

                {props.filterChild}
            </Grid>
        </BoxRadiusGrey>
    );
}

export const BoxRadiusGrey = styled(Box)({
    width: '100%',
    background: color.grey100,
    borderRadius: 10,
    padding: 16,
});

export const GFlexRow = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export const GFlexRowCenter = styled(Grid)({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
});

export type BadgeStyleProps = {
    backgroundColor: string;
    textColor: string;
};

export const commonBadgeStyle = (props: BadgeStyleProps): SxProps<Theme> => ({
    '& .MuiBadge-badge': {
        right: -28,
        top: -3,
        border: `2px solid #FFFFFF`,
        padding: '0 4px',
        fontSize: 9,
        color: props.textColor,
        backgroundColor: props.backgroundColor,
    },
});
