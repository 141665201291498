/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import LogoIconWithName from './LogoIconWithName';
import { Avatar, Stack, Typography } from '@mui/material';
import LanguageSelect from '../LanguageSelect';
import { appConfig } from '@configs/index';
import color from '@theme/Colors';
import LogoIconWithName from './LogoIconWithName';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '@configs/authConfig';
import axios from 'axios';
import CameraNotifications from '@components/camera-notification';

type Props = {
    isShowLabelAccount: boolean;
    isShowLabelLanguage?: boolean;
};
export default function MyAppBar(props: Props) {
    const isShowMultiLanguage = appConfig.flagFeatures.multiLanguage;
    const { accounts, instance } = useMsal();
    const [photoData, setPhotoData] = useState<string>('');

    useEffect(() => {
        if (accounts.length) {
            getUserAvatar();
        }
    }, []);

    const getUserAvatar = async () => {
        instance
            .acquireTokenSilent({
                ...loginRequest,
                account: accounts[0],
            })
            .then((res) => {
                const apiURL = 'https://graph.microsoft.com/v1.0/me/photo/$value';
                axios(apiURL, {
                    headers: { Authorization: `Bearer ${res.accessToken}` },
                    responseType: 'arraybuffer',
                })
                    .then((response) => {
                        const reader = new FileReader();
                        reader.onloadend = () => {
                            const base64data = reader.result as string;
                            setPhotoData(base64data);
                        };
                        const blob = new Blob([response.data], { type: 'image/jpeg' });
                        reader.readAsDataURL(blob);
                    })
                    .catch((err) => {
                        setPhotoData('');
                    });
            });
    };

    return (
        <AppBar
            position="static"
            sx={{
                position: 'fixed',
                zIndex: (theme) => theme.zIndex.drawer + 100,
                width: '100vw',
                top: 0,
                background: color.appBarColor,
            }}
        >
            <Toolbar>
                <Stack flexDirection={'row'} justifyContent={'space-between'} width={'100%'}>
                    <Stack flexDirection={'row'}>
                        <LogoIconWithName />
                    </Stack>
                    <Stack
                        flexDirection={'row'}
                        sx={{
                            '& .MuiSvgIcon-root': {
                                color: color.grey600,
                                px: '5px',
                            },
                            '& .MuiInputBase-input': {
                                pr: '35px !important',
                            },
                            '& .MuiSelect-select': {
                                background: 'rgba(255, 255, 255, 0.1)',
                                borderRadius: '3px',

                                '& .MuiTypography-root': {
                                    display: 'none',
                                },
                                '&:focus': {
                                    background: 'rgba(255, 255, 255, 0.1) !important',
                                },
                            },
                        }}
                    >
                        {isShowMultiLanguage && <LanguageSelect isShowLabel={false} />}
                        {props.isShowLabelAccount && (
                            <Stack direction={'row'} alignItems={'center'}>
                                <CameraNotifications />

                                <Typography
                                    variant="body1"
                                    align="center"
                                    color={color.white}
                                    display={'inline-block'}
                                    minWidth={80}
                                >
                                    {/* {account ? account?.firstName + ' ' + account?.lastName : 'Admin'} */}
                                    {accounts[0]?.name ?? 'Admin'}
                                </Typography>
                                <IconButton
                                    size="large"
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="inherit"
                                >
                                    <Avatar
                                        sx={{ width: '32px', height: '32px' }}
                                        alt={accounts[0]?.name ?? 'A'}
                                        src={photoData}
                                    />
                                </IconButton>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            </Toolbar>
        </AppBar>
    );
}
