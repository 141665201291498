import { BaseModel, Paging } from '@Core';
import {
    Box,
    CircularProgress,
    Grow,
    Pagination as MuiPagination,
    Stack,
    SxProps,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Theme,
} from '@mui/material';
import ListNoData from '@pages/cameras/list/components/NoDataCameras';
import { ReactNode } from 'react';
import { BaseHead } from './tables';

type Props<T extends BaseModel> = {
    paging?: Paging<T>;
    styleContainer?: SxProps<Theme>;
    isLoading?: boolean;
    setPage?: (page: number) => void;
    renderedItem?: (item: T) => JSX.Element;
    noDataComponent?: ReactNode;
    tableHeaders?: string[];
    isCheckedScroll?: boolean;
    isVerificationPage?: boolean;
};

export const tableHeaders: string[] = ['Created date', 'Created by', 'Last update', 'Note'];

export default function BaseListNote<T extends BaseModel>(props: Props<T>) {
    const {
        paging,
        setPage,
        isLoading,
        styleContainer,
        renderedItem,
        noDataComponent,
        tableHeaders,
        isCheckedScroll,
        isVerificationPage,
    } = props;

    return (
        <Stack sx={{ ...styleContainer }}>
            {isLoading ? (
                <Stack
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    minHeight={isVerificationPage ? 100 : 300}
                >
                    <CircularProgress size={isVerificationPage ? 30 : 40} />
                </Stack>
            ) : (
                <>
                    {paging?.rows?.length ? (
                        <TableContainer
                            component={Box}
                            sx={
                                isCheckedScroll
                                    ? {
                                          '& th': {},
                                          maxHeight: 500,
                                          overflowY: 'auto',
                                          overflowX: 'auto',
                                          '::-webkit-scrollbar': {
                                              width: '3px',
                                          },
                                      }
                                    : {}
                            }
                        >
                            <Table aria-label="simple table">
                                <TableHead
                                    sx={
                                        isCheckedScroll
                                            ? {
                                                  position: 'sticky',
                                                  top: 0,
                                                  zIndex: 5,
                                              }
                                            : {}
                                    }
                                >
                                    <TableRow>
                                        {tableHeaders?.map((t, i) => {
                                            return <BaseHead key={i} align="left" title={t} />;
                                        })}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {paging?.rows?.map((item, index) => (
                                        <Grow in={true} key={index} timeout={Math.min(500 * index, 1000)}>
                                            <TableRow>{renderedItem?.(item)}</TableRow>
                                        </Grow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    ) : (
                        noDataComponent ?? (
                            <ListNoData
                                sx={{
                                    border: isVerificationPage ? 'none' : '1px solid #DDDDDD',
                                    minHeight: isVerificationPage ? 200 : 150,
                                }}
                            />
                        )
                    )}

                    {paging?.total! > paging?.pageSize! && !isLoading && (
                        <Stack mt={3} direction="row" justifyContent="center">
                            <MuiPagination
                                count={paging?.totalPages || 1}
                                page={paging?.page}
                                onChange={(_, page) => setPage?.(page)}
                                variant="outlined"
                                shape="rounded"
                            />
                        </Stack>
                    )}
                </>
            )}
        </Stack>
    );
}
