import * as React from 'react';
import { Autocomplete, Box, Checkbox, TextField, Typography } from '@mui/material';

import color from '@theme/Colors';
import CheckedIcon from '@assets/jsx-icon/CheckedIcon';
import CheckBoxIcon from '@assets/jsx-icon/CheckBoxIcon';

export const icon = <CheckBoxIcon fontSize="small" />;
export const checkedIcon = <CheckedIcon fontSize="small" />;

type Props<T> = {
    listData: T[];
    label: string | JSX.Element;
    value: any[] | any;
    keyLabel: keyof T;
    keyId: keyof T;
    setFilter: (option: T[]) => void;
    isDisable?: boolean;
    limitTags?: number;
    chipSize?: number;
    placeHolder?: string;
    isSelectZone?: boolean;
    isRequired?: boolean;
};

export default function IMultiSelectWithCheckBox<T>(props: Props<T>) {
    const handleInput = (e: any, value: T[]) => {
        props.setFilter(value);
    };

    return (
        <Box
            sx={{
                '& ul': {
                    padding: '5px !important',
                    borderBottom: `1px solid ${color.grey100}`,
                },
                '& svg': {
                    width: '15px !important',
                    height: '15px !important',
                },
                '& .MuiButtonBase-root': {
                    backgroundColor: color.grey200,
                    maxWidth: `${props.chipSize || 120}px !important`,
                },
            }}
        >
            <Autocomplete
                multiple
                id="checkboxes-tags-demo1"
                options={props.listData}
                sx={{
                    '& .MuiOutlinedInput-root > span.MuiAutocomplete-tagSizeSmall': {
                        px: '5px',
                        background: color.grey200,
                        borderRadius: '5px',
                        fontSize: '12px',
                    },
                }}
                disabled={props.isDisable || false}
                componentsProps={{
                    popper: {
                        sx: {
                            zIndex: props.isSelectZone ? (theme) => theme.zIndex.drawer - 1 : undefined,
                            '& .MuiAutocomplete-listbox': {
                                padding: '5px',
                            },
                            '& li': {
                                minHeight: 'auto',
                            },
                        },
                    },
                }}
                limitTags={props.limitTags ?? 2}
                disableCloseOnSelect
                noOptionsText={'No options'}
                // open
                size="small"
                getOptionLabel={(option) => option[props.keyLabel]}
                onChange={(event, value) => handleInput(event, value)}
                isOptionEqualToValue={(option, value) => option[props.keyId] === value[props.keyId]}
                value={props.value || null}
                renderOption={(props1, option, { selected }) => {
                    return (
                        <li
                            {...props1}
                            style={{
                                borderRadius: '4px',
                                marginBottom: '2px',
                                paddingRight: '10px',
                            }}
                            key={Number(option[props.keyId])}
                        >
                            <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8, padding: 0 }}
                                checked={selected}
                            />
                            {props.isSelectZone ? (
                                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {option[props.keyLabel]} (ZoneID {option[props.keyId]})
                                </Typography>
                            ) : (
                                <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                    {option[props.keyLabel]}
                                </Typography>
                            )}
                        </li>
                    );
                }}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            label={
                                <span>
                                    {props.label}
                                    {props.isRequired && <span style={{ color: 'red' }}>{` *`}</span>}
                                </span>
                            }
                            placeholder={props.placeHolder ?? 'Select more'}
                            sx={{}}
                        />
                    );
                }}
            />
        </Box>
    );
}
