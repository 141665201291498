import color from '@theme/Colors';
import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function TaskManagement(props: Props) {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path
                d="M12.3701 8.87891H17.6201"
                stroke={color.grey600}
                className="icon-svg-stroke"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.37988 8.87891L7.12988 9.62891L9.37988 7.37891"
                stroke={color.grey600}
                className="icon-svg-stroke"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M12.3701 15.8789H17.6201"
                stroke={color.grey600}
                className="icon-svg-stroke"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M6.37988 15.8789L7.12988 16.6289L9.37988 14.3789"
                stroke={color.grey600}
                className="icon-svg-stroke"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z"
                stroke={color.grey600}
                className="icon-svg-stroke"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
