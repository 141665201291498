import { GeoZoneCoordinates, GeoZones } from '@EcamModel/model';
import IcDraw from '@assets/details-camera-icons/IcDraw';
import { CircularProgress, IconButton, IconButtonProps, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { Mode } from '@pages/cameras/details';
import { getCenterFrame } from '@pages/cameras/details/utils';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import Konva from 'konva';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { MdOutlineDelete } from 'react-icons/md';
import { Circle, Image, Layer, Line, Shape, Stage, Text } from 'react-konva';
import { DrawerContext } from './drawer/useDrawer';

export type Point = {
    x: number;
    y: number;
};

export type Action = 'draw' | 'move';

type Props = {
    onChange?(points: GeoZoneCoordinates[]): void;
    prevGeoZone?: GeoZoneCoordinates[][];
    selectedGeoZone?: GeoZones;
    blobName?: string;
    mode?: Mode;
};
const PREVIEW_WIDTH = 1128;
function FramePolygonSized(props: Props) {
    const { open } = useContext(DrawerContext);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const PREVIEW_RATIO = 0.5625;
    const [containerSize, setContainerSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth * PREVIEW_RATIO,
    });

    const stageRef = useRef<Konva.Stage>(null);
    const shapeRef = useRef<Konva.Shape>(null);
    const { image, loading, isError } = useImageDimensions(`${props.blobName}`);
    const scaleSize = containerSize.width / (image?.width ?? 1920);
    const scaledPrevGeoZone = props.prevGeoZone?.map((frame) =>
        frame.map((point) => ({
            x: point?.XPoint * scaleSize,
            y: point?.YPoint * scaleSize,
            geoZoneId: point.GeoZoneId,
        }))
    );

    const [typeSelected, setTypeSelected] = useState<Action>('draw');
    const [points, setPoints] = useState<GeoZoneCoordinates[]>([]);
    const [position, setPosition] = useState<Point>({ x: 0, y: 0 });
    const [sized, setSized] = useState(100);
    const [scale, setScale] = useState(1);
    const [isDrag, setIsDrag] = useState(false);

    const handleZoomBySlider = (event: any, newValue: number | number[], activeThumb: number) => {
        const value = Array.isArray(newValue) ? newValue[0] : newValue;
        const newScale = value / 100;
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(value);
    };
    const resetZoom = () => {
        const newScale = 1;
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(100); // Sét lại giá trị sized về 100%
    };
    const setScaleAndPosition = (newScale: number, newSized: number) => {
        const stage = stageRef.current;
        if (!stage) return;

        const newX = (stage.width() / 2) * (1 - newScale);
        const newY = (stage.height() / 2) * (1 - newScale);

        stage.scale({ x: newScale, y: newScale });
        stage.position({ x: newX, y: newY });
        stage.batchDraw();

        setScale(newScale);
        setPosition({ x: newX, y: newY });
        setSized(newSized);
    };
    const handleZoomIn = () => {
        if (sized < 200) {
            let newSized = Math.min(sized + 5, 200);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };

    const handleZoomOut = () => {
        if (sized > 100) {
            let newSized = Math.max(sized - 5, 100);
            let newScale = newSized / 100;
            setScaleAndPosition(newScale, newSized);
        }
    };
    const handleDragImage = (e: any) => {
        const stage = stageRef.current;
        if (!stage) return;

        const x = stage.x();
        const y = stage.y();

        const minX = -stage.width() * (scale - 1);
        const minY = -stage.height() * (scale - 1);

        if (x > 0 || y > 0 || x < minX || y < minY) {
            stage.position(position);
        } else {
            setPosition({ x, y });
        }
    };

    const isPointInsideFrame = (point: Partial<GeoZoneCoordinates>, frame: GeoZoneCoordinates[]) => {
        var x = point.XPoint,
            y = point.YPoint;

        var inside = false;
        for (var i = 0, j = frame.length - 1; i < frame.length; j = i++) {
            var xi = frame[i]?.XPoint,
                yi = frame[i]?.YPoint;

            var xj = frame[j]?.XPoint,
                yj = frame[j]?.YPoint;

            var intersect = yi > y! !== yj > y! && x! < ((xj - xi) * (y! - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
        }

        return inside;
    };

    const handleDrawFrame = (e: any) => {
        if (points.length === 5) return;
        if (typeSelected !== 'draw') return;
        const stage = stageRef.current;
        if (!stage) return;

        const pointer = stage.getPointerPosition();
        if (!pointer) return;

        const currentScale = stage.scaleX(); // Lấy tỷ lệ zoom hiện tại
        const point: GeoZoneCoordinates = {
            XPoint: (pointer.x - position.x) / currentScale / scaleSize, // Tính toán tọa độ x theo tỷ lệ zoom
            YPoint: (pointer.y - position.y) / currentScale / scaleSize, // Tính toán tọa độ y theo tỷ lệ zoom
            Index: points.length + 1,
            GeoZoneId: props.selectedGeoZone?.Id,
        };

        if (props.prevGeoZone?.some((frame) => isPointInsideFrame(point, frame))) {
            return;
        }
        setPoints((prevPoints) => {
            const newPoints = [...prevPoints, point];
            if (newPoints.length === 4) {
                newPoints.push(prevPoints[0]);
            }

            return newPoints;
        });
    };

    const handleDragAnchors = (index: number, e: any) => {
        if (typeSelected !== 'draw') {
            e.target.to({
                x: points[index].XPoint * scaleSize,
                y: points[index].YPoint * scaleSize,
            });
            return;
        }
        const newPoints: any[] = [...points];

        const newPoint: GeoZoneCoordinates = {
            XPoint: e.target.x() / scaleSize,
            YPoint: e.target.y() / scaleSize,
            Index: points[index].Index,
            GeoZoneId: props.selectedGeoZone?.Id,
        };

        if (props.prevGeoZone?.some((frame) => isPointInsideFrame(newPoint, frame))) {
            e.target.to({
                x: points[index].XPoint * scaleSize,
                y: points[index].YPoint * scaleSize,
            });
            return;
        }
        newPoints[index] = newPoint;
        if (index === 4) {
            newPoints[0] = newPoints[index];
        }
        setPoints(newPoints);
    };

    const handleDragShape = (e: any) => {
        const shapeNode = shapeRef.current;
        if (shapeNode && typeSelected !== 'draw') {
            shapeNode.position({ x: 0, y: 0 });
            return;
        }
        if (shapeNode) {
            const newX = e.target.x() / scaleSize;
            const newY = e.target.y() / scaleSize;

            const updatedPoints = points.map((point, index) => ({
                XPoint: point.XPoint + newX,
                YPoint: point.YPoint + newY,
                Index: index + 1,
                GeoZoneId: props.selectedGeoZone?.Id,
            }));
            const shapeInsideFrame = updatedPoints.some((point) => {
                return props.prevGeoZone?.some((frame) => {
                    return isPointInsideFrame(point, frame);
                });
            });
            if (shapeInsideFrame) {
                shapeNode.position({ x: 0, y: 0 });
                setIsDrag(false);
                return;
            }
            setPoints(updatedPoints);
            shapeNode.position({ x: 0, y: 0 });
            setIsDrag(false);
        }
    };
    // useEffect(() => {
    //     if (stageRef.current) {
    //         const stageElement = stageRef.current.content;
    //         if (typeSelected === 'move') {
    //             stageElement.style.cursor = 'move';
    //         } else {
    //             stageElement.style.cursor = 'default';
    //         }
    //     }
    // }, [typeSelected]);

    useEffect(() => {
        const isEdit = props.mode === 'edit';
        if (isEdit && points.length === 5) {
            props.onChange?.(points);
        } else if (!isEdit) {
            props.onChange?.(points);
        }
    }, [props.mode, points]);

    useEffect(() => {
        if (!props.selectedGeoZone?.Id) {
            let initialPolygon: GeoZoneCoordinates[] = [];
            // do {
            //     initialPolygon = getRandomPolygon(844, 844 * 0.5625, 80, 100);
            // } while (
            //     initialPolygon.some((point) => props.prevGeoZone?.some((frame) => isPointInsideFrame(point, frame)))
            // );

            setPoints(initialPolygon);
        } else {
            setPoints(
                props.selectedGeoZone?.GeoZoneCoordinates?.concat(props.selectedGeoZone?.GeoZoneCoordinates?.[0]) || []
            );
        }
    }, [props.selectedGeoZone?.Id]);

    useEffect(() => {
        const updateStageSize = () => {
            if (containerRef.current) {
                const width = containerRef.current.offsetWidth;
                const height = width * PREVIEW_RATIO;
                setContainerSize({ width, height });
            } else {
                setTimeout(updateStageSize, 300);
            }
        };

        if (open) {
            setTimeout(updateStageSize, 300);
        } else {
            updateStageSize();
        }

        window.addEventListener('resize', updateStageSize);

        return () => {
            window.removeEventListener('resize', updateStageSize);
        };
    }, [open]);

    return (
        <>
            {!image ? (
                <Stack
                    direction="row"
                    width="100%"
                    justifyContent="center"
                    alignItems="center"
                    minHeight={containerSize.height}
                >
                    <CircularProgress />
                </Stack>
            ) : (
                <div
                    ref={containerRef}
                    style={{
                        width: '75%',
                        height: containerSize.height,
                        margin: '10px auto 0',
                        position: 'relative',
                    }}
                >
                    <Stage
                        ref={stageRef}
                        width={containerSize.width}
                        height={containerSize.height}
                        scaleX={scale}
                        scaleY={scale}
                        x={position.x}
                        y={position.y}
                        draggable={Boolean(typeSelected === 'move')}
                        onDragEnd={handleDragImage}
                        onDragMove={handleDragImage}
                        onClick={handleDrawFrame}
                    >
                        <Layer>
                            <Image image={image} scaleX={scaleSize} scaleY={scaleSize} />
                            {!!scaledPrevGeoZone?.length &&
                                scaledPrevGeoZone?.map((frame, frameIndex) => {
                                    const center = getCenterFrame(frame);
                                    return (
                                        <React.Fragment key={`frame-${frameIndex}`}>
                                            <Line
                                                points={frame?.flatMap((point) => [point.x, point.y])}
                                                stroke="blue"
                                            />
                                            {frame[0].geoZoneId !== props.selectedGeoZone?.Id && (
                                                <>
                                                    <Circle x={center.x} y={center.y} radius={15} fill="#fff" />
                                                    <Text
                                                        x={center.x}
                                                        y={center.y}
                                                        text={String(`${frameIndex + 1}`)}
                                                        fontSize={14}
                                                        fill={'black'}
                                                        align={'center'}
                                                        verticalAlign={'middle'}
                                                        offsetX={4}
                                                        offsetY={6}
                                                    />
                                                </>
                                            )}
                                        </React.Fragment>
                                    );
                                })}
                            {points.length >= 4 && (
                                <Shape
                                    sceneFunc={(context, shape) => {
                                        context.beginPath();
                                        const startPoint = points[0];
                                        context.moveTo(startPoint?.XPoint * scaleSize, startPoint?.YPoint * scaleSize);
                                        points.slice(1).forEach((point) => {
                                            context.lineTo(point.XPoint * scaleSize, point.YPoint * scaleSize);
                                        });
                                        context.closePath();
                                        context.strokeShape(shape);
                                        context.fillStrokeShape(shape);
                                    }}
                                    stroke="red"
                                    ref={shapeRef}
                                    draggable
                                    onDragStart={() => {
                                        if (typeSelected !== 'draw') return;
                                        setIsDrag(true);
                                    }}
                                    onDragEnd={handleDragShape}
                                    onMouseEnter={() => {
                                        if (stageRef.current) {
                                            const stageElement = stageRef.current.content;
                                            stageElement.style.cursor = 'pointer';
                                        }
                                    }}
                                    onMouseLeave={() => {
                                        if (stageRef.current) {
                                            const stageElement = stageRef.current.content;
                                            stageElement.style.cursor = 'default';
                                        }
                                    }}
                                />
                            )}
                            {points.map((point, index) => (
                                <>
                                    {index < points.length - 1 && points.length <= 4 && (
                                        <Line
                                            key={`line-${index}`}
                                            points={[
                                                point.XPoint * scaleSize,
                                                point.YPoint * scaleSize,
                                                points[index + 1].XPoint * scaleSize,
                                                points[index + 1].YPoint * scaleSize,
                                            ]}
                                            stroke="red"
                                        />
                                    )}
                                    {!isDrag && (
                                        <Circle
                                            key={`circle-${index}`}
                                            x={point.XPoint * scaleSize}
                                            y={point.YPoint * scaleSize}
                                            radius={4}
                                            fill="white"
                                            stroke="black"
                                            strokeWidth={2}
                                            draggable
                                            onDragEnd={(e) => handleDragAnchors(index, e)}
                                        />
                                    )}
                                </>
                            ))}
                        </Layer>
                    </Stage>
                    <Stack
                        direction="row"
                        justifyContent="center"
                        sx={{
                            p: 1,
                            position: 'absolute',
                            top: -51,
                            left: 0,
                            right: 0,
                            background: 'rgba(0, 0, 0, 1)',
                        }}
                        alignItems="center"
                        spacing={1.5}
                    >
                        <BaseIconFrame
                            icon={<IcDraw style={{ width: 18, height: 18 }} />}
                            onClick={() => setTypeSelected('draw')}
                            isSelected={Boolean(typeSelected === 'draw')}
                        />
                        {/* <BaseIconFrame
                            icon={<IcHandTool style={{ width: 18, height: 18 }} />}
                            onClick={() => setTypeSelected('move')}
                            isSelected={Boolean(typeSelected === 'move')}
                        /> */}
                        <BaseIconFrame
                            icon={<MdOutlineDelete fill="#fff" fontSize={20} />}
                            onClick={() => {
                                setPoints([]);
                            }}
                        />
                    </Stack>
                    {/* <Stack alignItems="center" sx={{ height: 200, position: 'absolute', right: 0, bottom: 120 }}>
                        <IconActionZoom
                            children={<TbZoomReset />}
                            onClick={resetZoom}
                            sx={{ disabled: Boolean(sized <= 100) }}
                        />
                        <IconActionZoom
                            children={<TfiZoomIn />}
                            onClick={handleZoomIn}
                            sx={{ disabled: Boolean(sized >= 200) }}
                        />
                        <Slider
                            orientation="vertical"
                            value={sized}
                            min={100}
                            max={200}
                            onChange={handleZoomBySlider}
                            valueLabelDisplay="auto"
                            valueLabelFormat={(value) => `${value}%`}
                            step={5}
                            sx={{
                                '& .MuiSlider-thumb': {
                                    width: 15,
                                    height: 15,
                                    '&:before': {
                                        boxShadow: '0 4px 8px rgba(0,0,0,0.4)',
                                    },
                                    '&:hover, &.Mui-focusVisible, &.Mui-active': {
                                        boxShadow: 'none',
                                    },
                                },
                            }}
                        />
                        <IconActionZoom
                            children={<TfiZoomOut />}
                            onClick={handleZoomOut}
                            sx={{ disabled: Boolean(sized <= 100) }}
                        />
                    </Stack> */}
                </div>
            )}
        </>
    );
}

export default FramePolygonSized;
export const BaseIconFrame = ({
    icon,
    onClick,
    isSelected,
    style,
}: {
    icon: React.ReactNode;
    onClick(): void;
    isSelected?: boolean;
    style?: SxProps<Theme>;
}) => {
    const theme = useTheme();
    return (
        <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{
                width: 35,
                height: 35,
                borderRadius: '100%',
                border: `2px solid ${isSelected ? theme.palette.primary.main : '#DDDDDD'}`,
                cursor: 'pointer',
                color: isSelected ? theme.palette.primary.main : '#fff',
                '&:hover': {
                    background: 'rgba(255, 255, 255, 0.4)',
                    transition: '0.3s ease-in-out',
                },
                ...style,
            }}
            onClick={(e) => {
                onClick();
            }}
        >
            {icon}
        </Stack>
    );
};
export const IconActionZoom = ({
    children,
    onClick,
    sx,
}: {
    children: React.ReactNode;
    onClick(): void;
    sx?: IconButtonProps;
}) => {
    return (
        <IconButton onClick={onClick} sx={{ color: '#fff', width: 35, height: 35 }} {...sx}>
            {children}
        </IconButton>
    );
};
