import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function Germany(props: Props) {
    return (
        <svg {...props} width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_332_22915)">
                <path
                    d="M0 3C0 1.34315 1.34315 0 3 0H30.6C32.2569 0 33.6 1.34315 33.6 3V21C33.6 22.6569 32.2569 24 30.6 24H3C1.34315 24 0 22.6569 0 21V3Z"
                    fill="#F93939"
                />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 16H33.6V24H0V16Z" fill="#FFDA2C" />
                <path fillRule="evenodd" clipRule="evenodd" d="M0 0H33.6V8H0V0Z" fill="#151515" />
            </g>
            <defs>
                <clipPath id="clip0_332_22915">
                    <path
                        d="M0 3C0 1.34315 1.34315 0 3 0H30.6C32.2569 0 33.6 1.34315 33.6 3V21C33.6 22.6569 32.2569 24 30.6 24H3C1.34315 24 0 22.6569 0 21V3Z"
                        fill="white"
                    />
                </clipPath>
            </defs>
        </svg>
    );
}
