import { ConsolidateCameraMonitoring } from '@EcamModel/model/CameraOverview';
import { localStorageCustomFieldsKey } from '@pages/camerasOverview/configs';
import { TableInfoContent } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';
import _ from 'lodash';
import { startOfDay } from 'src/helpers';

// lay ra cac truong co kieu du lieu trong 1 model
export type BooleanProperties<T> = {
    [K in keyof T]: T[K] extends boolean ? K : never;
}[keyof T];

export type StringProperties<T> = {
    [K in keyof T]: T[K] extends string ? K : never;
}[keyof T];

export type NumberProperties<T> = {
    [K in keyof T]: T[K] extends number ? K : never;
}[keyof T];

type EnumType = string | number;

export type EnumProperties<T> = {
    [K in keyof T]: T[K] extends EnumType ? K : never;
}[keyof T];

export type AllProperties<T> = BooleanProperties<T> | StringProperties<T> | NumberProperties<T> | EnumProperties<T>;

// smart filter
export enum ModeFilter {
    smart,
    highlight,
}

export interface FilterOptionsCondition {
    Number?: string;
    String?: string;
    Date?: string;
    List?: string;
}

export interface FilterOptionsValue {
    Number?: { value?: number | number[] };
    String?: { value: string };
    Date?: { value: Date | Date[] };
    List?: { value: string[] };
}

export interface OptionType<T> {
    id: number;
    fieldValue?: TableInfoContent<T>[];
    condition?: string;

    //Number
    numberRangeValue?: number | number[];

    //String
    value?: string;

    //Date
    dateRangeValue?: Date | Date[];

    //List
    fieldList?: string;

    error?: string | null;
}

export type NumberCondition =
    | 'Greater Than'
    | 'Greater Than Or Equal'
    | 'Equal To'
    | 'Not Equal'
    | 'Less Than'
    | 'Less Than Or Equal'
    | 'Between';

export type StringCondition = 'Contains' | 'Does Not Contain' | 'Equals' | 'Not Equals';
export type DateCondition =
    | 'After'
    | 'Before'
    | 'Equal And After'
    | 'Equal And Before'
    | 'Between'
    | 'Equals'
    | 'Not Equals';
export type ListCondition = 'In' | 'Not In';

export interface BaseSmartFilterOption<C, V> {
    field: (keyof ConsolidateCameraMonitoring)[];
    condition?: C;
    value?: V;
}

export type SmartFilterOption =
    | BaseSmartFilterOption<NumberCondition, number | number[]>
    | BaseSmartFilterOption<StringCondition, string>
    | BaseSmartFilterOption<DateCondition, Date | Date[]>
    | BaseSmartFilterOption<ListCondition, number[]>;

export type DataType = 'Number' | 'String' | 'List' | 'Date';

export interface SmartFilter {
    type: DataType;
    options: SmartFilterOption[];
}

export const convertModel = <T extends Record<string, any>, U extends Record<string, any>>(
    data: T[],
    convertFn: (item: T) => U
): U[] => {
    return data?.map(convertFn);
};

export function aggregateOptions<T extends BaseSmartFilterOption<any, any>>(
    options: T[],
    defaultValue: T | null
): T | null {
    return options.reduce<T | null>((acc, option) => {
        if (acc === null) {
            return option;
        }
        const mergedField = Array.from(new Set([...acc.field, ...option.field]));
        return {
            ...acc,
            field: mergedField,
            condition: acc.condition,
            value: acc.value,
        } as T;
    }, defaultValue);
}

export const aggregateNumberOptions = (options: SmartFilterOption[]) =>
    aggregateOptions<BaseSmartFilterOption<NumberCondition, number | number[]>>(
        options as BaseSmartFilterOption<NumberCondition, number | number[]>[],
        null
    );

export const aggregateStringOptions = (options: SmartFilterOption[]) =>
    aggregateOptions<BaseSmartFilterOption<StringCondition, string>>(
        options as BaseSmartFilterOption<StringCondition, string>[],
        null
    );

export const aggregateDateOptions = (options: SmartFilterOption[]) =>
    aggregateOptions<BaseSmartFilterOption<DateCondition, Date | Date[]>>(
        options as BaseSmartFilterOption<DateCondition, Date | Date[]>[],
        null
    );

export const aggregateListOptions = (options: SmartFilterOption[]) =>
    aggregateOptions<BaseSmartFilterOption<ListCondition, number[]>>(
        options as BaseSmartFilterOption<ListCondition, number[]>[],
        null
    );

export const aggregationFunctions: Record<DataType, (options: SmartFilterOption[]) => SmartFilterOption | null> = {
    Number: aggregateNumberOptions,
    String: aggregateStringOptions,
    Date: aggregateDateOptions,
    List: aggregateListOptions,
};

export const getConditionFromDisplayValue = <T extends string | number | symbol>(
    conditionMap: Record<T, string>,
    displayValue: string
): T | undefined => {
    return Object.keys(conditionMap).find((key) => conditionMap[key as T] === displayValue) as T | undefined;
};
// const displayValue = 'CameraDeployDate';
// const condition = getConditionFromDisplayValue(idToFieldMapping, displayValue);
// console.log('🚀 ~ condition:', condition); Output: Deployment date

export const findKeyByValue = <T>(mapping: Record<string, T>, value: T): string | undefined => {
    return Object.keys(mapping).find((key) => mapping[key] === value);
};

// =================== Smart Filter =================
export const countSelectedFieldsInFilters = <T extends { [key: string]: any }>(filters: SmartFilter[]): number => {
    const storedCustomFields = localStorage.getItem(localStorageCustomFieldsKey);
    const tableFields: TableInfoContent<T>[] = storedCustomFields ? JSON.parse(storedCustomFields) : [];

    const selectedFieldIds = tableFields.map((field) => field.id);

    return filters?.reduce((total, filter) => {
        return (
            total +
            filter.options.reduce((optionTotal, option) => {
                const matchingFields = option.field.filter((field) => {
                    return selectedFieldIds.includes(field as unknown as T[keyof T]);
                });
                return optionTotal + matchingFields.length;
            }, 0)
        );
    }, 0);
};

export const applySmartFilters = (item, filters: SmartFilter[]): boolean => {
    return filters.every((filter) => {
        return filter.options.every((option) => {
            return option.field.every((field) => {
                const fieldValue = item[field];

                switch (filter.type) {
                    case 'Number':
                        return applyNumberFilter(option, fieldValue);

                    case 'String':
                        return applyStringFilter(option, fieldValue);

                    case 'List':
                        return applyListFilter(option, fieldValue);

                    case 'Date':
                        return applyDateFilter(option, fieldValue);

                    default:
                        return false;
                }
            });
        });
    });
};

const applyNumberFilter = (option: SmartFilterOption, fieldValue: number | [number, number]): boolean => {
    if (_.isNil(option.value)) {
        return false;
    }
    switch (option.condition) {
        case 'Greater Than':
            return fieldValue > option.value;
        case 'Greater Than Or Equal':
            return fieldValue >= option.value;
        case 'Equal To':
            return fieldValue == option.value;
        case 'Less Than':
            return fieldValue < option.value;
        case 'Less Than Or Equal':
            return fieldValue <= option.value;
        case 'Between':
            return fieldValue >= option.value[0] && fieldValue <= option.value[1];
        case 'Not Equal':
            return fieldValue !== option.value;
        default:
            return false;
    }
};

const applyStringFilter = (option: SmartFilterOption, fieldValue: string): boolean => {
    if (_.isNil(option.value) || _.isNil(fieldValue)) {
        return false;
    }

    const normalizedFieldValue = fieldValue.toLowerCase().trim();
    const normalizedOptionValue = option.value.toString().toLowerCase().trim();

    switch (option.condition) {
        case 'Equals':
            return normalizedFieldValue === normalizedOptionValue;
        case 'Not Equals':
            return normalizedFieldValue !== normalizedOptionValue;
        case 'Contains':
            return normalizedFieldValue.includes(normalizedOptionValue);
        case 'Does Not Contain':
            return !normalizedFieldValue.includes(normalizedOptionValue);
        default:
            return false;
    }
};

const applyDateFilter = (option: SmartFilterOption, fieldValue: Date | string): boolean => {
    if (_.isNil(option.value)) {
        return false;
    }

    const dateValue = startOfDay(fieldValue);
    const optionValue = option.value;

    switch (option.condition) {
        case 'After':
            return dateValue.isAfter(startOfDay(optionValue as Date));
        case 'Before':
            return dateValue.isBefore(startOfDay(optionValue as Date));
        case 'Equals':
            return dateValue.isSame(startOfDay(optionValue as Date));
        case 'Equal And After':
            return dateValue.isSameOrAfter(startOfDay(optionValue as Date));
        case 'Equal And Before':
            return dateValue.isSameOrBefore(startOfDay(optionValue as Date));
        case 'Between':
            const [start, end] = optionValue as [Date, Date];
            return dateValue.isBetween(startOfDay(start), startOfDay(end), null, '[]');
        case 'Not Equals':
            return !dateValue.isSame(startOfDay(optionValue as Date));
        default:
            return true;
    }
};

const applyListFilter = (option: SmartFilterOption, fieldValue: number): boolean => {
    if (_.isNil(option.value)) {
        return false;
    }

    const values = option.value as number[];
    switch (option.condition) {
        case 'In':
            return values.includes(fieldValue);
        case 'Not In':
            return !values.includes(fieldValue);
        default:
            return false;
    }
};
