import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraLogFiles } from '@EcamModel/model/CameraLogFiles';
import { AxiosInstance } from 'axios';
import { ICameraLogFilesHttpController, IGetCameraLogFilesProps } from '../ICameraLogFilesHttpController';

export class CameraLogFilesHttpController extends BaseHttpController<CameraLogFiles> implements ICameraLogFilesHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-log-files', client);
    }

    async list(params: IGetCameraLogFilesProps): Promise<Paging<CameraLogFiles>> {
        return await this.doPost({ path: 'filter', body: params }).then((res) => res.data);
    }
}
