import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import { pushError } from '@components/toast';
import {
    cameraOverviewHttpController,
    solar4gCamPhotoUploadScheduleHttpController,
    thresholdSettingHttpController,
} from '@controllers/index';
import { CameraDailyWorkedHour, ConsolidateCameraMonitoring } from '@EcamModel/model/CameraOverview';
import { Solar4gPhotoUploadSchedule } from '@EcamModel/model/Solar4gPhotoUploadSchedule';
import { ThresholdSetting, ThresholdStatus } from '@EcamModel/model/ThresholdSetting';
import usePaging from '@hooks/usePaging';
import usePopUp from '@hooks/usePopUp';
import { Filter } from '@pages/cameras/list';
import {
    cameraPowerSuppliesReadableMapping,
    cameraPriorityReadableMapping,
    purposesReadableMapping,
} from '@pages/cameras/list/components/CameraItem';
import { BarChart } from '@pages/overview/charts/BarChartCameraStatus';
import { TableInfoContent } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';
import { applySmartFilters, ModeFilter, SmartFilter } from '@pages/overview/smartFilterHighLight/configs';
import { convertThresholdToSmartFiltersOption } from '@pages/overview/smartFilterHighLight/helpers';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { endOfYesterday, formatUKDate, startOfYesterday } from 'src/helpers';
import { SortCriterion, SortMode, sortValueDataInColumnTable } from 'src/helpers/sortColumTable';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { useDebounce } from 'usehooks-ts';
import _ from 'lodash';
import {
    CameraOverviewPopup,
    cameraServiceMapping,
    cameraStatusMapping,
    dataTypesCameraOverview,
    frequencyTimeMapping,
    getColumnsFieldCustom,
    localStorageCustomFieldsKey,
    localStorageHighlightFilterKey,
    activeItemFilterKey,
    localStorageSmartFilterKey,
    netWorkingMapping,
    numberOfVersionsForOverview,
    postTypeMapping,
    uploadModeMapping,
    wifiStatusMapping,
    TypeFilter,
} from '../configs';

export type IdCameraState = {
    note: number;
    task: number;
};

export const itemsPerPageForCameraOverviewPage = 500;

export default function useCameraOverviewProvider() {
    const backdrop = useBackdrop();
    const { setActiveItem } = useContext(DrawerContext);

    const { state } = useLocation();
    const cameraName = state?.cameraName;

    const [cameraConfigMonitoring, setCameraConfigMonitoring] = useState<ConsolidateCameraMonitoring[]>([]);
    const [searchCameraConfig, setSearchCameraConfig] = useState<string>(cameraName ?? '');
    const debouncedValue = useDebounce<string>(searchCameraConfig, 500);

    const [loadingDailyWorkedHours, setLoadingDailyWorkedHours] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(true);

    const [activeItemId, setActiveItemId] = useState<number | null>(() => {
        const storedIsActiveItemFilter = localStorage.getItem(activeItemFilterKey);
        return storedIsActiveItemFilter ? JSON.parse(storedIsActiveItemFilter) : null;
    });

    const [photoUploadSchedule, setPhotoUploadSchedule] = useState<Solar4gPhotoUploadSchedule>(
        {} as Solar4gPhotoUploadSchedule
    );
    const [cameraOverview, setCameraOverview] = useState<CameraOverviewPopup>({
        cameraName: '',
        cameraId: 1,
        locationName: '',
    });

    const [smartFilters, setSmartFilters] = useState<SmartFilter[]>([]);
    const [highLightFilters, setHighLightFilters] = useState<SmartFilter[]>([]);
    const [isReceivingNotifications, setIsReceivingNotifications] = useState<ThresholdStatus>(ThresholdStatus.Inactive);
    const [thresholdSetting, setThresholdSetting] = useState<ThresholdSetting>({} as ThresholdSetting);

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: startOfYesterday,
        to: endOfYesterday,
        status: cameraName
            ? undefined
            : {
                  Id: 2,
                  Name: 'Active',
                  Value: 0,
              },
    });

    const [orderBy, setOrderBy] = useState<SortCriterion<ConsolidateCameraMonitoring>[]>([]);
    const [cameraDailyWorkedHour, setCameraDailyWorkedHour] = useState<CameraDailyWorkedHour[]>([]);
    const [idNote, setIdNote] = useState<number>(0);
    const [idCamera, setIdCamera] = useState<IdCameraState>({} as IdCameraState);

    const [modeFilter, setModeFilter] = useState<ModeFilter | null>(null);

    const [macCameras, setMacCameras] = useState<string[]>([]);

    const popUpNoteInCamera = usePopUp();
    const popUpTaskUnComplete = usePopUp();
    const popUpCreateNewTask = usePopUp();
    const popUpAddOrUpdateNoteInCamera = usePopUp();
    const popUpConfirmDeleteNoteHistory = usePopUp();

    const columnsFieldCustom = useMemo(() => getColumnsFieldCustom(), [numberOfVersionsForOverview]);

    const defaultChartRowsData: TableInfoContent<keyof ConsolidateCameraMonitoring>[] = columnsFieldCustom.slice(0, 16);

    const sortedNumberFieldCustom = defaultChartRowsData.map((item, index) => ({
        ...item,
        number: index + 1,
    }));

    const getDataSmartFiltersFromLocalStorage = () => {
        setSmartFilters(JSON.parse(localStorage.getItem(localStorageSmartFilterKey)!) ?? []);
        setHighLightFilters(JSON.parse(localStorage.getItem(localStorageHighlightFilterKey)!) ?? []);
    };

    const getCameraConfigMonitoring = async (props: { startDate: Date; endDate: Date }) => {
        setLoading(true);
        const startAndEndDate = {
            startDate: props?.startDate ?? startOfYesterday,
            endDate: props?.endDate ?? endOfYesterday,
        };

        const result = await cameraOverviewHttpController
            .getConsolidateCameraMonitoring(startAndEndDate)
            .finally(() => setLoading(false));
        setCameraConfigMonitoring(result);
    };

    const handleGetPhotoUploadTimeLatest = () => {
        setIsLoading(true);
        solar4gCamPhotoUploadScheduleHttpController
            .getPhotoUploadTimeLatest()
            .then((res) => {
                setPhotoUploadSchedule(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleBatteryFilterClick = (report: ConsolidateCameraMonitoring) => {
        const hasValidAvgDailyWorkedHours = !_.isNil(report.AvgDailyWorkedHours);
        const hasValidAvgBattery = !_.isNil(report.AvgBattery);
        const hasValidCurrentBattery = !_.isNil(report.CurrentBattery);

        if (hasValidAvgDailyWorkedHours && hasValidAvgBattery && hasValidCurrentBattery) {
            const uptimeCondition = report.AvgDailyWorkedHours > 16;
            const averageBatteryCondition = report.AvgBattery <= 50;
            const currentBatteryCondition = report.CurrentBattery <= 70;

            return uptimeCondition || averageBatteryCondition || currentBatteryCondition;
        }

        return false;
    };

    const matchesLocation = (locationFilter: ConsolidateCameraMonitoring) => {
        return filter.location ? locationFilter.LocationName === filter.location.Name : true;
    };

    const matchesZone = (zoneFilter: ConsolidateCameraMonitoring) => {
        return filter.zones?.length ? filter.zones.some((zone) => zone.PublicName === zoneFilter.ZoneName) : true;
    };

    const matchesCameraName = (cameraNameFilter: ConsolidateCameraMonitoring) => {
        return debouncedValue ? cameraNameFilter.CameraName.toLowerCase().includes(debouncedValue.toLowerCase()) : true;
    };

    const matchesVersion = (camVersionFilter: ConsolidateCameraMonitoring) => {
        return filter.camVersion ? camVersionFilter.CameraVersion === filter.camVersion.Name : true;
    };

    const matchesUploadMode = (uploadModeFilter: ConsolidateCameraMonitoring) => {
        return filter.uploadMode ? uploadModeFilter.UploadMode === filter.uploadMode.Value : true;
    };

    const matchesStatus = (cameraStatusFilter: ConsolidateCameraMonitoring) => {
        return filter.status ? cameraStatusFilter.CameraStatus === filter.status.Value : true;
    };

    const matchesFirmware = (firmwareFilter: ConsolidateCameraMonitoring) => {
        return filter.firmware ? filter.firmware.Id === -1 || firmwareFilter.Firmware === filter.firmware.Value : true;
    };

    const matchesServiceType = (serviceTypeFilter: ConsolidateCameraMonitoring) => {
        return filter.serviceType ? serviceTypeFilter.CameraService === filter.serviceType.Value : true;
    };

    const matchesPurposeType = (purposeFilter: ConsolidateCameraMonitoring) => {
        return filter.camPurposes ? purposeFilter.PurposeType === filter.camPurposes.Value : true;
    };

    const matchesCameraPowerSupplies = (cameraPowerSuppliesFilter: ConsolidateCameraMonitoring) => {
        return filter.powerSupply ? cameraPowerSuppliesFilter.CameraPowerSupply === filter.powerSupply.Value : true;
    };

    const matchesCameraPriorities = (cameraPrioritiesFilter: ConsolidateCameraMonitoring) => {
        return filter.cameraPriority ? cameraPrioritiesFilter.CameraPriority === filter.cameraPriority.Value : true;
    };

    const matchesSmartFilters = (report: ConsolidateCameraMonitoring) => {
        return applySmartFilters(report, smartFilters);
    };

    const applyGeneralFilters = (report: ConsolidateCameraMonitoring) => {
        return (
            matchesCameraName(report) &&
            matchesVersion(report) &&
            matchesStatus(report) &&
            matchesFirmware(report) &&
            matchesUploadMode(report) &&
            matchesServiceType(report) &&
            matchesPurposeType(report) &&
            matchesSmartFilters(report) &&
            matchesZone(report) &&
            matchesCameraPowerSupplies(report) &&
            matchesCameraPriorities(report)
        );
    };

    const filteredDataCameraOverview = useMemo(() => {
        return cameraConfigMonitoring?.filter((report) => {
            const passesGeneralFilters = applyGeneralFilters(report);

            if (activeItemId === TypeFilter.BATTERY) {
                return passesGeneralFilters && handleBatteryFilterClick(report);
            }

            return passesGeneralFilters;
        });
    }, [
        debouncedValue,
        cameraConfigMonitoring,
        smartFilters,
        activeItemId,
        filter.camVersion,
        filter.status,
        filter.location,
        filter.zones,
        filter.firmware,
        filter.uploadMode,
        filter.serviceType,
        filter.camPurposes,
        filter.powerSupply,
        filter.cameraPriority,
    ]);

    const checkAndUpdateLocalStorage = (data: TableInfoContent<keyof ConsolidateCameraMonitoring>[]) => {
        const updatedData = data.some((item) => item.version !== numberOfVersionsForOverview);
        if (updatedData) {
            localStorage.setItem(localStorageCustomFieldsKey, JSON.stringify(sortedNumberFieldCustom));
            return sortedNumberFieldCustom;
        } else {
            return data;
        }
    };

    const fetchReportsDataFromLocalStorage = (): TableInfoContent<keyof ConsolidateCameraMonitoring>[] => {
        try {
            const jsonData = localStorage.getItem(localStorageCustomFieldsKey);

            if (jsonData) {
                const data = JSON.parse(jsonData);

                if (Array.isArray(data) && data.length === 0) {
                    localStorage.setItem(localStorageCustomFieldsKey, JSON.stringify(sortedNumberFieldCustom));
                    return sortedNumberFieldCustom.map(
                        (item: TableInfoContent<keyof ConsolidateCameraMonitoring>, index: number) => ({
                            ...item,
                            number: index + 1,
                        })
                    );
                }

                const updatedData = checkAndUpdateLocalStorage(data);

                return updatedData.map((item: TableInfoContent<keyof ConsolidateCameraMonitoring>, index: number) => ({
                    ...item,
                    number: index + 1,
                }));
            } else {
                localStorage.setItem(localStorageCustomFieldsKey, JSON.stringify(sortedNumberFieldCustom));
                return sortedNumberFieldCustom.map(
                    (item: TableInfoContent<keyof ConsolidateCameraMonitoring>, index: number) => ({
                        ...item,
                        number: index + 1,
                    })
                );
            }
        } catch (error) {
            console.log('🚀 ~ getDataFromLocalStorage ~ error:', error);
            return [];
        }
    };

    const reportFields: TableInfoContent<keyof ConsolidateCameraMonitoring>[] = fetchReportsDataFromLocalStorage();

    const [dataFromLocal, setDataFromLocal] =
        useState<TableInfoContent<keyof ConsolidateCameraMonitoring>[]>(reportFields);

    const handleSave = (data: TableInfoContent<keyof ConsolidateCameraMonitoring>[]) => {
        try {
            const jsonData = JSON.stringify(data);
            localStorage.setItem(localStorageCustomFieldsKey, jsonData);
            const updatedOverviewFields = fetchReportsDataFromLocalStorage();
            setDataFromLocal(updatedOverviewFields);
            setOrderBy([]);
        } catch (error) {
            console.log('🚀 ~ handleSave ~ error:', error);
        }
    };

    const tableRowForCameraOverview: TableInfoContent<keyof ConsolidateCameraMonitoring>[] = [
        {
            id: 'CameraId',
            label: 'No.',
        },
        {
            id: 'CameraPriority',
            label: 'Camera\npriority',
        },
        {
            id: 'CameraName',
            label: 'Camera',
        },
        ...(reportFields ?? dataFromLocal),
        {
            id: 'RadarSensitivity',
            label: 'Action',
        },
    ];

    // const dataTypesCameraOverview: { [key in keyof ConsolidateCameraMonitoring]: DataTypes } =
    //     inferDataTypes(filteredDataCameraOverview);

    const sortedData = sortValueDataInColumnTable({
        data: filteredDataCameraOverview,
        orderBy: orderBy,
        dataTypes: dataTypesCameraOverview,
        valueMappings: {
            Frequency: frequencyTimeMapping,
            CameraService: cameraServiceMapping,
            CameraStatus: cameraStatusMapping,
            PurposeType: purposesReadableMapping,
            NetworkType: netWorkingMapping,
            UploadMode: uploadModeMapping,
            PostType: postTypeMapping,
            LastWifiStatus: wifiStatusMapping,
            CameraPowerSupply: cameraPowerSuppliesReadableMapping,
            // CameraPriority: cameraPriorityReadableMapping,
        },
    });

    const getDataHighlightFilters = () => {
        thresholdSettingHttpController
            .getThresholdSetting()
            .then((res) => {
                setThresholdSetting(res);
                const dataHighlightFilter = convertThresholdToSmartFiltersOption(res.Thresholds);
                setHighLightFilters(dataHighlightFilter);
                if (res.Status) {
                    setIsReceivingNotifications(res.Status);
                }
            })
            .finally(() => {});
    };

    // ------------------------ PopUpAvgWorkedHours --------------------------------

    const cameraDailyWorkedHourPopup = cameraDailyWorkedHour.map((i) => {
        let chart: BarChart = { label: formatUKDate(i.Created), value: i.DailyWorkedHours };
        return chart;
    });

    const cameraPhotoTakenPopup = cameraDailyWorkedHour.map((i) => {
        let chart: BarChart = { label: formatUKDate(i.Created), value: i.DailyPhotosTaken };
        return chart;
    });

    useEffect(() => {
        getCameraConfigMonitoring({
            startDate: filter?.from ?? startOfYesterday,
            endDate: filter?.to ?? endOfYesterday,
        });
    }, [filter?.from, filter?.to]);

    useEffect(() => {
        setActiveItem(DrawerPage.OverviewCameras);
        handleGetPhotoUploadTimeLatest();
        getDataSmartFiltersFromLocalStorage();
        getDataHighlightFilters();
    }, []);

    const filteredNameCamera = filteredDataCameraOverview.find(
        (n) => n.CameraId === idCamera?.note || n.CameraId === idCamera?.task
    );
    useEffect(() => {
        if (!cameraName) return;
        setSearchCameraConfig(cameraName);
        setFilter({});
    }, [cameraName]);

    useEffect(() => {
        localStorage.setItem(activeItemFilterKey, JSON.stringify(activeItemId));
    }, [activeItemId]);

    const { currentPage, totalPages, currentData, nextPage, prevPage, handleSetPage, setCurrentPage } = usePaging(
        sortedData,
        itemsPerPageForCameraOverviewPage
    );

    return {
        backdrop,
        filter,
        cameraConfigMonitoring,
        searchCameraConfig,
        highLightFilters,
        cameraOverview,
        idCamera,
        idNote,
        smartFilters,
        modeFilter,
        isLoading,
        loading,
        loadingDailyWorkedHours,
        photoUploadSchedule,
        sortedData,
        filteredDataCameraOverview,
        tableRowForCameraOverview,
        orderBy,
        cameraDailyWorkedHourPopup,
        cameraPhotoTakenPopup,
        columnsFieldCustom,
        popUpNoteInCamera,
        popUpTaskUnComplete,
        popUpCreateNewTask,
        popUpAddOrUpdateNoteInCamera,
        popUpConfirmDeleteNoteHistory,
        filteredNameCamera,
        currentPage,
        totalPages,
        currentData,
        isReceivingNotifications,
        thresholdSetting,
        dataFromLocal,
        activeItemId,
        setActiveItemId,
        setIsReceivingNotifications,
        setThresholdSetting,
        setCurrentPage,
        setFilter,
        setCameraConfigMonitoring,
        setPhotoUploadSchedule,
        setSearchCameraConfig,
        setHighLightFilters,
        setDataFromLocal,
        setCameraOverview,
        setIdCamera,
        setIsLoading,
        setOrderBy,
        setIdNote,
        setSmartFilters,
        setModeFilter,
        setLoadingDailyWorkedHours,
        setCameraDailyWorkedHour,
        fetchReportsDataFromLocalStorage,
        getDataSmartFiltersFromLocalStorage,
        handleSave,
        handleSetPage,
        macCameras,
        setMacCameras,
        handleBatteryFilterClick,
    };
}

type CameraOverviewContextType = ReturnType<typeof useCameraOverviewProvider>;

export const CameraOverviewContext = createContext<CameraOverviewContextType>({} as CameraOverviewContextType);

export const useCameraOverview = () => useContext(CameraOverviewContext);
