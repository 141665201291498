import { Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraTask } from '@EcamModel/model/CameraTask';
import { AxiosInstance } from 'axios';
import { ICameraTaskHttpController, IGetCameraTaskProps } from '../ICameraTaskHttpController';

export class CameraTaskHttpController extends BaseHttpController<CameraTask> implements ICameraTaskHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-task', client);
    }

    async list(params: IGetCameraTaskProps): Promise<Paging<CameraTask>> {
        return await this.doPost({ path: 'filter', body: params }).then((res) => res.data);
    }

    async upsert(props: CameraTask): Promise<CameraTask> {
        return await this.doPost({ path: 'upsert-camera-task', body: props }).then((res) => res.data);
    }
}
