import IcMenu from '@assets/details-camera-icons/IcMenu';
import IcSetting from '@assets/details-camera-icons/IcSetting';
import BaseCheckbox from '@components/BaseCheckbox';
import BreadCrumbs from '@components/breadcumbs/BreadCumbs';
import IOSSwitch from '@components/IOSSwitch';
import StyledAutocomplete from '@components/styled-autocomplete';
import { GeoZoneCoordinates, GeoZones } from '@EcamModel/model';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    Button,
    CircularProgress,
    Fade,
    FormControl,
    FormControlLabel,
    Radio,
    RadioGroup,
    Stack,
    Tooltip,
    Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { validateDatePickerValue } from '@pages/cameras/add-new';
import color from '@theme/Colors';
import moment from 'moment';
import { Controller, FormProvider } from 'react-hook-form';
import { BsQuestion } from 'react-icons/bs';
import { HeadingSection } from '../../../../AddGeoZones';
import { Mode } from '../../../../CameraInformation';
import GracePeriods from '../../../../GracePeriods';
import OperationalHours from '../../../../OperationalHours';
import FramePolygonGeoOverview from '../../../components/FramePolygonGeoOverview';
import useAddGeoZones from '../hook/useAddGeoZones';
import FormAddGeoZonesPtzOverviewPage from './FormAddGeoZonesPtzOverviewPage';
import LoadingBreadCrumbs from '@components/skeleton/LoadingBreadCrumbs';

export default function AddGeoZonesCameraPTZ() {
    const {
        setStartDate,
        handleRadioChangeCameraType,
        updateOperationHours,
        setIsOverlap,
        handleAddGeoZoneOverview,
        shouldDisableButton,
        handleNavigateButton,
        loading,
        prevGeoZonePointsOverviewPage,
        conditionBreadcrumbs,
        errors,
        methods,
        selectedCameraType,
        contraventionAutocomplete,
        detectModeAutocomplete,
        gracePeriodForm,
        operationHours,
        isLoadingSave,
        startDate,
        imageElement,
        isHasCameraViewZoomId,
        typeDetailsIncludesCameraViewZoomId,
        isTypeOverview,
    } = useAddGeoZones();

    const handleDeleteGeoZonePoints = () => {
        methods.setValue('geoZonePoints', [] as GeoZoneCoordinates[], {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
        });
    };

    return (
        <Stack mt={1} width={'100%'}>
            <Stack mb={!isHasCameraViewZoomId ? 3 : 0}>
                <Typography variant="h3" mb={1}>
                    Add geo-zones
                </Typography>
                {loading ? <LoadingBreadCrumbs /> : <BreadCrumbs breadcrumbs={conditionBreadcrumbs} />}
            </Stack>
            {!isHasCameraViewZoomId && (
                <Grid container xs={12} alignItems="center">
                    <Grid xs={6} lg={2.5}>
                        <Typography variant="h5">Select geo-zone to add:</Typography>
                    </Grid>
                    <Grid
                        xs={6}
                        lg={9.5}
                        sx={{
                            textAlign: {
                                xs: 'end',
                                lg: 'start',
                            },
                        }}
                    >
                        <FormControl>
                            <RadioGroup row value={selectedCameraType} onChange={handleRadioChangeCameraType}>
                                <FormControlLabel
                                    sx={{
                                        mr: 2,
                                        '.MuiFormControlLabel-label': {
                                            mt: '3px !important',
                                        },
                                    }}
                                    control={<Radio value={0} />}
                                    label="Geo-zones overview"
                                />
                                <FormControlLabel
                                    sx={{
                                        '.MuiFormControlLabel-label': {
                                            mt: '3px !important',
                                        },
                                    }}
                                    control={<Radio value={1} />}
                                    label="Geo-zones detail"
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
            )}

            <FormProvider {...methods}>
                <Box>
                    {isTypeOverview && !isHasCameraViewZoomId && <FormAddGeoZonesPtzOverviewPage methods={methods} />}

                    {typeDetailsIncludesCameraViewZoomId && (
                        <Fade in={typeDetailsIncludesCameraViewZoomId} timeout={800}>
                            <Box mt={3}>
                                <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                                    <Controller
                                        name="contraventionReasonTranslations"
                                        control={methods.control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please select a Contravention reason.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <StyledAutocomplete
                                                {...field}
                                                {...contraventionAutocomplete}
                                                isRequired
                                                getOptionLabel={(o) => o.Summary}
                                                label="Contravention reason"
                                                value={field.value}
                                                placeholder="Select reason"
                                                disabledAllOption
                                                onChange={(c) => {
                                                    methods.setValue('contraventionReasonTranslations', c!, {
                                                        shouldValidate: true,
                                                        shouldTouch: true,
                                                        shouldDirty: true,
                                                    });
                                                }}
                                                wrapperWidth="100%"
                                            />
                                        )}
                                    />
                                    <Controller
                                        name="detectMode"
                                        control={methods.control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: 'Please select a detectode.',
                                            },
                                        }}
                                        render={({ field, fieldState: { error } }) => (
                                            <StyledAutocomplete
                                                {...field}
                                                {...detectModeAutocomplete}
                                                isRequired
                                                getOptionLabel={(o) => o.Label}
                                                renderOptionTooltip={(o) => (
                                                    <Tooltip title={o.Note} arrow placement="top">
                                                        <Stack
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            sx={{
                                                                p: 0.1,
                                                                background: (theme) => theme.palette.info.main,
                                                                borderRadius: '100%',
                                                            }}
                                                        >
                                                            <BsQuestion color="#fff" fontSize={16} />
                                                        </Stack>
                                                    </Tooltip>
                                                )}
                                                label="Detect mode"
                                                value={field.value}
                                                disabledAllOption
                                                onChange={(c) => {
                                                    field.onChange(c);
                                                }}
                                                wrapperWidth="100%"
                                            />
                                        )}
                                    />
                                    <div>
                                        <Controller
                                            name="isActive"
                                            control={methods.control}
                                            render={({ field, fieldState: { error } }) => (
                                                <IOSSwitch
                                                    {...field}
                                                    label={
                                                        <Typography sx={{ minWidth: '55px' }}>
                                                            {!!field.value ? 'Active' : 'Inactive'}
                                                        </Typography>
                                                    }
                                                    iosSwitchProps={{
                                                        checked: field.value,
                                                        onChange(event, checked) {
                                                            field.onChange(event);
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </div>
                                </Stack>

                                {/* checkbox ExemptVRNs */}
                                <Stack
                                    direction="row"
                                    alignItems="center"
                                    justifyContent={'space-between'}
                                    width="100%"
                                    mt={2}
                                >
                                    <Stack direction="row" alignItems="center">
                                        <Controller
                                            name="isExemptVRNs"
                                            control={methods.control}
                                            render={({ field, fieldState: { error } }) => (
                                                <FormControlLabel
                                                    control={
                                                        <BaseCheckbox
                                                            checked={field.value}
                                                            onChange={(_, checked) => {
                                                                field.onChange(checked);
                                                            }}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        <Typography ml={0.5} mt={0.3}>
                                                            Exempt VRNs on Permit lists
                                                        </Typography>
                                                    }
                                                    sx={{ ml: 0 }}
                                                />
                                            )}
                                        />
                                    </Stack>
                                </Stack>
                                {/* end checkbox ExemptVRNs  */}

                                <>
                                    {/* Draw angle box */}
                                    <HeadingSection icon={<IcMenu />} heading="Zone marking" />
                                    <Controller
                                        name="geoZonePoints"
                                        control={methods.control}
                                        render={({ field, fieldState: { error } }) => (
                                            <FramePolygonGeoOverview
                                                {...field}
                                                imageElement={imageElement!}
                                                mode={Mode.create}
                                                prevGeoZone={prevGeoZonePointsOverviewPage as GeoZoneCoordinates[][]}
                                                selectedGeoZone={{} as GeoZones}
                                                onChange={(points) => {
                                                    methods.setValue('geoZonePoints', points, {
                                                        shouldValidate: true,
                                                        shouldTouch: true,
                                                        shouldDirty: true,
                                                    });
                                                }}
                                                onDelete={handleDeleteGeoZonePoints}
                                            />
                                        )}
                                    />
                                </>

                                {/* Operational periods */}
                                <HeadingSection icon={<IcSetting />} heading="Time setting" />
                                <Stack
                                    p={1.7}
                                    sx={{
                                        border: '1px solid #DDDDDD',
                                        borderWidth: '0px 1px 1px 1px',
                                        borderRadius: '0px 0px 4px 4px',
                                    }}
                                    spacing={2}
                                >
                                    <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={2}
                                        sx={{ borderBottom: '1px solid #DDDDDD', pb: 1.7 }}
                                    >
                                        <Typography>
                                            Effective from<span style={{ color: 'red' }}>*</span>:
                                        </Typography>
                                        <DesktopDatePicker
                                            value={startDate}
                                            format="DD/MM/YYYY"
                                            shouldDisableDate={(date) =>
                                                validateDatePickerValue(moment(date).toDate()) !== null
                                            }
                                            onChange={(e) => setStartDate(e!)}
                                            slotProps={{
                                                textField: {
                                                    inputProps: {
                                                        readOnly: true,
                                                    },
                                                    sx: {
                                                        width: 250,
                                                    },
                                                },
                                            }}
                                        />
                                    </Stack>
                                    <Stack sx={{ borderBottom: '1px solid #DDDDDD', pb: 2 }} spacing={2}>
                                        <Typography>
                                            Grace period setting<span style={{ color: 'red' }}>*</span>:
                                        </Typography>
                                        <GracePeriods
                                            gracePeriods={gracePeriodForm}
                                            control={methods.control}
                                            errors={errors}
                                            watch={methods.watch}
                                        />
                                    </Stack>

                                    {operationHours.map((item, index, arr) => {
                                        return (
                                            <OperationalHours
                                                geoZoneHourConfigWithTimeRanges={item as any}
                                                geoZoneGracePeriod={methods.watch().gracePeriods}
                                                onChange={(item, error) => {
                                                    const operationHours = {
                                                        Weekday: item.Weekday,
                                                        TimeRanges: item.TimeRanges,
                                                        AllDay: item.AllDay,
                                                        Status: item.Status,
                                                    };
                                                    updateOperationHours(index, operationHours);

                                                    setIsOverlap(error);
                                                }}
                                            />
                                        );
                                    })}
                                </Stack>
                            </Box>
                        </Fade>
                    )}
                </Box>
            </FormProvider>

            {/* Button */}
            <Stack mt={3} direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Button
                    variant="cancel"
                    sx={{ flexBasis: 130 }}
                    disabled={isLoadingSave}
                    onClick={handleNavigateButton}
                >
                    Cancel
                </Button>
                <LoadingButton
                    sx={{
                        minWidth: 130,
                    }}
                    loading={isLoadingSave}
                    loadingPosition="center"
                    variant="contained"
                    disabled={shouldDisableButton() || isLoadingSave}
                    onClick={handleAddGeoZoneOverview}
                >
                    Save
                </LoadingButton>
            </Stack>
        </Stack>
    );
}
