import { Paging } from '@Core';
import { IUpdateConfigIsVerificationsEnabledResponse, SortByValue } from '@EcamModel/controllers/ICameraHttpController';
import {
    CameraWithInfo,
    ExemptionPeriods,
    LocationSMStatus,
    PowerSupply,
    PurposeType,
    SolarPanelPositionType,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import IcEdit from '@assets/details-camera-icons/IcEdit';
import ICDelete from '@assets/jsx-icon/ICDelete';
import BaseListNote, { tableHeaders } from '@components/BaseListNote';
import { BaseTextField } from '@components/BaseTextField';
import IOSSwitch from '@components/IOSSwitch';
import PopUpWarning from '@components/PopUpWarning';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';
import { CellOfRow } from '@components/tables';
import { pushError, pushSuccess } from '@components/toast';
import {
    cameraController,
    cameraNoteHttpController,
    cameraPTZConfigureRequestHttpController,
} from '@controllers/index';
import usePopUp, { UsePopUpReturnType } from '@hooks/usePopUp';
import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    Stack,
    SxProps,
    TableCell,
    Tooltip,
    Typography,
} from '@mui/material';
import {
    PositionSolarPanelLabel,
    camServiceTypes,
    positionSolarPanelReadableMapping,
    powerSupplyMapping,
} from '@pages/cameras/add-new';
import { BaseSelect, Filter, cameraSortByStatus } from '@pages/cameras/list';
import {
    cameraPriorityReadableMapping,
    getBatteryIcon,
    purposesReadableMapping,
} from '@pages/cameras/list/components/CameraItem';
import NoDataCameras from '@pages/cameras/list/components/NoDataCameras';
import SearchTypeSelect from '@pages/cameras/list/components/SearchTypeSelect';
import { DangerTooltip } from '@pages/cameras/list/components/TooltipDangerIconButton';
import ConnectedIcon from '@pages/cameras/list/components/icons/ConnectedIcon';
import DisconnectedIcon from '@pages/cameras/list/components/icons/DisconnectedIcon';
import color from '@theme/Colors';
import moment from 'moment';
import { Dispatch, ReactNode, SetStateAction, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { BiHide, BiShow } from 'react-icons/bi';
import { FiSearch } from 'react-icons/fi';
import { MdAdd } from 'react-icons/md';
import { TbBook2 } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { formatDateTime, formatTimeDayHourMinute, formatUKDateTime } from 'src/helpers';
import { useBackdrop } from 'src/providers/BackdropProvider';
import { useDebounce } from 'usehooks-ts';
import ImageCarousel from './ImageCarousel';
import PopUpAddExemption from './PopUpAddExemption';
import PopUpEditAllocatedZone from './PopUpEditAllocatedZone';
import PopUpEditMilesightAccount from './PopUpEditMilesightAccount';
import PopUpEditService from './PopUpEditService';
import PopUpHistoryExemption from './PopUpHistoryExemption';
import PopUpNoteHistory from './PopUpNoteHistory';
import PopUpEditCameraPhotoInstalled from './PupupEditCameraPhotoInstalled';
import PopUpEditDeployDate from './modals/PopUpEditDeployDate';
import PopUpEditPurposes from './modals/PopUpEditPurposes';
// import { PTZSysInfo } from '@EcamModel/model/PTZSysInfo';
// import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import LoadingTextSkeleton from '@components/skeleton/LoadingTextSkeleton';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { AxiosError } from '@controllers/axiosClient';
import { useCameraDetailContext } from '@pages/cameras/hook/CameraDetailsProvider';
import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';

type Props = {
    camera: CameraWithInfo;
    setCamera: Dispatch<SetStateAction<CameraWithInfo>>;
    onUpdateCamera?(camera: CameraWithInfo): Promise<void>;
    onUpdateService?(camera: CameraWithInfo): void;
    onAddExemption?(exemption: ExemptionPeriods): void;
    onDeleteExemption?(exemptionId: number): void;
    loading?: false;
    setLoading?: Dispatch<SetStateAction<boolean>>;
    isHideAction?: boolean;
};
export type FormValues = {
    name: string;
    mac: string;
    note: string;
    assets: string;
    fullAddress: string;
    solarPanelPosition: PositionSolarPanelLabel | SolarPanelPositionType;
    position: {
        lat: number;
        lng: number;
    };
};

export const LocationStatusReadableMapping = {
    [LocationSMStatus.Decommissioned]: 'Decommissioned',
    [LocationSMStatus.Live]: 'Live',
    [LocationSMStatus.OnHold]: 'On hold',
    [LocationSMStatus.Prospective]: 'Prospective',
    [LocationSMStatus.Transferred]: 'Transferred',
};

export enum Mode {
    create,
    edit,
}

export const cameraNoteSortByType: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Last updated',
        Value: 'Updated',
    },
    {
        Id: 2,
        Name: 'Created date',
        Value: 'Created',
    },
];

export enum DetailsGroup {
    GENERAL,
    UPDATED,
    CREATED,
}

export type DetailsInformation = {
    id: number;
    label: string;
    value: ReactNode;
    icon?: ReactNode;
    isHide?: boolean;
    groupDetail?: DetailsGroup;
};

export default function CameraInformation(props: Props) {
    const { camera, isHideAction = false, setCamera } = props;
    const queryClient = useQueryClient();

    const {
        watch,
        reset,
        control,
        formState: { isDirty, isValid, errors, dirtyFields, isSubmitting },
    } = useForm<FormValues>({
        mode: 'all',
        defaultValues: {
            name: camera.Name,
            mac: camera.Mac,
            note: camera.Notes,
            assets: camera.Asset,
            // fullAddress: camera.FullAddress,
            solarPanelPosition: positionSolarPanelReadableMapping[camera?.SolarPanelPosition!],
            // position: {
            //     lat: camera.Latitude,
            //     lng: camera.Longitude,
            // },
        },
    });

    const { isLoadingDetailsCameraPTZ, cameraDetailsPTZ, setCameraDetailsPTZ, setIsLoadingDetailsCameraPTZ } =
        useCameraDetailContext();

    const [mode, setMode] = useState<Mode | null>(null);
    const [selectedExemption, setSelectedExemption] = useState<ExemptionPeriods>({} as ExemptionPeriods);
    const [showPassword, setShowPassword] = useState(false);

    const [filter, setFilter] = useState<Partial<Filter>>({
        page: 1,
        from: undefined,
        to: undefined,
        search: '',
        sortedOrder: cameraSortByStatus[1],
        sort: cameraNoteSortByType[0],
    });

    const [idNote, setIdNote] = useState<number>(0);
    const [searchValue, setSearchValue] = useState<string>('');
    const debouncedValue = useDebounce<string>(searchValue ?? '', 500);

    const getStatusColor = (status: PurposeType) => {
        const colorRender = (props: { color: string; bgcolor: string }) => ({
            colorText: props.color,
            bgColor: props.bgcolor,
        });

        const mappingColor = {
            [PurposeType.Prospective]: colorRender({
                color: color.secondary,
                bgcolor: '#E5F2FF',
            }),
            [PurposeType.Live]: colorRender({
                bgcolor: '#E5F5ED',
                color: '#009D4F',
            }),
            [PurposeType.Testing]: colorRender({
                bgcolor: '#CC00661A',
                color: '#CC0066',
            }),
            [PurposeType.Decommissioned]: colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            }),
            [PurposeType.OnHold]: colorRender({
                bgcolor: '#FFF8E5',
                color: '#FFB600',
            }),
        };
        return (
            mappingColor[status] ||
            colorRender({
                bgcolor: '#FCE8E5',
                color: '#E01B00',
            })
        );
    };

    const { colorText, bgColor } = getStatusColor(camera.PurposeType!);

    // const [cameraDetailsPTZ, setCameraDetailsPTZ] = useState({});
    // const [cameraDetailsPTZ, setCameraDetailsPTZ] = useState<PTZSysInfo>({} as PTZSysInfo);
    // const [isLoadingDetailsCameraPTZ, setIsLoadingDetailsCameraPTZ] = useState<boolean>(true);

    const handleGetDetailsCameraPTZ = async () => {
        const _filter: ISendActionGet = {
            ip: camera.IP,
            authenticate: {
                password: camera.PassWord,
                username: camera.UserName,
            },
        };
        setIsLoadingDetailsCameraPTZ(true);
        await cameraPTZConfigureRequestHttpController
            .getPTZSystemInfo(_filter)
            .then((res) => {
                setCameraDetailsPTZ(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingDetailsCameraPTZ(false);
            });
    };

    useEffect(() => {
        if (camera.PTZCameraId !== null) {
            handleGetDetailsCameraPTZ();
        }
    }, []);

    const popUpEditCameraPhotoInstalled = usePopUp();
    const popUpEditPurposes = usePopUp();
    const popUpEditProductDate = usePopUp();

    const isCameraLocationDecommissioned = camera.Zone?.Location?.LocationStatus === LocationSMStatus.Decommissioned;

    const popUpWarning = usePopUp();
    const popUpWarningEditMac = usePopUp();
    const popUpEditAllocatedZone = usePopUp();
    const popUpAddExemption = usePopUp();
    const popUpWarningVerification = usePopUp();
    const popUpConfirmDelete = usePopUp();
    const popUpEditMilesightAccount = usePopUp();
    const popupHistoryExemption = usePopUp();
    const popupNoteHistory = usePopUp();
    const popUpConfirmDeleteNoteHistory = usePopUp();

    const [isActive, setIsActive] = useState(camera.Status);
    const [isVerification, setIsVerification] = useState<IUpdateConfigIsVerificationsEnabledResponse>({
        IsVerificationsEnabled: camera.IsVerificationsEnabled!,
        EnablePcnVerificationAt: camera.EnablePcnVerificationAt!,
    });
    const [isEnabled, setIsEnabled] = useState(true);

    const now = new Date();
    const deployDate = new Date(camera.DeployedDate);
    // const boolean = moment(now).isBefore(deployDate) && Boolean(isActive === Status.NotAvailable): boolean = moment(now).isBefore(deployDate) && Boolean(isActive === Status.NotAvailable);
    const handleOnConfirm = () => {
        cameraController
            .changeStatus(camera.Id!)
            .then((res) => {
                queryClient.invalidateQueries(['trailAuditEvent']);
                pushSuccess('Changed status successfully');
                setIsActive(res.Status);
                setCamera((prev) => ({
                    ...prev,
                    Status: res.Status,
                }));
            })
            .catch((err) => { })
            .finally(() => {
                popUpWarning.onClose();
            });
    };
    const backdrop = useBackdrop();
    const handleConfirmVerification = async (cameraId: number, isEnabled: boolean) => {
        backdrop.setTrue();
        await cameraController
            .updateConfigIsVerificationsEnabled({ CameraId: cameraId, Enable: isEnabled })
            .then((res) => {
                queryClient.invalidateQueries(['trailAuditEvent']);
                setIsVerification(res);
                pushSuccess('Updated successfully');
                setCamera((prev) => ({
                    ...prev,
                    IsVerificationsEnabled: res.IsVerificationsEnabled,
                    EnablePcnVerificationAt: res.EnablePcnVerificationAt,
                }));
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                backdrop.setFalse();
            });
    };

    useEffect(() => {
        setIsActive(camera.Status);
        setIsVerification({
            IsVerificationsEnabled: camera.IsVerificationsEnabled!,
            EnablePcnVerificationAt: camera.EnablePcnVerificationAt!,
        });
    }, [camera.IsVerificationsEnabled, camera.Status]);

    const popUpEditService = usePopUp({});

    const checkPostCaptureEnable = () => {
        const postCaptureEnable = {
            [0]: 'Disable',
            [1]: 'Enable',
        };
        return postCaptureEnable[camera.Solar4gCamera?.Solar4gCaptureInfo?.PostCaptureEnable!];
    };

    const sortingConditions =
        filter.sortedOrder?.Value === SortByValue.Asc ? `${filter.sort?.Value}` : `-${filter.sort?.Value}`;

    const handleGetListNote = async () => {
        const response = await cameraNoteHttpController.list({
            page: filter.page ?? 1,
            CameraId: camera.Id ?? 0,
            pageSize: 8,
            filter: {
                CameraId: camera.Id ?? 0,
            },
            search: {
                content: searchValue ?? '',
                fields: ['CreatedBy'],
            },
            sorts: [sortingConditions] as any,
            From: filter.from ?? undefined,
            To: filter.to ?? undefined,
        });
        return response;
    };

    const {
        data: notes,
        error,
        isLoading: loading,
    } = useQuery(
        ['listNotes', camera.Id, filter.page, debouncedValue, filter.to, filter.from, filter.sortedOrder, filter.sort],
        handleGetListNote,
        {
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            enabled: !!camera.Id,
        }
    );

    const upsertCameraNoteAPI = async (data: CameraNotes) => {
        const response = await cameraNoteHttpController.upsert(data);
        return response;
    };

    const mutationUpsertCameraNote = useMutation(upsertCameraNoteAPI, {
        onSuccess: async (data, variables) => {
            queryClient.invalidateQueries(['listNotes']);
            queryClient.invalidateQueries(['trailAuditEvent']);
            pushSuccess(variables.Id ? 'Update successfully' : 'Add successfully');
        },
        onError: (err: AxiosError) => {
            pushError(err.message);
        },
        onSettled: () => {
            backdrop.setFalse();
        },
    });

    const handleAddOrUpdateNote = async (idCamera: number, note: string, idNote?: number) => {
        backdrop.setTrue();
        const data: CameraNotes = {
            CameraId: idCamera,
            Notes: note,
            Id: mode === Mode.edit ? idNote : undefined,
        };

        await mutationUpsertCameraNote.mutateAsync(data);
    };

    const deleteNoteAPI = async (idNote: number) => {
        const response = await cameraNoteHttpController.delete(idNote.toString());
        return response;
    };

    const mutationDeleteNote = useMutation(deleteNoteAPI, {
        onSuccess: async () => {
            queryClient.invalidateQueries(['listNotes']);
            queryClient.invalidateQueries(['trailAuditEvent']);
            pushSuccess('Delete successfully');
        },
        onError: (err: AxiosError) => {
            pushError(err.message);
        },
        onSettled: () => {
            backdrop.setFalse();
        },
    });

    const handleDeleteNote = async (idNote: number) => {
        backdrop.setTrue();
        await mutationDeleteNote.mutateAsync(idNote);
    };

    const detailHeaderTop: DetailsInformation[] = [
        {
            id: 1,
            label: 'Camera type',
            value: (
                <Typography variant="body1" color={'#85858A'}>
                    {camera.VendorCameraId !== null ? 'Camera Solar' : 'Camera PTZ'}
                </Typography>
            ),
        },
        {
            id: 2,
            label: 'Status',
            value: (
                <>
                    {isHideAction ? (
                        <Stack
                            sx={{
                                width: 80,
                                height: 30,
                                background: (theme) => theme.palette.error.main,
                                borderRadius: 1,
                            }}
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography color="#FFFFFF">Deleted</Typography>
                        </Stack>
                    ) : (
                        <Box display="flex" alignItems="center">
                            <Typography variant="body1" color={'#85858A'} sx={{ marginRight: 1 }}>
                                {Boolean(isActive === Status.Active) ? 'Active' : 'Inactive'}
                            </Typography>
                            <IOSSwitch
                                iosSwitchProps={{
                                    checked: Boolean(isActive === Status.Active),
                                    onChange(event, checked) {
                                        popUpWarning.setTrue();
                                    },
                                    disabled:
                                        moment(now).isBefore(deployDate) || Boolean(isActive === Status.NotAvailable),
                                }}
                            />
                        </Box>
                    )}
                </>
            ),
        },
        {
            id: 3,
            label: 'Battery',
            value: (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" color={'#85858A'}>
                        {camera.Solar4gCamera?.LastBatteryStatus !== undefined
                            ? `${camera.Solar4gCamera.LastBatteryStatus}%`
                            : 'Not available'}
                    </Typography>
                    {getBatteryIcon(camera.Solar4gCamera?.LastBatteryStatus ?? 0)}
                </Stack>
            ),
        },
        {
            id: 4,
            label: 'Priority',
            value: (
                <Typography variant="body1" color={'#85858A'}>
                    {camera.Priority !== null ? cameraPriorityReadableMapping[camera.Priority!] : 'None'}
                </Typography>
            ),
        },
        {
            id: 5,
            label: 'Network',
            value: (
                <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="body1" color={'#85858A'}>
                        {camera.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected ? 'Connected' : 'Not available'}
                    </Typography>
                    {camera.Solar4gCamera?.LastWifiStatus === WifiStatus.Connected ? (
                        <ConnectedIcon width={18} height={18} />
                    ) : (
                        <DisconnectedIcon width={18} height={18} />
                    )}
                </Stack>
            ),
        },
    ];

    const detailHeaderBot: DetailsInformation[] = [
        {
            id: 1,
            label: 'Created by',
            value: (
                <Typography variant="body1" color={'#85858A'}>
                    {camera.CreatedBy}
                </Typography>
            ),
        },
        {
            id: 2,
            label: 'Created at',
            value: (
                <Typography variant="body1" color={'#85858A'}>
                    {moment(camera.Created).local().format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
            ),
        },
        {
            id: 3,
            label: 'Last contact',
            value: (
                <Typography variant="body1" color={'#85858A'}>
                    {moment(camera.Solar4gCamera?.LastContact).local().format('DD/MM/YYYY HH:mm:ss')}
                </Typography>
            ),
        },
    ];

    const tabDetailsCameraSolar: DetailsInformation[] = [
        {
            id: 1,
            label: 'Name',
            value: <Typography variant="body1">{camera.Name ?? ''}</Typography>,
        },
        {
            id: 2,
            label: 'MAC',
            value: <Typography>{camera.Mac}</Typography>,
        },
        {
            id: 3,
            label: 'Purposes',
            value: (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Chip
                        size="small"
                        sx={{
                            borderRadius: '16px',
                            fontSize: 12,
                            p: '5px',
                            background: bgColor,
                            color: colorText,
                        }}
                        label={purposesReadableMapping?.[camera.PurposeType!] ?? 'N/A'}
                    />
                </Stack>
            ),
        },
        {
            id: 4,
            label: 'Asset',
            value: <Typography>{camera.Asset}</Typography>,
        },
        {
            id: 5,
            label: 'Lens capacity',
            value: <Typography>{camera.LensCapacity}</Typography>,
        },
        {
            id: 6,
            label: 'Firmware version',
            value: <Typography>{camera.Solar4gCamera?.Solar4gSysInfo?.Software}</Typography>,
        },
        {
            id: 7,
            label: 'Power supply',
            value: <Typography>{powerSupplyMapping[camera?.PowerSupply!]?.label || ''}</Typography>,
        },
        {
            id: 7,
            label: 'Solar panel position',
            value: (
                <Typography>{positionSolarPanelReadableMapping[camera?.SolarPanelPosition!]?.label || ''}</Typography>
            ),
            isHide: camera.PowerSupply === PowerSupply.Wired,
        },
    ];

    const tabDetailsCameraPTZ: DetailsInformation[] = [
        {
            id: 1,
            label: 'Name',
            value: <Typography variant="body1">{camera.Name}</Typography>,
        },
        {
            id: 2,
            label: 'MAC',
            value: <Typography>{camera.Mac}</Typography>,
        },
        {
            id: 3,
            label: 'Purposes',
            value: (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Chip
                        size="small"
                        sx={{
                            borderRadius: '16px',
                            fontSize: 12,
                            p: '5px',
                            background: bgColor,
                            color: colorText,
                        }}
                        label={purposesReadableMapping[camera.PurposeType!] ?? 'N/A'}
                    />
                </Stack>
            ),
        },
        {
            id: 4,
            label: 'Asset',
            value: <Typography>{camera.Asset}</Typography>,
        },
        {
            id: 5,
            label: 'Lens capacity',
            value: <Typography>{camera.LensCapacity}</Typography>,
        },
        {
            id: 6,
            label: 'Product model',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.Model}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 7,
            label: 'Hardware version',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.HardwareVersion}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 8,
            label: 'Firmware version',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.FirmwareVersion}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 9,
            label: 'S/N',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.SnCode}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 10,
            label: 'Device information',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.DeviceInformation}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },

        {
            id: 11,
            label: 'Alarm input',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.AlarmInputSupport}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 12,
            label: 'Alarm output',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={cameraDetailsPTZ.AlarmOutputSupport}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
        {
            id: 13,
            label: 'Uptime',
            value: (
                <LoadingTextSkeleton
                    conditions={isLoadingDetailsCameraPTZ}
                    value={formatTimeDayHourMinute(cameraDetailsPTZ.Runtime)}
                    style={{
                        width: '70%',
                    }}
                />
            ),
        },
    ];

    const tabAllocatedZone: DetailsInformation[] = [
        {
            id: 1,
            label: 'Location',
            value: <Typography>{camera.LocationName}</Typography>,
        },
        {
            id: 2,
            label: 'Zone',
            value: <Typography>{camera.ZoneName}</Typography>,
        },
        {
            id: 3,
            label: 'Location status',
            value: (
                <Typography
                    bgcolor={isCameraLocationDecommissioned ? color.danger : 'transparent'}
                    color={isCameraLocationDecommissioned ? color.white : color.textPrimary}
                    p={isCameraLocationDecommissioned ? '4px 10px' : 0}
                    borderRadius={1}
                    maxWidth={'max-content'}
                >
                    {camera.Zone?.Location?.LocationStatus !== null
                        ? LocationStatusReadableMapping[camera?.Zone?.Location?.LocationStatus ?? 0]
                        : 'N/A'}
                </Typography>
            ),
        },
    ];

    const tabDataProcessing: DetailsInformation[] = [
        {
            id: 1,
            label: 'Enable verifications',
            value: (
                <>
                    {isHideAction ? (
                        <Typography>On</Typography>
                    ) : (
                        <IOSSwitch
                            label={
                                <Typography sx={{ minWidth: '55px' }}>
                                    {!!isVerification.IsVerificationsEnabled ? 'On' : 'Off'}
                                </Typography>
                            }
                            iosSwitchProps={{
                                checked: isVerification.IsVerificationsEnabled,
                                onChange(event, checked) {
                                    setIsEnabled(checked);
                                    popUpWarningVerification.setTrue();
                                },
                            }}
                        />
                    )}
                </>
            ),
        },
        {
            id: 2,
            label: 'Enable verifications at',
            value: (
                <Typography sx={{ minWidth: '55px' }}>
                    {isVerification.EnablePcnVerificationAt || camera.EnablePcnVerificationAt
                        ? formatUKDateTime(isVerification.EnablePcnVerificationAt)
                        : 'N/A'}
                </Typography>
            ),
        },
    ];

    const tabLoginInformation: DetailsInformation[] = [
        {
            id: 1,
            label: 'IP',
            value: (
                <a href={`http://${camera.IP}`} target="_blank" rel="noreferrer">
                    {camera.IP}
                </a>
            ),
        },
        {
            id: 2,
            label: 'Username',
            value: <Typography>{camera.UserName}</Typography>,
        },
        {
            id: 3,
            label: 'Password',
            value: (
                <Stack direction="row" alignItems="center" justifyContent="space-between">
                    <Typography>{showPassword ? camera.PassWord : camera.PassWord?.replace(/./g, '*')}</Typography>
                    {showPassword ? (
                        <BiHide fontSize={20} color="#85858A" cursor="pointer" onClick={() => setShowPassword(false)} />
                    ) : (
                        <BiShow fontSize={20} color="#85858A" cursor="pointer" onClick={() => setShowPassword(true)} />
                    )}
                </Stack>
            ),
        },
    ];

    const idsDetailHeaderTop = camera.PTZCameraId !== null ? [1, 2] : [1, 2, 3, 4];
    const idsDetailHeaderBot = camera.PTZCameraId !== null ? [1, 2] : [1, 2, 3];

    const filteredDetailHeaderTop = detailHeaderTop.filter((d) => idsDetailHeaderTop.includes(d.id));
    const filteredDetailHeaderBot = detailHeaderBot.filter((d) => idsDetailHeaderBot.includes(d.id));

    const navigate = useNavigate();

    return (
        <>
            <Stack padding={2} borderRadius={'0px 16px 16px 16px'} border={'1px solid #E4E7EC'}>
                {/* InfoHeader */}
                <Stack width={'100%'} direction={'row'} justifyContent={'space-between'}>
                    <Box>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            <Typography variant="h5">Information camera:</Typography>
                            <Typography variant="h5" color={color.secondary}>
                                {camera.Name ?? ''}
                            </Typography>
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'} my={1}>
                            {filteredDetailHeaderTop.map((item, index) => (
                                <Box key={index} display="flex" alignItems="center">
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography variant="body1" color={'#85858A'}>
                                            {item.label}:
                                        </Typography>
                                        <Typography variant="body1" color={'#85858A'}>
                                            {item.value}
                                        </Typography>
                                    </Stack>
                                    {index < filteredDetailHeaderTop.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                borderRightWidth: 1,
                                                borderColor: '#85858A',
                                                px: '4px',
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Stack>
                        <Stack direction={'row'} spacing={1} alignItems={'center'}>
                            {filteredDetailHeaderBot.map((item, index) => (
                                <Box key={index} display="flex" alignItems="center">
                                    <Stack direction={'row'} spacing={1} alignItems={'center'}>
                                        <Typography variant="body1" color={'#85858A'}>
                                            {item.label}:
                                        </Typography>
                                        <Typography variant="body1" color={'#85858A'}>
                                            {item.value}
                                        </Typography>
                                    </Stack>
                                    {index < filteredDetailHeaderBot.length - 1 && (
                                        <Divider
                                            orientation="vertical"
                                            flexItem
                                            sx={{
                                                borderRightWidth: 1,
                                                borderColor: '#85858A',
                                                px: '4px',
                                            }}
                                        />
                                    )}
                                </Box>
                            ))}
                        </Stack>
                    </Box>
                    <Button
                        variant="contained"
                        onClick={() => {
                            navigate(`/locations/${camera.Zone?.LocationId}/cameras/${camera.Id}/edit`);
                        }}
                    >
                        Edit camera
                    </Button>
                </Stack>

                <Grid
                    container
                    xs={12}
                    bgcolor={color.grey100}
                    p={'16px 16px 0px 16px'}
                    borderRadius={'8px 8px 0px 0px'}
                    mt={2}
                    direction={'row'}
                    justifyContent={'space-between'}
                >
                    <Grid item container xs={5.9} height={'100%'} gap={2} justifyContent={'space-between'}>
                        {/* Details */}
                        <BaseComponentDetails
                            title="Details"
                            styleTitle={{
                                mb: 2,
                            }}
                            content={
                                <Grid item container xs={12} spacing={2}>
                                    {camera.PTZCameraId !== null ? (
                                        <>
                                            {tabDetailsCameraPTZ.map((item, index) => (
                                                <Grid item container key={index} xs={12}>
                                                    <Grid item xs={5}>
                                                        <Typography variant="body1">{item.label}:</Typography>
                                                    </Grid>
                                                    <Grid item xs={7}>
                                                        <Typography variant="body1">{item.value}</Typography>
                                                    </Grid>
                                                </Grid>
                                            ))}
                                        </>
                                    ) : (
                                        <>
                                            {tabDetailsCameraSolar
                                                .filter((item) => !Boolean(item.isHide))
                                                .map((item, index) => (
                                                    <Grid item container key={index} xs={12}>
                                                        <Grid item xs={5}>
                                                            <Typography variant="body1">{item.label}:</Typography>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <Typography variant="body1">{item.value}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                ))}
                                        </>
                                    )}
                                </Grid>
                            }
                        />

                        {/* Production */}
                        <BaseComponentDetails
                            title="Production"
                            styleTitle={{
                                mb: 2,
                            }}
                            // isHideAction={isHideAction}
                            // iconAdd={<IcEdit style={{ width: '14px', height: '14px' }} />}
                            // titleTooltipIconAdd="Edit Production"
                            // popupAdd={popUpEditProductDate}
                            content={
                                <Grid item container xs={12} spacing={2}>
                                    <Grid item container xs={12}>
                                        <Grid item xs={5}>
                                            <Typography variant="body1">Production:</Typography>
                                        </Grid>
                                        <Grid item xs={7}>
                                            <Typography variant="body1">
                                                {moment(camera.DeployedDate).format('DD/MM/YYYY')}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        />

                        {/* Service */}
                        {camera.PTZCameraId === null && (
                            <BaseComponentDetails
                                title="Service"
                                // isHideAction={isHideAction}
                                // iconAdd={<IcEdit style={{ width: '14px', height: '14px' }} />}
                                // titleTooltipIconAdd="Edit service"
                                // popupAdd={popUpEditService}
                                content={
                                    <Stack mt={2} spacing={2} width={'100%'}>
                                        <Grid xs={12} container>
                                            <Grid xs={5}>
                                                <Typography>Type service:</Typography>
                                            </Grid>
                                            <Grid xs={7}>
                                                <Typography>{camServiceTypes?.[camera.ServiceType]?.label}</Typography>
                                            </Grid>
                                        </Grid>
                                        {camera.ANPRCameraId && (
                                            <Grid xs={12} container>
                                                <Grid xs={5}>
                                                    <Typography>ANPR ID:</Typography>
                                                </Grid>
                                                <Grid xs={7}>
                                                    <Typography>{camera.ANPRCameraId}</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Stack>
                                }
                            />
                        )}
                    </Grid>

                    <Grid item container xs={5.9} height={'100%'} gap={2} justifyContent={'space-between'}>
                        {/* Allocated zone */}
                        <BaseComponentDetails
                            title="Allocated zone"
                            styleTitle={{
                                mb: 2,
                            }}
                            content={
                                <Grid item container xs={12} spacing={2}>
                                    {tabAllocatedZone.map((item, index) => (
                                        <Grid item container key={index} xs={12}>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">{item.label}:</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="body1">{item.value}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        />

                        {/* Data processing */}
                        <BaseComponentDetails
                            title="Data processing"
                            styleTitle={{
                                mb: 2,
                            }}
                            content={
                                <Grid item container xs={12} spacing={2}>
                                    {tabDataProcessing.map((item, index) => (
                                        <Grid item container key={index} xs={12}>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">{item.label}:</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="body1">{item.value}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        />

                        {/* Camera login information */}
                        <BaseComponentDetails
                            title="Camera login information"
                            // isHideAction={isHideAction}
                            // iconAdd={<IcEdit style={{ width: '14px', height: '14px' }} />}
                            // titleTooltipIconAdd="Edit camera login information"
                            // popupAdd={popUpEditMilesightAccount}
                            styleTitle={{
                                mb: 2,
                            }}
                            content={
                                <Grid item container xs={12} spacing={2}>
                                    {tabLoginInformation.map((item, index) => (
                                        <Grid item container key={index} xs={12}>
                                            <Grid item xs={5}>
                                                <Typography variant="body1">{item.label}:</Typography>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="body1">{item.value}</Typography>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            }
                        />

                        {/* Service */}
                        {camera.PTZCameraId !== null && (
                            <BaseComponentDetails
                                title="Service"
                                // isHideAction={isHideAction}
                                // iconAdd={<IcEdit style={{ width: '14px', height: '14px' }} />}
                                // titleTooltipIconAdd="Edit service"
                                // popupAdd={popUpEditService}
                                content={
                                    <Stack mt={2} spacing={2} width={'100%'}>
                                        <Grid xs={12} container>
                                            <Grid xs={5}>
                                                <Typography>Type service:</Typography>
                                            </Grid>
                                            <Grid xs={7}>
                                                <Typography>{camServiceTypes[camera.ServiceType]?.label}</Typography>
                                            </Grid>
                                        </Grid>
                                        {camera.ANPRCameraId && (
                                            <Grid xs={12} container>
                                                <Grid xs={5}>
                                                    <Typography>ANPR ID:</Typography>
                                                </Grid>
                                                <Grid xs={7}>
                                                    <Typography>{camera.ANPRCameraId}</Typography>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </Stack>
                                }
                            />
                        )}
                    </Grid>

                    {/* Recent exemption periods */}
                    <BaseComponentDetails
                        title="Recent exemption periods"
                        isHideAction={isHideAction}
                        style={{
                            mt: 2,
                        }}
                        iconAdd={<MdAdd style={{ width: '16px', height: '16px' }} />}
                        popupAdd={popUpAddExemption}
                        titleTooltipIconAdd="Add exemption"
                        iconHistory={<TbBook2 style={{ width: '16px', height: '16px' }} />}
                        titleTooltipIconHistory="History exemption"
                        popupHistory={popupHistoryExemption}
                        setMode={setMode}
                        content={
                            <>
                                {!!camera.ExemptionPeriods?.length && (
                                    <Stack mt={2} spacing={2} width={'100%'} paddingX={2}>
                                        <>
                                            <Grid xs={12} container borderBottom={`1px solid ${color.grey200}`} pb={1}>
                                                <Grid xs={7.5}>
                                                    <Typography fontWeight={500}>Effective</Typography>
                                                </Grid>
                                                <Grid xs={3.5}>
                                                    <Typography fontWeight={500}>Description</Typography>
                                                </Grid>
                                            </Grid>
                                            {camera.ExemptionPeriods?.map((item) => {
                                                const isPast = moment(item.EffectiveFrom).isBefore(
                                                    moment().startOf('date')
                                                );
                                                return (
                                                    <Grid
                                                        xs={12}
                                                        container
                                                        borderBottom={`1px solid ${color.grey200}`}
                                                        pb={1}
                                                    >
                                                        <Grid xs={7.5}>
                                                            <Stack>
                                                                <Typography>
                                                                    {moment(item.EffectiveFrom).format(
                                                                        'DD/MM/YYYY HH:mm'
                                                                    )}{' '}
                                                                    -{' '}
                                                                    {moment(item.EffectiveTo).format(
                                                                        'DD/MM/YYYY HH:mm'
                                                                    )}
                                                                </Typography>
                                                                <Typography
                                                                    fontSize={12}
                                                                    sx={{ color: (theme) => theme.palette.grey[700] }}
                                                                >
                                                                    By {item.CreatedBy}
                                                                </Typography>
                                                            </Stack>
                                                        </Grid>
                                                        <Grid xs={4.5}>
                                                            <Stack
                                                                direction="row"
                                                                justifyContent="space-between"
                                                                alignItems={!!errors.name ? 'flex-start' : 'center'}
                                                            >
                                                                <Typography>{item.Notes}</Typography>
                                                                {isPast ? (
                                                                    <></>
                                                                ) : (
                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="flex-end"
                                                                        spacing={0.7}
                                                                    >
                                                                        <Box
                                                                            sx={{
                                                                                color: '#85858A',
                                                                                transition: '.2s ease-in-out',
                                                                                cursor: 'pointer',
                                                                                '&:hover': {
                                                                                    color: (theme) =>
                                                                                        theme.palette.secondary.main,
                                                                                },
                                                                            }}
                                                                            onClick={() => {
                                                                                setMode(Mode.edit);
                                                                                setSelectedExemption(item);
                                                                                popUpAddExemption.setTrue();
                                                                            }}
                                                                        >
                                                                            <IcEdit />
                                                                        </Box>
                                                                        <Box
                                                                            sx={{
                                                                                cursor: 'pointer',
                                                                            }}
                                                                            onClick={() => {
                                                                                setSelectedExemption(item);
                                                                                popUpConfirmDelete.setTrue();
                                                                            }}
                                                                        >
                                                                            <ICDelete fontSize={18} />
                                                                        </Box>
                                                                    </Stack>
                                                                )}
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                );
                                            })}
                                        </>
                                    </Stack>
                                )}
                                {!camera.ExemptionPeriods?.length && (
                                    <NoDataCameras
                                        sx={{ borderRadius: '0px 0px 4px 4px', minHeight: 150, border: 'none' }}
                                    />
                                )}
                            </>
                        }
                    />

                    {/* Note */}
                    <BaseComponentDetails
                        title="Note"
                        isHideAction={isHideAction}
                        iconAdd={<MdAdd style={{ width: '16px', height: '16px' }} />}
                        popupAdd={popupNoteHistory}
                        titleTooltipIconAdd="Add note"
                        setMode={setMode}
                        style={{
                            mt: 2,
                        }}
                        content={
                            <Stack mt={2} spacing={2} width={'100%'}>
                                <Stack mt={2}>
                                    <FilterRoot
                                        handleResetFilter={() => {
                                            setFilter({
                                                page: 1,
                                                from: filter.from,
                                                to: filter.to,
                                                sortedOrder: cameraSortByStatus[1],
                                                sort: cameraNoteSortByType[0],
                                            });
                                            setSearchValue('');
                                        }}
                                        rightTitleContent={
                                            <Grid container xs={9} spacing={1} justifyContent={'flex-end'}>
                                                <Grid xs={5}>
                                                    <SearchTypeSelect
                                                        startAdornment={
                                                            <Stack direction={'row'}>
                                                                <SearchTypeSelect
                                                                    listData={cameraSortByStatus}
                                                                    value={filter.sortedOrder!}
                                                                    keyEqual="Id"
                                                                    keyLabel="Name"
                                                                    handleChange={(e) => {
                                                                        setFilter((prev) => ({
                                                                            ...prev,
                                                                            sortedOrder: e,
                                                                            page: 1,
                                                                        }));
                                                                    }}
                                                                    label="Sort by"
                                                                    minWidth={60}
                                                                />
                                                                <Divider
                                                                    orientation="vertical"
                                                                    flexItem
                                                                    sx={{
                                                                        mx: '5px',
                                                                        borderRightWidth: 1,
                                                                        borderColor: color.grey600,
                                                                    }}
                                                                    variant="middle"
                                                                />
                                                            </Stack>
                                                        }
                                                        listData={cameraNoteSortByType}
                                                        value={filter.sort!}
                                                        keyEqual="Id"
                                                        keyLabel="Name"
                                                        handleChange={(e) => {
                                                            setFilter((prev) => ({ ...prev, sort: e, page: 1 }));
                                                        }}
                                                        label="Sort by"
                                                        minWidth={60}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                        filterChild={
                                            <Grid container xs={12} gap={1} justifyContent={'center'}>
                                                <Grid xs={5.9}>
                                                    <BaseTextField
                                                        placeholder={'Search by email'}
                                                        value={searchValue}
                                                        onChange={(e) => {
                                                            setFilter({
                                                                page: 1,
                                                                from: filter.from,
                                                                to: filter.to,
                                                                sortedOrder: filter.sortedOrder,
                                                                sort: filter.sort,
                                                            });
                                                            setSearchValue(e.target.value);
                                                        }}
                                                        maxLength={50}
                                                        InputProps={{
                                                            endAdornment: (
                                                                <FiSearch
                                                                    color="disabled"
                                                                    size={16}
                                                                    style={{
                                                                        color: 'rgba(0, 0, 0, 0.38)',
                                                                        cursor: 'pointer',
                                                                    }}
                                                                />
                                                            ),
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid xs={5.9}>
                                                    <StyledDateRangePicker
                                                        value={{
                                                            startDate: filter?.from || undefined,
                                                            endDate: filter?.to || undefined,
                                                        }}
                                                        onChange={(value) => {
                                                            setFilter((prev) => ({
                                                                ...prev,
                                                                page: 1,
                                                                from: value.startDate,
                                                                to: value.endDate,
                                                            }));
                                                        }}
                                                        dateReset={{ startDate: undefined, endDate: undefined }}
                                                        label={'Select date'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        }
                                    />
                                </Stack>

                                <BaseListNote
                                    paging={notes ?? ({} as Paging<CameraNotes>)}
                                    isLoading={loading}
                                    tableHeaders={[...tableHeaders, '']}
                                    styleContainer={{ mt: 2, mb: 5 }}
                                    setPage={(page) => {
                                        setFilter((prev) => ({ ...prev, page }));
                                    }}
                                    renderedItem={(item) => {
                                        return (
                                            <>
                                                <CellOfRow value={formatDateTime(item.Created!)} align="left" />
                                                <CellOfRow value={item.CreatedBy ?? ''} align="left" />
                                                <CellOfRow value={formatDateTime(item.Updated!)} align="left" />
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        wordWrap: 'break-word',
                                                        whiteSpace: 'pre-wrap',
                                                        wordBreak: 'break-word',
                                                    }}
                                                >
                                                    <Typography>{item.Notes.trim() ?? ''}</Typography>
                                                </TableCell>
                                                <CellOfRow
                                                    value={
                                                        <>
                                                            <Stack
                                                                direction={'row'}
                                                                spacing={0.7}
                                                                alignItems={'center'}
                                                            >
                                                                <Tooltip placement="top" title="Edit">
                                                                    <Box
                                                                        sx={{
                                                                            color: '#85858A',
                                                                            transition: '.2s ease-in-out',
                                                                            cursor: 'pointer',
                                                                            '&:hover': {
                                                                                color: (theme) =>
                                                                                    theme.palette.secondary.main,
                                                                            },
                                                                        }}
                                                                        onClick={() => {
                                                                            setMode(Mode.edit);
                                                                            setIdNote(item.Id ?? 0);
                                                                            popupNoteHistory.setTrue();
                                                                        }}
                                                                    >
                                                                        <IcEdit />
                                                                    </Box>
                                                                </Tooltip>
                                                                <DangerTooltip placement="top" title="Delete">
                                                                    <Box
                                                                        sx={{
                                                                            cursor: 'pointer',
                                                                        }}
                                                                        onClick={() => {
                                                                            setIdNote(item.Id ?? 0);
                                                                            popUpConfirmDeleteNoteHistory.setTrue();
                                                                        }}
                                                                    >
                                                                        <ICDelete fontSize={18} />
                                                                    </Box>
                                                                </DangerTooltip>
                                                            </Stack>
                                                        </>
                                                    }
                                                    align="left"
                                                />
                                            </>
                                        );
                                    }}
                                />
                            </Stack>
                        }
                    />

                    {/* Photo of camera as installed */}
                    <Grid
                        item
                        container
                        xs={12}
                        height={'100%'}
                        bgcolor={color.white}
                        padding={2}
                        mt={2}
                        borderRadius={'16px 16px 0px 0px'}
                    >
                        <Stack
                            borderBottom={'1px solid #E4E7EC'}
                            width={'100%'}
                            paddingRight={2}
                            direction={'row'}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                        >
                            <Typography color={color.darkPrimary} variant="h5" width={'100%'} mb={1}>
                                Photo of camera as installed
                            </Typography>
                            {!isHideAction && (
                                <Tooltip title="Edit Photo of camera as installed" placement="top" arrow>
                                    <IconButton
                                        sx={{
                                            borderRadius: '3px',
                                            width: '24px',
                                            height: '24px',
                                            color: 'white',
                                            backgroundColor: '#2E7D32',
                                            ':hover': {
                                                backgroundColor: '#256428',
                                            },
                                            padding: '4px',
                                            '&.MuiIconButton-root.Mui-disabled': {
                                                backgroundColor: '#eee',
                                            },
                                        }}
                                        onClick={() => {
                                            popUpEditCameraPhotoInstalled.setTrue();
                                        }}
                                    >
                                        <IcEdit style={{ width: '14px', height: '14px' }} />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Stack>
                    </Grid>
                </Grid>
                <Box px={camera?.CameraInstalledPhotos?.length! > 0 ? 0 : 2} bgcolor={color.grey100}>
                    {camera?.CameraInstalledPhotos?.length! > 0 ? (
                        <ImageCarousel camera={camera} />
                    ) : (
                        <NoDataCameras
                            sx={{
                                borderRadius: '0px 0px 4px 4px',
                                height: '250px',
                                mb: 2,
                                bgcolor: color.white,
                                width: '100%',
                                border: 'none',
                            }}
                        />
                    )}
                </Box>
            </Stack>

            <PopUpEditService {...popUpEditService} camera={camera} onConfirm={props.onUpdateService} />

            <PopUpEditMilesightAccount
                {...popUpEditMilesightAccount}
                camera={camera}
                onConfirm={(camera) => {
                    props.onUpdateCamera?.(camera);
                    popUpEditMilesightAccount.onClose();
                }}
            />

            <PopUpWarning
                {...popUpWarning}
                title="Confirm"
                message="Are you sure you want to change the status of this camera?"
                onConfirm={handleOnConfirm}
            />
            <PopUpWarning
                {...popUpConfirmDelete}
                title="Confirm"
                message="Are you sure you want to delete this exemption?"
                onConfirm={() => {
                    props.onDeleteExemption?.(selectedExemption.Id!);
                    popUpConfirmDelete.onClose();
                }}
            />
            <PopUpWarning
                {...popUpWarningVerification}
                title="Confirm"
                message="Are you sure you want to change the status of create verifications?"
                onConfirm={() => {
                    handleConfirmVerification(camera.Id!, isEnabled);
                    popUpWarningVerification.onClose();
                }}
            />

            <PopUpWarning
                {...popUpWarningEditMac}
                title="Confirm"
                message="This action will switch the camera to the 'Not available' state until the system retrieves the latest information from this MAC. Are you sure you want to edit this MAC?"
                onConfirm={() => {
                    if (dirtyFields.mac && !isSubmitting) {
                        const _camera: CameraWithInfo = {
                            ...camera,
                            Mac: watch().mac.trim(),
                        };
                        props.onUpdateCamera?.(_camera);
                        popUpWarningEditMac.onClose();
                    }
                }}
            />

            <PopUpAddExemption
                {...popUpAddExemption}
                mode={mode}
                selectedExemption={selectedExemption}
                onClose={() => {
                    popUpAddExemption.onClose();
                }}
                onConfirm={(data) => {
                    const exemption: ExemptionPeriods = {
                        CameraId: camera.Id!,
                        Id: data.id,
                        EffectiveFrom: moment(data.from).toDate(),
                        EffectiveTo: moment(data.to).toDate(),
                        Notes: data.desc,
                    };

                    props.onAddExemption?.(exemption);
                    popUpAddExemption.onClose();
                }}
            />
            {/* <PopUpEditAllocatedZone
                zones={camera.Zone!}
                loading={props.loading}
                {...popUpEditAllocatedZone}
                camera={camera}
                onUpdateCamera={props.onUpdateCamera}
                setLoading={props.setLoading}
                onConfirm={async (camera) => {
                    await props.onUpdateCamera?.(camera).then(() => {
                        popUpEditAllocatedZone.onClose();
                    });
                }}
            /> */}

            <PopUpHistoryExemption
                {...popupHistoryExemption}
                onClose={() => {
                    popupHistoryExemption.onClose();
                }}
            />

            <PopUpNoteHistory
                {...popupNoteHistory}
                mode={mode}
                camera={camera}
                listNotes={notes ?? ({} as Paging<CameraNotes>)}
                idNote={idNote}
                handleAddOrUpdateNote={handleAddOrUpdateNote}
            />

            <PopUpWarning
                {...popUpConfirmDeleteNoteHistory}
                title="Confirm"
                message="Are you sure you want to delete this note?"
                onClose={() => {
                    setIdNote(0);
                    popUpConfirmDeleteNoteHistory.onClose();
                }}
                onConfirm={() => {
                    if (idNote) {
                        handleDeleteNote(idNote);
                        popUpConfirmDeleteNoteHistory.onClose();
                    } else {
                        pushError('Id note does not exist');
                    }
                }}
            />
            <PopUpEditPurposes {...popUpEditPurposes} camera={camera} onConfirm={props.onUpdateService} />
            <PopUpEditCameraPhotoInstalled
                {...popUpEditCameraPhotoInstalled}
                camera={camera}
                onConfirm={async () => {
                    await cameraController.get(String(camera.Id!)).then((res) => {
                        setCamera(res);
                    });
                }}
            />
            <PopUpEditDeployDate {...popUpEditProductDate} camera={camera} onConfirm={props.onUpdateService} />
        </>
    );
}

type BaseComponentDetailsProps = {
    title: string;
    content: ReactNode;
    style?: SxProps;
    styleTitle?: SxProps;
    isHideAction?: boolean;
    iconAdd?: ReactNode;
    iconHistory?: ReactNode;
    popupAdd?: UsePopUpReturnType;
    popupHistory?: UsePopUpReturnType;
    titleTooltipIconAdd?: string;
    titleTooltipIconHistory?: string;
    setMode?: Dispatch<SetStateAction<Mode | null>>;
};

export const BaseComponentDetails: React.FC<BaseComponentDetailsProps> = (props) => {
    const {
        title,
        content,
        style,
        styleTitle,
        isHideAction,
        iconAdd,
        iconHistory,
        setMode,
        popupAdd,
        popupHistory,
        titleTooltipIconAdd,
        titleTooltipIconHistory,
    } = props;
    return (
        <Grid
            item
            container
            xs={12}
            height={'100%'}
            bgcolor={color.white}
            padding={2}
            borderRadius={2}
            sx={{
                ...style,
            }}
        >
            <Stack
                borderBottom={'1px solid #E4E7EC'}
                width={'100%'}
                paddingRight={2}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                sx={{
                    ...styleTitle,
                }}
            >
                <Typography color={color.darkPrimary} variant="h5" width={'100%'} mb={1}>
                    {title}
                </Typography>
                {!isHideAction && (
                    <Stack direction="row" justifyContent="flex-end" alignItems="center" spacing={1} mb={1}>
                        {iconAdd && (
                            <Tooltip title={titleTooltipIconAdd} placement="top" arrow>
                                <IconButton
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        padding: '4px',
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                    }}
                                    onClick={() => {
                                        setMode?.(Mode.create);
                                        popupAdd?.setTrue();
                                    }}
                                >
                                    {iconAdd}
                                </IconButton>
                            </Tooltip>
                        )}
                        {iconHistory && (
                            <Tooltip title={titleTooltipIconHistory} placement="top" arrow>
                                <IconButton
                                    sx={{
                                        borderRadius: '3px',
                                        width: '24px',
                                        height: '24px',
                                        color: 'white',
                                        backgroundColor: '#2E7D32',
                                        ':hover': {
                                            backgroundColor: '#256428',
                                        },
                                        padding: '4px',
                                        '&.MuiIconButton-root.Mui-disabled': {
                                            backgroundColor: '#eee',
                                        },
                                    }}
                                    onClick={() => {
                                        popupHistory?.setTrue();
                                    }}
                                >
                                    {iconHistory}
                                </IconButton>
                            </Tooltip>
                        )}
                    </Stack>
                )}
            </Stack>

            {content}
        </Grid>
    );
};
