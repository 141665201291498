import IcMenu from '@assets/details-camera-icons/IcMenu';
import { GeoZoneCoordinates, GeoZones } from '@EcamModel/model';
import { Box, Fade } from '@mui/material';
import { FormValues, HeadingSection } from '@pages/cameras/details/components/AddGeoZones';
import { Mode } from '@pages/cameras/details/components/CameraInformation';
import color from '@theme/Colors';
import { FC } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form';
import FramePolygonGeoOverview from '../../../components/FramePolygonGeoOverview';
import useAddGeoZones from '../hook/useAddGeoZones';

interface Props {
    methods: UseFormReturn<FormValues, any, undefined>;
}

const FormAddGeoZonesPtzOverviewPage: FC<Props> = (props) => {
    const { methods } = props;
    const { imageElement, prevGeoZonePointsOverviewPage, selectedCameraType } = useAddGeoZones();
    const isTypeOverview = selectedCameraType === 0;

    const handleDeleteGeoZonePoints = () => {
        methods.setValue('geoZonePoints', [] as GeoZoneCoordinates[], {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true,
        });
    };

    return (
        <Fade in={isTypeOverview} timeout={800}>
            <Box>
                {/* Draw angle box */}
                <HeadingSection icon={<IcMenu />} heading="Zone marking" />
                <Controller
                    name="geoZonePoints"
                    control={methods.control}
                    render={({ field, fieldState: { error } }) => (
                        <FramePolygonGeoOverview
                            {...field}
                            imageElement={imageElement!}
                            mode={Mode.create}
                            prevGeoZone={prevGeoZonePointsOverviewPage as GeoZoneCoordinates[][]}
                            selectedGeoZone={{} as GeoZones}
                            onChange={(points) => {
                                methods.setValue('geoZonePoints', points, {
                                    shouldValidate: true,
                                    shouldTouch: true,
                                    shouldDirty: true,
                                });
                            }}
                            onDelete={handleDeleteGeoZonePoints}
                        />
                    )}
                />
            </Box>
        </Fade>
    );
};

export default FormAddGeoZonesPtzOverviewPage;
