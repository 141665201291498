import { Autocomplete, SxProps, TextField, Theme } from '@mui/material';

interface BaseAutocompleteProps<T> {
    label: string;
    placeholder?: string;
    options: T[];
    getOptionLabel?: (option: T) => string;
    value: T | null;
    onChange: (event: React.SyntheticEvent, newValue: T | null) => void;
    isDisabled?: boolean;
    clearIcon?: boolean;
}

const BaseAutoCompleteForTypeMapping = <T,>({
    label,
    placeholder,
    options,
    getOptionLabel,
    value,
    onChange,
    isDisabled = false,
    clearIcon = true,
}: BaseAutocompleteProps<T>) => {
    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel || ((option) => option as unknown as string)}
            value={value}
            clearIcon={clearIcon}
            disabled={isDisabled}
            renderInput={(params) => (
                <TextField
                    {...params}
                    InputLabelProps={{ shrink: true }}
                    label={
                        <span>
                            {label}
                            <span style={{ color: 'red' }}> *</span>
                        </span>
                    }
                    placeholder={placeholder || 'Enter value'}
                />
            )}
            sx={{ ...autocompleteStyles }}
            onChange={onChange}
        />
    );
};

export const autocompleteStyles: SxProps<Theme> = {
    '& .MuiOutlinedInput-root': {
        padding: '9.95px 14px !important',
    },
    '& .MuiButtonBase-root': {
        marginRight: '5px !important',
    },
    '& .MuiAutocomplete-inputRoot': {
        '& .MuiAutocomplete-input': {
            padding: '0px 0px',
        },
    },
};

export default BaseAutoCompleteForTypeMapping;
