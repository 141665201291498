import React, { SVGProps } from 'react';
type Props = {} & SVGProps<SVGSVGElement>;

export default function ArrowDownButtonEndIcon(props: Props) {
    return (
        <svg
            width={props.width ?? '9px'}
            height={props.height ?? '9px'}
            viewBox="0 0 9 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M6.76535 3.48438L4.59202 5.65771C4.33535 5.91438 3.91535 5.91438 3.65868 5.65771L1.48535 3.48438"
                stroke={props.stroke ?? 'currentColor'}
                stroke-width="1.5"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
