import { GeoZoneCoordinates } from '@EcamModel/model';
import Konva from 'konva';
import { useContext, useEffect, useRef, useState } from 'react';
import { Mode } from '../details/components/CameraInformation';
import { FramePolygonProps } from '../details/components/tabsGeozonesDetail/components/FramePolygonGeoOverview';
import { PREVIEW_RATIO } from './CameraDetailsProvider';
import { DrawerContext } from '@components/drawer/useDrawer';

export default function useFramePolygonGeoZones(props: FramePolygonProps) {
    const { mode, imageElement, prevGeoZone, selectedGeoZone, onChange, onDelete } = props;
    const { open } = useContext(DrawerContext);

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [containerSize, setContainerSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth * PREVIEW_RATIO,
    });

    const stageRef = useRef<Konva.Stage>(null);
    const shapeRef = useRef<Konva.Shape>(null);

    const scaleSize = containerSize.width / (imageElement?.width ?? 1920);

    const scaledPrevGeoZone = prevGeoZone?.map((frame) =>
        frame?.map((point) => ({
            x: point?.XPoint * scaleSize,
            y: point?.YPoint * scaleSize,
            geoZoneId: point.GeoZoneId,
        }))
    );

    const isPointInsideFrame = (point: Partial<GeoZoneCoordinates>, frame: GeoZoneCoordinates[]) => {
        var x = point.XPoint,
            y = point.YPoint;
        var inside = false;
        for (var i = 0, j = frame.length - 1; i < frame.length; j = i++) {
            var xi = frame[i]?.XPoint,
                yi = frame[i]?.YPoint;
            var xj = frame[j]?.XPoint,
                yj = frame[j]?.YPoint;
            var intersect = yi > y! !== yj > y! && x! < ((xj - xi) * (y! - yi)) / (yj - yi) + xi;
            if (intersect) inside = !inside;
        }
        return inside;
    };

    const [points, setPoints] = useState<GeoZoneCoordinates[]>([]);
    const [isDrag, setIsDrag] = useState<boolean>(false);

    const handleDrawFrame = (e: any) => {
        if (points.length === 5) return;
        const stage = stageRef.current;
        if (!stage) return;

        const pointer = stage.getPointerPosition();
        if (!pointer) return;

        const currentScale = stage.scaleX(); // Lấy tỷ lệ zoom hiện tại
        const point: GeoZoneCoordinates = {
            XPoint: pointer.x / currentScale / scaleSize, // Tính toán tọa độ x theo tỷ lệ zoom
            YPoint: pointer.y / currentScale / scaleSize, // Tính toán tọa độ y theo tỷ lệ zoom
            Index: points.length + 1,
            GeoZoneId: selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId,
        };

        if (prevGeoZone?.some((frame) => isPointInsideFrame(point, frame))) {
            return;
        }
        setPoints((prevPoints) => {
            const newPoints = [...prevPoints, point];
            if (newPoints.length === 4) {
                newPoints.push(prevPoints[0]);
            }

            return newPoints;
        });
    };

    const handleDragAnchors = (index: number, e: any) => {
        const newPoints: any[] = [...points];

        const newPoint: GeoZoneCoordinates = {
            XPoint: e.target.x() / scaleSize,
            YPoint: e.target.y() / scaleSize,
            Index: points[index].Index,
            GeoZoneId: selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId,
        };

        if (prevGeoZone?.some((frame) => isPointInsideFrame(newPoint, frame))) {
            e.target.to({
                x: points[index].XPoint * scaleSize,
                y: points[index].YPoint * scaleSize,
            });
            return;
        }
        newPoints[index] = newPoint;
        if (index === 4) {
            newPoints[0] = newPoints[index];
        }
        setPoints(newPoints);
    };

    const handleDragShape = (e: any) => {
        const shapeNode = shapeRef.current;

        if (shapeNode) {
            const newX = e.target.x() / scaleSize;
            const newY = e.target.y() / scaleSize;

            const updatedPoints = points.map((point, index) => ({
                XPoint: point.XPoint + newX,
                YPoint: point.YPoint + newY,
                Index: index + 1,
                GeoZoneId: selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId,
            }));
            const shapeInsideFrame = updatedPoints.some((point) => {
                return prevGeoZone?.some((frame) => {
                    return isPointInsideFrame(point, frame);
                });
            });
            if (shapeInsideFrame) {
                shapeNode.position({ x: 0, y: 0 });
                setIsDrag(false);
                return;
            }
            setPoints(updatedPoints);
            shapeNode.position({ x: 0, y: 0 });
            setIsDrag(false);
        }
    };

    useEffect(() => {
        const isEdit = mode === Mode.edit;
        if (isEdit && points.length === 5) {
            onChange?.(points);
        } else if (!isEdit) {
            onChange?.(points);
        }
    }, [mode, points]);

    useEffect(() => {
        if (!selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId) {
            let initialPolygon: GeoZoneCoordinates[] = [];
            setPoints(initialPolygon);
        } else {
            setPoints(selectedGeoZone?.GeoZoneCoordinates?.concat(selectedGeoZone?.GeoZoneCoordinates?.[0]) || []);
        }
    }, [selectedGeoZone?.GeoZoneCoordinates?.[0].GeoZoneId]);

    useEffect(() => {
        const updateStageSize = () => {
            if (containerRef.current) {
                const width = containerRef.current.offsetWidth;
                const height = width * PREVIEW_RATIO;
                setContainerSize({ width, height });
            } else {
                setTimeout(updateStageSize, 300);
            }
        };

        if (open) {
            setTimeout(updateStageSize, 300);
        } else {
            updateStageSize();
        }
        window.addEventListener('resize', updateStageSize);

        return () => {
            window.removeEventListener('resize', updateStageSize);
        };
    }, [open]);

    return {
        stageRef,
        shapeRef,
        points,
        isDrag,
        scaledPrevGeoZone,
        scaleSize,
        prevGeoZone,
        selectedGeoZone,
        imageElement,
        containerRef,
        containerSize,
        setPoints,
        handleDrawFrame,
        handleDragAnchors,
        handleDragShape,
        setIsDrag,
    };
}
