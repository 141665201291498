import { Grid, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { useCameraOverview } from '@pages/camerasOverview/hook/CameraOverviewProvider';
import { GridBorderRadius, GridWithGray100 } from '@pages/dashboard/styled';
import color from '@theme/Colors';
import { ReactNode, SyntheticEvent, useState } from 'react';
import BarStackedChart from '../charts/BarStackedChart';
import LineChartCameraStatus from '../charts/LineChartCameraStatus';
import { ChartItem } from '../components/PopUpAvgWorkedHours';
import { ICameraDailyWorkedHour, IGetCameraPingHour } from '@EcamModel/controllers/ICameraOverviewHttpController';
import { endOfYesterday, formatUKTimeDate, startOfYesterday } from 'src/helpers';
import { cameraOverviewHttpController } from '@controllers/index';
import { CameraBatteryDetail, CameraDistributeWorkingHour, CameraPingDetail } from '@EcamModel/model/CameraOverview';
import { BarChart } from '../charts/BarChartCameraStatus';

interface TabPanelProps {
    children?: ReactNode;
    index: number;
    value: number;
}

export function CustomTabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

export function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function TabsData() {
    const [value, setValue] = useState(0);

    const { filter, cameraDailyWorkedHourPopup, cameraPhotoTakenPopup, cameraOverview, setLoadingDailyWorkedHours } =
        useCameraOverview();

    const [cameraDailyBatteryDetail, setCameraDailyBatteryDetail] = useState<CameraBatteryDetail[]>([]);
    const [cameraPingStatus, setCameraPingStatus] = useState<CameraPingDetail[]>([]);
    const [capturedPhotos, setCapturedPhotos] = useState<CameraDistributeWorkingHour[]>([]);

    const cameraBatteryDetailPopup = cameraDailyBatteryDetail.map((i) => {
        let chart: BarChart = { label: formatUKTimeDate(i.Created), value: i.Battery };
        return chart;
    });

    const getBatteryDetailByHourly = async (cameraId: number) => {
        let req: ICameraDailyWorkedHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraBatteryDetail(req)
            .then((res) => {
                setCameraDailyBatteryDetail(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const getPingStatus = async (cameraId: number) => {
        let req: IGetCameraPingHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraPingDetail(req)
            .then((res) => {
                setCameraPingStatus(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const getCapturedPhotosAvg = async (cameraId: number) => {
        let req: ICameraDailyWorkedHour = {
            CameraId: cameraId,
            From: filter.from ?? startOfYesterday,
            To: filter.to ?? endOfYesterday,
        };
        setLoadingDailyWorkedHours(true);
        cameraOverviewHttpController
            .cameraDistributeWorkingHours(req)
            .then((res) => {
                setCapturedPhotos(res);
            })
            .finally(() => {
                setLoadingDailyWorkedHours(false);
            });
    };

    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab label="Daily worked hours" {...a11yProps(0)} />
                    <Tab
                        label="Internal battery detail"
                        onClick={() => {
                            if (value != 1) {
                                getBatteryDetailByHourly(cameraOverview.cameraId);
                                getPingStatus(cameraOverview.cameraId);
                            }
                        }}
                        {...a11yProps(1)}
                    />
                    <Tab
                        onClick={() => {
                            if (value != 2) {
                                getCapturedPhotosAvg(cameraOverview.cameraId);
                            }
                        }}
                        label="Distibute working hours"
                        {...a11yProps(2)}
                    />
                </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
                <Stack gap={2}>
                    <ChartItem
                        dataAxisY={true}
                        title={<Typography variant="h5">Daily worked hours</Typography>}
                        chartColor={color.secondary}
                        data={cameraDailyWorkedHourPopup}
                        label="Number of daily working hours"
                    />
                    <ChartItem
                        dataAxisY={false}
                        title={<Typography variant="h5">Daily photo taken</Typography>}
                        chartColor={color.primary}
                        data={cameraPhotoTakenPopup}
                        label="Photos taken daily"
                    />
                </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <Stack gap={2}>
                    <ChartItem
                        dataBattery={true}
                        dataAxisY={false}
                        title={<Typography variant="h5">{`%Battery`}</Typography>}
                        chartColor={color.secondary}
                        data={cameraBatteryDetailPopup}
                        label="Number of internal battery detail"
                    />

                    <GridWithGray100 container item md={12} sm={12} xs={12} borderRadius={'10px'}>
                        <GridBorderRadius container item border={`1px solid ${color.grey200} !important`}>
                            <Typography variant="h5">Ping status</Typography>
                            <Grid item md={12} sm={12} xs={12}>
                                <BarStackedChart chart={cameraPingStatus} />
                            </Grid>
                        </GridBorderRadius>
                    </GridWithGray100>
                </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
                <GridWithGray100 container item md={12} sm={12} xs={12} borderRadius={'10px'}>
                    <GridBorderRadius container item border={`1px solid ${color.grey200} !important`}>
                        <Typography variant="h5">Captured photos average</Typography>
                        <Grid item md={12} sm={12} xs={12}>
                            <LineChartCameraStatus capturedPhotos={capturedPhotos} />
                        </Grid>
                    </GridBorderRadius>
                </GridWithGray100>
            </CustomTabPanel>
        </Box>
    );
}
