import ReportBI from '@pages/report';
import { lazy } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import './App.css';
import AuthLayout from './layout/AuthLayout';
import InsightHeatMap from '@pages/report/InsightHeatMap';
import AddGeoZonesCameraPTZ from '@pages/cameras/details/components/tabsGeozonesDetail/ptz/upsertGeoZones/components/AddGeoZonesPTZ';

const HomeScreen = lazy(() => import('@pages/HomeScreen'));
const CameraUpsert = lazy(() => import('@pages/cameras/add-new'));
const DetailsCamera = lazy(() => import('@pages/cameras/details'));
const Cameras = lazy(() => import('@pages/cameras/list'));
const Locations = lazy(() => import('@pages/locations'));
const MapOverview = lazy(() => import('@pages/map-overview/index'));
const LivePhotosPage = lazy(() => import('@pages/cameras/live-photos'));
const Logout = lazy(() => import('@pages/logout'));
const VerificationsOverviewScreen = lazy(() => import('@pages/overview/VerificationsOverviewScreen'));
const ListPCByZone = lazy(() => import('@pages/verification/list-pc-by-zone'));
const WrapPCDetail = lazy(() => import('@pages/verification/pc-details'));
const VerificationsByZone = lazy(() => import('@pages/verification/zone-detail'));
const AddGeoZones = lazy(() => import('@pages/cameras/details/components/AddGeoZones'));
const EditGeoZones = lazy(() => import('@pages/cameras/details/components/EditGeoZones'));
const ReportsAnalyzing = lazy(() => import('@pages/overview'));
const CameraOverViewPage = lazy(() => import('@pages/camerasOverview/pages'));
const CameraOverviewPageContext = lazy(() => import('@pages/camerasOverview'));
const PageNotFound = lazy(() => import('@components/page-not-found/index'));
const TaskManagementScreen = lazy(() => import('@pages/task-management'));
const UpsertGeoZonesHandler = lazy(() => import('@pages/cameras/hook/GeoZonesNavigateHandler'));
const GeoZonesDetailCameraPTZ = lazy(
    () => import('@pages/cameras/details/components/tabsGeozonesDetail/ptz/detailGeoZones')
);

const router = createBrowserRouter([
    {
        path: '/',
        element: <AuthLayout />,

        children: [
            {
                index: true,
                element: <Navigate to={'locations'} />,
            },
            {
                path: 'home',
                element: <HomeScreen />,
            },
            {
                path: 'locations/:locationId/cameras',
                element: <Cameras />,
            },
            {
                path: 'locations/cameras/add',
                element: <CameraUpsert />,
            },
            {
                path: 'locations/:locationId/cameras/add/:type',
                element: <CameraUpsert />,
            },
            {
                path: 'locations/:locationId/cameras/:id/edit',
                element: <CameraUpsert />,
            },
            {
                path: 'locations/:locationId/cameras/:id',
                element: <DetailsCamera />,
            },
            {
                path: 'locations/:locationId/cameras/:id/live-photos',
                element: <LivePhotosPage />,
            },
            {
                path: 'locations/:locationId/cameras/:id/add/:type',
                element: <UpsertGeoZonesHandler />,
            },
            {
                path: 'locations/:locationId/cameras/:id/edit/:geoZoneId/:type',
                element: <UpsertGeoZonesHandler />,
            },
            {
                path: 'locations/:locationId/cameras/:id/overview/:geoZoneId',
                element: <GeoZonesDetailCameraPTZ />,
            },
            {
                path: 'locations/:locationId/cameras/:id/overview/:geoZoneId/add',
                element: <AddGeoZonesCameraPTZ />,
            },
            {
                path: 'map-overview',
                element: <MapOverview />,
            },
            {
                path: 'verification',
                element: <ListPCByZone />,
            },
            {
                path: 'verification/:zoneId',
                element: <VerificationsByZone />,
            },
            {
                path: 'verification/:zoneId/:groupPcId',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'verification/contravention/:contraventionId',
                element: <WrapPCDetail />,
            },
            {
                path: 'verification/:zoneId/step/:step',
                element: <WrapPCDetail />,
            },
            {
                path: 'overview-verification',
                element: <VerificationsOverviewScreen />,
            },
            {
                path: 'camera-overview',
                element: <CameraOverviewPageContext />,
                children: [{ index: true, element: <CameraOverViewPage /> }],
            },
            {
                path: 'task-management',
                element: <TaskManagementScreen />,
            },
            // {
            //     path: 'cameraTime',
            //     element: <CameraTimeScreen />,
            // },
            {
                path: 'insights',
                element: <ReportBI />,
            },
            {
                path: 'bubble-heat-map',
                element: <InsightHeatMap />,
            },
            // {
            //     path: 'reports-analysing',
            //     element: <ReportsAnalyzing />,
            // },
            {
                path: 'overview-verification/:groupPcId',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'overview-verification/:groupPcId/audit',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'verification/:zoneId/:groupPcId/audit',
                element: <WrapPCDetail readonly />,
            },
            {
                path: 'locations',
                element: <Locations />,
            },

            // {
            //     path: 'ecam',
            //     element:
            // <FrameCrop /> ,
            // },
            {
                path: 'logout',
                element: <Logout />,
            },
            // {
            //     path: 'dashboard',
            //     element: <Dashboard /> ,
            // },

            // {
            //     path: 'crop',
            //     element: <CropIMG />,
            // },

            // {
            // 	path: "*",
            // 	action: () => redirect("/ecam"),
            // },

            {
                path: '/page-not-found',
                element: <PageNotFound />,
            },

            {
                path: '*',
                element: <Navigate to={'/page-not-found'} />,
            },
        ],
    },
]);

function App() {
    return <RouterProvider router={router} />;
}

export default App;
