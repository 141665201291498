import { IPublicClientApplication, InteractionStatus, SilentRequest } from '@azure/msal-browser';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';
import LoadingScreen from '@components/LoadingScreen';
import MultiProvider from '@components/MultiProvider';
import MyDrawer from '@components/drawer/MyDrawer';
import useDrawer, { DrawerContext } from '@components/drawer/useDrawer';
import MyAppBar from '@components/header/MyAppBar';
import { pushError } from '@components/toast';
import { axiosClient } from '@controllers/axiosClient';
import { Box, Container, Stack } from '@mui/material';
import Login from '@pages/login';
import jwtDecode from 'jwt-decode';
import { Suspense, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Outlet } from 'react-router-dom';

export enum AuthPermission {
    READ_ONLY = 1,
    EDITABLE = 2,
}

const ID_TOKEN_KEY = 'idToken';

const handle401Error = async (error, instance: IPublicClientApplication, accessTokenRequest: SilentRequest) => {
    const idToken = localStorage.getItem(ID_TOKEN_KEY);
    if (idToken) {
        const decoded: any = jwtDecode(idToken);
        const isValid = decoded.exp > Math.floor(Date.now() / 1000);
        if (!isValid) {
            pushError('Token has expired');
            await instance.acquireTokenRedirect(accessTokenRequest);
        } else {
            throw Error(error);
        }
    } else {
        await instance.acquireTokenRedirect(accessTokenRequest);
    }
};

const setupInterceptors = (instance: IPublicClientApplication, accessTokenRequest: SilentRequest, getToken) => {
    axiosClient.interceptors.request.use(
        async (config) => {
            const token = await getToken(instance, accessTokenRequest);
            localStorage.setItem(ID_TOKEN_KEY, token);
            config.headers.Authorization = `Bearer ${token}`;
            return config;
        },
        (error) => Promise.reject(error)
    );

    axiosClient.interceptors.response.use(
        (response) => response,
        async (error) => {
            console.log(`error:`, error);
            if (error?.response?.status === 401) {
                console.log(`error?.response?.status:`, error?.response?.status);
                await handle401Error(error, instance, accessTokenRequest);
            } else {
                console.error('_error', error.response);
                const errorMessage =
                    typeof error?.response?.data === 'string'
                        ? error?.response?.data
                        : error?.response?.data?.message || error.message || 'Server error!';
                pushError(errorMessage);
                return Promise.reject(error);
            }
        }
    );
};

function ProtectedChild() {
    const { instance, inProgress, accounts } = useMsal();
    const drawerContext = useDrawer();
    const [openDrawer, setOpenDrawer] = useState<boolean>(true);

    const getToken = async (instance: IPublicClientApplication, accessTokenRequest: SilentRequest) => {
        try {
            const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
            const _idToken = accessTokenResponse.idToken;
            return _idToken;
        } catch (error) {
            return Promise.reject(error);
        }
    };

    useLayoutEffect(() => {
        const accessTokenRequest = {
            scopes: ['openid'],
            account: accounts[0],
        };
        setupInterceptors(instance, accessTokenRequest, getToken);
    }, [instance, accounts]);

    if (inProgress !== InteractionStatus.None) {
        return <LoadingScreen isLoading={true} />;
    }

    return (
        <MultiProvider providers={[<DrawerContext.Provider value={drawerContext} />]}>
            <MyAppBar isShowLabelAccount={true} isShowLabelLanguage={true} />
            <Stack direction={'row'} minHeight={'calc(100vh - 63px)'} width={'calc(100vW - 8px)'} className="App">
                <MyDrawer
                    open={(e) => {
                        setOpenDrawer(e);
                    }}
                />
                <Container
                    sx={{
                        pt: 14,
                        pb: 6,
                        pl: {
                            xs: !openDrawer ? 10 : 5,
                        },
                        pr: {
                            xs: !openDrawer ? 28 : 12,
                        },
                        minWidth: '100%',
                    }}
                >
                    <Suspense>
                        <Outlet />
                    </Suspense>
                </Container>
                {/* <Typography>Menu</Typography>
			<Stack spacing={1} direction={"row"}>
				<Link to={"/home"}>Canvas</Link>
				<Link to={"/ecam"}>Konva</Link>
				<Link to={"/logout"}>Logout</Link>
			</Stack> */}
            </Stack>
        </MultiProvider>
    );
}

export function UnAuth() {
    return <Login />;
}

function AuthLayout() {
    return (
        <Box sx={{ overflowX: 'hidden', pt: 0 }}>
            <AuthenticatedTemplate>
                <ProtectedChild />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <UnAuth />
            </UnauthenticatedTemplate>
        </Box>
    );
}

export default AuthLayout;
