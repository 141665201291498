import {
    CameraNotification,
    CameraNotificationStatus,
    CameraNotificationType,
} from '@EcamModel/model/CameraNotification';
import { Box, Divider, Stack, Tooltip, Typography } from '@mui/material';
import color from '@theme/Colors';
import moment from 'moment';
import { ReactNode } from 'react';
import { useNotification } from '../hooks/NotificationProvider';

export type NotificationItemProps = {
    item: CameraNotification;
    index: number;
};

type Props = NotificationItemProps & {
    handleMarkRead: () => void;
    children: ReactNode;
};

const CameraNotificationTypeMapping = {
    [CameraNotificationType.CameraThreshold]: {
        background: color.gold100,
        color: color.gold600,
        name: 'Warning',
    },
    [CameraNotificationType.Task]: {
        background: color.lightPrimary,
        color: color.success,
        name: 'Task',
    },
};

export default function LayoutNotificationItem(props: Props) {
    const { markRead, listNotification } = useNotification();
    const { handleMarkRead, item, children, index } = props;

    const isUnread = item?.Status === CameraNotificationStatus.Unread;
    const notificationMapping = CameraNotificationTypeMapping[item?.Type ?? 0];

    return (
        <>
            <Stack
                p={'8px 16px'}
                borderRadius={1}
                sx={{
                    '&:hover': {
                        bgcolor: color.grey100,
                    },
                    cursor: 'pointer',
                }}
                onClick={handleMarkRead}
                gap={1}
            >
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Stack direction={'row'} gap={1}>
                        <Typography variant="h5" fontWeight={500}>
                            {item?.Camera?.Name}
                        </Typography>

                        <Box bgcolor={notificationMapping.background} p="2px 10px" borderRadius={2} maxHeight={'20px'}>
                            <Typography variant="body2" textTransform={'capitalize'} color={notificationMapping.color}>
                                {notificationMapping.name}
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack alignItems="center" justifyItems={'center'} minWidth={'75px'}>
                        <Stack direction={'row'} gap={1} alignItems="center" justifyItems={'center'}>
                            <Typography color={color.grey600}>{moment(item?.Created).fromNow()}</Typography>
                            {isUnread && (
                                <Tooltip title={'Mark as read'} placement="top">
                                    <Box
                                        sx={{
                                            width: 10,
                                            height: 10,
                                            borderRadius: '50%',
                                            backgroundColor: 'red',
                                        }}
                                        onClick={(event) => {
                                            event.stopPropagation();
                                            markRead([item?.Id!], true);
                                        }}
                                    />
                                </Tooltip>
                            )}
                        </Stack>
                    </Stack>
                </Stack>

                {children}
            </Stack>
            {index < listNotification.rows?.length - 1 && <Divider />}
        </>
    );
}
