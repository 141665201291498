import { BaseModel } from '@Core';
import { ConsolidateCameraMonitoring } from './CameraOverview';

export interface ThresholdSetting extends BaseModel {
    Thresholds: Threshold[];
    Status: ThresholdStatus;
}

export enum ThresholdStatus {
    Inactive,
    Active,
}

export type NumberCondition =
    | 'Greater Than'
    | 'Greater Than Or Equal'
    | 'Equal To'
    | 'Not Equal'
    | 'Less Than'
    | 'Less Than Or Equal'
    | 'Between';

export type StringCondition = 'Contains' | 'Does Not Contain' | 'Equals' | 'Not Equals';

export type DateCondition =
    | 'After'
    | 'Before'
    | 'Equal And After'
    | 'Equal And Before'
    | 'Between'
    | 'Equals'
    | 'Not Equals';

export type ListCondition = 'In' | 'Not In';

export interface BaseThresholdOption<C, V> {
    Field: (keyof ConsolidateCameraMonitoring)[] | keyof ConsolidateCameraMonitoring;
    Condition?: C;
    Value?: V;
    CurrentValue?: any;
}

export type ThresholdOption =
    | BaseThresholdOption<NumberCondition, number | number[]>
    | BaseThresholdOption<StringCondition, string>
    | BaseThresholdOption<DateCondition, Date | Date[]>
    | BaseThresholdOption<ListCondition, number[]>;

export type DataType = 'Number' | 'String' | 'List' | 'Date';

export interface Threshold {
    Type: DataType;
    Options: ThresholdOption[];
}
