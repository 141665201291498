import { BaseModel } from '@Core';
import { Camera } from './Camera';
import { Threshold } from './ThresholdSetting';

export interface CameraNotification extends BaseModel {
    Thresholds?: Threshold[];
    Notes?: string;
    CameraId: number;
    Camera?: Camera;
    Status?: CameraNotificationStatus;
    Type: CameraNotificationType;
}

export enum CameraNotificationType {
    CameraThreshold,
    Task,
}

export enum CameraNotificationStatus {
    Unread,
    Read,
}
