import { ExemptionPeriodController } from './../submodules/ecam-ops-base-model/eCam-model/controllers/http/ExemptionPeriodController';
import { appConfig } from '@configs/index';
import { CameraHttpController } from '@EcamModel/controllers/http/CameraHttpController';
import { axiosClient } from './axiosClient';
import { GeoZoneHttpController } from '@EcamModel/controllers/http/GeoZoneHttpController';
import { ZoneHttpController } from '@EcamModel/controllers/http/ZoneHttpController';
import { LocationHttpController } from '@EcamModel/controllers/http/LocationHttpController';
import { PotentialContraventionHttpController } from '@EcamModel/controllers/http/PotentialContraventionController';
import { CancellationReasonHttpController } from '@EcamModel/controllers/http/CancellationReasonHttpController';
import { CameraPhotoHttpController } from '@EcamModel/controllers/http/CameraPhotoHttpController';
import { GroupPotentialContraventionController } from '@EcamModel/controllers/http/GroupPotentialContraventionController';
import { PotentialContraventionPhotoController } from '@EcamModel/controllers/http/PotentialContraventionPhotoController';
import { DashBoardHttpController } from '@EcamModel/controllers/http/DashBoardHttpController';
import { GeoZoneOperationConfigHttpController } from '@EcamModel/controllers/http/GeoZoneOperationConfigHttpController';
import { CameraOverviewHttpController } from '@EcamModel/controllers/http/CameraOverviewHttpController';
import { Solar4gCameraHttpController } from '@EcamModel/controllers/http/Solar4gCameraHttpController';
import { CameraExemptionPeriodAuditEventHttpController } from '@EcamModel/controllers/http/CameraExemptionPeriodAuditEventHttpController';
import { CameraTrailAuditEventHttpController } from '@EcamModel/controllers/http/CameraTrailAuditEventHttpController';
import { CameraPhotoVehicleHttpController } from '@EcamModel/controllers/http/CameraPhotoVehicleHttpController';
import { CameraNoteHttpController } from '@EcamModel/controllers/http/CameraNoteHttpController';
import { VerificationNoteHttpController } from '@EcamModel/controllers/http/VerificationNoteHttpController';
import { CameraInstalledPhotoHttpController } from '@EcamModel/controllers/http/CameraInstalledPhotoHttpController';
import { ContraventionFunnelHttpController } from '@EcamModel/controllers/http/ContraventionFunnelHttpController';
import { VerificationAuditHttpController } from '@EcamModel/controllers/http/VerificationAuditHttpController';
import { PTZCamConfigureRequestHttpController } from '@EcamModel/controllers/http/PTZCamConfigureRequestHttpController';
import { Solar4gCamPhotoUploadScheduleHttpController } from '@EcamModel/controllers/http/Solar4gCamPhotoUploadScheduleHttpController';
import { ThresholdSettingHttpController } from '@EcamModel/controllers/http/ThresholdSettingHttpController.ts';
import { CameraTaskHttpController } from '@EcamModel/controllers/http/CameraTaskHttpController';
import { CameraTaskTypeHttpController } from '@EcamModel/controllers/http/CameraTaskTypeHttpController';
import { CameraNotificationHttpController } from '@EcamModel/controllers/http/CameraNotificationHttpController';
import { CameraViewHttpController } from '@EcamModel/controllers/http/CameraViewHttpController';
import { ContraventionAuditEventHttpController } from '@EcamModel/controllers/http/ContraventionAuditEventHttpController';
import { CameraLogFilesHttpController } from '@EcamModel/controllers/http/CameraLogFilesHttpController';

const apiUrl = appConfig.gateway.backendURL;

export const cameraController = new CameraHttpController(apiUrl, axiosClient);
export const cameraPhotoController = new CameraPhotoHttpController(apiUrl, axiosClient);
export const locationController = new LocationHttpController(apiUrl, axiosClient);
export const zoneController = new ZoneHttpController(apiUrl, axiosClient);
export const geoZoneController = new GeoZoneHttpController(apiUrl, axiosClient);
export const cameraViewController = new CameraViewHttpController(apiUrl, axiosClient);
export const geoZoneOperationConfigController = new GeoZoneOperationConfigHttpController(apiUrl, axiosClient);
export const pcController = new PotentialContraventionHttpController(apiUrl, axiosClient);
export const cancelationReasonController = new CancellationReasonHttpController(apiUrl, axiosClient);
export const potentialGroupContraventionController = new GroupPotentialContraventionController(apiUrl, axiosClient);
export const potentialPhotoContraventionController = new PotentialContraventionPhotoController(apiUrl, axiosClient);
export const dashBoardHttpController = new DashBoardHttpController(apiUrl, axiosClient);
export const exemptionController = new ExemptionPeriodController(apiUrl, axiosClient);
export const cameraOverviewHttpController = new CameraOverviewHttpController(apiUrl, axiosClient);
export const solar4gCameraHttpController = new Solar4gCameraHttpController(apiUrl, axiosClient);
export const cameraExemptionPeriodAuditEventHttpController = new CameraExemptionPeriodAuditEventHttpController(
    apiUrl,
    axiosClient
);

export const cameraTrailAuditEventHttpController = new CameraTrailAuditEventHttpController(apiUrl, axiosClient);
export const cameraPhotoVehicleHttpController = new CameraPhotoVehicleHttpController(apiUrl, axiosClient);
export const cameraNoteHttpController = new CameraNoteHttpController(apiUrl, axiosClient);
export const verifierNoteHttpController = new VerificationNoteHttpController(apiUrl, axiosClient);
export const cameraInstalledPhotoHttpController = new CameraInstalledPhotoHttpController(apiUrl, axiosClient);
export const contraventionFunnelHttpController = new ContraventionFunnelHttpController(apiUrl, axiosClient);
export const auditVerificationHTTPController = new VerificationAuditHttpController(apiUrl, axiosClient);
export const cameraPTZConfigureRequestHttpController = new PTZCamConfigureRequestHttpController(apiUrl, axiosClient);
export const solar4gCamPhotoUploadScheduleHttpController = new Solar4gCamPhotoUploadScheduleHttpController(
    apiUrl,
    axiosClient
);
export const thresholdSettingHttpController = new ThresholdSettingHttpController(apiUrl, axiosClient);
export const taskHttpController = new CameraTaskHttpController(apiUrl, axiosClient);
export const taskTypeHttpController = new CameraTaskTypeHttpController(apiUrl, axiosClient);
export const cameraNotificationHttpController = new CameraNotificationHttpController(apiUrl, axiosClient);
export const contraventionAuditEventHttpController = new ContraventionAuditEventHttpController(apiUrl, axiosClient);
export const cameraLogFilesController = new CameraLogFilesHttpController(apiUrl, axiosClient);
