import { ConsolidateCameraMonitoring } from '@EcamModel/model/CameraOverview';
import {
    cameraPowerSuppliesReadableMapping,
    cameraPriorityReadableMapping,
    purposesReadableMapping,
} from '@pages/cameras/list/components/CameraItem';
import { TableInfoContent } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';
import _ from 'lodash';
import moment from 'moment';
import { startOfDay } from 'src/helpers';
import {
    aggregationFunctions,
    BooleanProperties,
    convertModel,
    DataType,
    DateCondition,
    ListCondition,
    NumberCondition,
    OptionType,
    SmartFilter,
    SmartFilterOption,
    StringCondition,
} from '../configs';
import {
    cameraServiceMapping,
    cameraStatusMapping,
    frequencyTimeMapping,
    netWorkingMapping,
    postTypeMapping,
    uploadModeMapping,
    wifiStatusMapping,
    getColumnsFieldCustom,
} from '@pages/camerasOverview/configs';
import { Threshold, ThresholdOption } from '@EcamModel/model/ThresholdSetting';

const getFieldNames = <T extends keyof ConsolidateCameraMonitoring>(option: OptionType<T>): T[] => {
    return option.fieldValue
        ? option.fieldValue.map((field) => field.id).filter((field): field is T => field !== undefined)
        : [];
};

const handleNumberOption = <T extends keyof ConsolidateCameraMonitoring>(option: OptionType<T>): SmartFilterOption => {
    return {
        field: getFieldNames(option),
        condition: option.condition as NumberCondition,
        value: option.numberRangeValue as number | number[],
    };
};

const handleStringOption = <T extends keyof ConsolidateCameraMonitoring>(option: OptionType<T>): SmartFilterOption => {
    return {
        field: getFieldNames(option),
        condition: option.condition as StringCondition,
        value: option.value,
    };
};

const handleDateOption = <T extends keyof ConsolidateCameraMonitoring>(option: OptionType<T>): SmartFilterOption => {
    return {
        field: getFieldNames(option),
        condition: option.condition as DateCondition,
        value: option.dateRangeValue as Date | Date[],
    };
};

const handleListOption = <T extends keyof ConsolidateCameraMonitoring>(option: OptionType<T>): SmartFilterOption => {
    const fieldListArray = option.fieldList ? [option.fieldList] : [];
    const value = option.numberRangeValue ? (option.numberRangeValue as number[]) : [0];

    return {
        field: fieldListArray as T[],
        condition: option.condition as ListCondition,
        value: value,
    };
};

export const convertOptionTypeNumberToSmartFilters = <T extends keyof ConsolidateCameraMonitoring>(
    optionsTypeNumber: OptionType<T>[],
    optionsTypeString: OptionType<T>[],
    optionsTypeList: OptionType<T>[],
    optionsTypeDate: OptionType<T>[]
): SmartFilter[] => {
    const smartFilterMap: Record<string, SmartFilterOption[]> = {};

    optionsTypeNumber.forEach((option) => {
        const smartFilterOption: SmartFilterOption = handleNumberOption(option);

        if (!smartFilterMap['Number']) {
            smartFilterMap['Number'] = [];
        }
        smartFilterMap['Number'].push(smartFilterOption);
    });

    const validOptionsTypeString = optionsTypeString.filter((option) => {
        const smartFilterOption = handleStringOption(option);
        return smartFilterOption.field.length > 0 || smartFilterOption.value !== '';
    });

    validOptionsTypeString.forEach((option) => {
        const smartFilterOption: SmartFilterOption = handleStringOption(option);
        if (!smartFilterMap['String']) {
            smartFilterMap['String'] = [];
        }
        smartFilterMap['String'].push(smartFilterOption);
    });

    optionsTypeList.forEach((option) => {
        const smartFilterOption: SmartFilterOption = handleListOption(option);

        if (!smartFilterMap['List']) {
            smartFilterMap['List'] = [];
        }
        smartFilterMap['List'].push(smartFilterOption);
    });

    optionsTypeDate.forEach((option) => {
        const smartFilterOption: SmartFilterOption = handleDateOption(option);

        if (!smartFilterMap['Date']) {
            smartFilterMap['Date'] = [];
        }
        smartFilterMap['Date'].push(smartFilterOption);
    });

    return Object.entries(smartFilterMap).map(([type, options]) => ({
        type: type as DataType,
        options: options,
    }));
};

const smartFilterToThreshold = (smartFilter: SmartFilter): Threshold => {
    return {
        Type: smartFilter.type,
        Options: smartFilter.options.map((option) => ({
            Field: option.field,
            Condition: option.condition,
            Value: option.value,
        })) as ThresholdOption[],
    };
};

const thresholdToSmartFilter = (threshold: Threshold): SmartFilter => {
    return {
        type: threshold.Type,
        options: threshold.Options.map((option) => ({
            field: option.Field,
            condition: option.Condition,
            value: option.Value,
        })) as SmartFilterOption[],
    };
};

export const convertSmartFiltersOptionToThreshold = (smartFilters: SmartFilter[]): Threshold[] => {
    return convertModel(smartFilters, smartFilterToThreshold);
};

export const convertThresholdToSmartFiltersOption = (thresholds: Threshold[]): SmartFilter[] => {
    return convertModel(thresholds, thresholdToSmartFilter);
};

export const idToFieldMapping = getColumnsFieldCustom().reduce<Record<string, keyof ConsolidateCameraMonitoring>>(
    (acc, column) => {
        acc[column.label] = column.id as keyof ConsolidateCameraMonitoring;
        return acc;
    },
    {}
);

export const numberConditions: NumberCondition[] = [
    'Greater Than',
    'Greater Than Or Equal',
    'Equal To',
    'Not Equal',
    'Less Than',
    'Less Than Or Equal',
    'Between',
];

export const stringConditions: StringCondition[] = ['Contains', 'Does Not Contain', 'Equals', 'Not Equals'];

export const dateConditions: DateCondition[] = [
    'After',
    'Before',
    'Equal And After',
    'Equal And Before',
    'Between',
    'Equals',
    'Not Equals',
];

export const listConditions: ListCondition[] = ['In', 'Not In'];

export type BooleanFieldsInConsolidateCameraMonitoring = BooleanProperties<ConsolidateCameraMonitoring>;

export const booleanFields: BooleanFieldsInConsolidateCameraMonitoring[] = ['IsVerificationsEnabled', 'Pingable'];
export const booleanDisplayValues: string[] = ['True', 'False'];
export const booleanRawValues = {
    True: 1,
    False: 0,
};

export const fieldToDisplayValues: Partial<Record<keyof ConsolidateCameraMonitoring, string[]>> = {
    CameraStatus: Object.values(cameraStatusMapping),
    NetworkType: Object.values(netWorkingMapping),
    UploadMode: Object.values(uploadModeMapping),
    LastWifiStatus: Object.values(wifiStatusMapping),
    PostType: Object.values(postTypeMapping),
    CameraService: Object.values(cameraServiceMapping),
    Frequency: Object.values(frequencyTimeMapping),
    PurposeType: Object.values(purposesReadableMapping),
    CameraPowerSupply: Object.values(cameraPowerSuppliesReadableMapping),
    CameraPriority: Object.values(cameraPriorityReadableMapping),
    Pingable: booleanDisplayValues,
    IsVerificationsEnabled: booleanDisplayValues,
};

export const fieldToRawValuesMapping: Partial<Record<keyof ConsolidateCameraMonitoring, Record<number, string>>> = {
    Frequency: frequencyTimeMapping,
    PurposeType: purposesReadableMapping,
    CameraPowerSupply: cameraPowerSuppliesReadableMapping,
    CameraPriority: cameraPriorityReadableMapping,
    CameraService: cameraServiceMapping,
    PostType: postTypeMapping,
    LastWifiStatus: wifiStatusMapping,
    UploadMode: uploadModeMapping,
    NetworkType: netWorkingMapping,
    CameraStatus: cameraStatusMapping,
    Pingable: booleanRawValues,
    IsVerificationsEnabled: booleanRawValues,
};

export const typePriority: Record<DataType, number> = {
    Number: 1,
    String: 2,
    Date: 3,
    List: 4,
};

export function generateSmartFilterConfigurations<T>(
    groupedData: Record<string, TableInfoContent<T>[]>,
    typeMapping: Record<DataType, (item: TableInfoContent<T>) => SmartFilterOption | undefined>
): SmartFilter[] {
    return Object.keys(groupedData).map((type) => {
        const filterType = type as DataType;
        const mapFunction = typeMapping[filterType];
        const aggregationFunction = aggregationFunctions[filterType];

        if (!mapFunction || !aggregationFunction) {
            return {
                type: filterType,
                options: [],
            } as SmartFilter;
        }

        const options = groupedData[filterType]
            .map((item: TableInfoContent<T>) => mapFunction(item))
            .filter((option): option is SmartFilterOption => option !== undefined);

        const aggregatedOption = aggregationFunction(options);

        return {
            type: filterType,
            options: aggregatedOption ? [aggregatedOption] : [],
        } as SmartFilter;
    });
}

// ======================== Smart HighlightFilter =================
export const colorHighlight: string = '#FFD35A';

const handleNumberCondition = (
    condition: NumberCondition,
    value: number,
    optionValue: number | number[]
): string | undefined => {
    switch (condition) {
        case 'Greater Than':
            return Number(value) > Number(optionValue) ? colorHighlight : undefined;
        case 'Greater Than Or Equal':
            return Number(value) >= Number(optionValue) ? colorHighlight : undefined;
        case 'Less Than':
            return Number(value) < Number(optionValue) ? colorHighlight : undefined;
        case 'Less Than Or Equal':
            return Number(value) <= Number(optionValue) ? colorHighlight : undefined;
        case 'Equal To':
            return value === optionValue ? colorHighlight : undefined;
        case 'Not Equal':
            return value !== optionValue ? colorHighlight : undefined;
        case 'Between':
            const [min, max] = optionValue as number[];
            return value >= min && value <= max ? colorHighlight : undefined;
        default:
            return undefined;
    }
};

const handleStringCondition = (condition: StringCondition, value: string, optionValue: string): string | undefined => {
    switch (condition) {
        case 'Contains':
            return value.toLowerCase().includes(optionValue.toLowerCase()) ? colorHighlight : undefined;
        case 'Does Not Contain':
            return !value.toLowerCase().includes(optionValue.toLowerCase()) ? colorHighlight : undefined;
        case 'Equals':
            return value === optionValue ? colorHighlight : undefined;
        case 'Not Equals':
            return value !== optionValue ? colorHighlight : undefined;
        default:
            return undefined;
    }
};

const handleListCondition = (condition: ListCondition, value: number, optionValue: number[]): string | undefined => {
    switch (condition) {
        case 'In':
            return optionValue.includes(value) ? colorHighlight : undefined;
        case 'Not In':
            return !optionValue.includes(value) ? colorHighlight : undefined;
        default:
            return undefined;
    }
};

const handleDateCondition = (condition: DateCondition, value: Date, optionValue: Date | Date[]): string | undefined => {
    const valueDate = startOfDay(value);

    if (Array.isArray(optionValue)) {
        const [start, end] = optionValue.map(startOfDay);
        return handleDateConditionForRange(condition, valueDate, start, end);
    } else {
        const optionDate = startOfDay(optionValue as Date);
        return handleDateConditionForSingle(condition, valueDate, optionDate);
    }
};

const handleDateConditionForSingle = (
    condition: DateCondition,
    valueDate: moment.Moment,
    optionDate: moment.Moment
): string | undefined => {
    switch (condition) {
        case 'After':
            return valueDate.isAfter(optionDate) ? colorHighlight : undefined;
        case 'Before':
            return valueDate.isBefore(optionDate) ? colorHighlight : undefined;
        case 'Equal And After':
            return valueDate.isSameOrAfter(optionDate) ? colorHighlight : undefined;
        case 'Equal And Before':
            return valueDate.isSameOrBefore(optionDate) ? colorHighlight : undefined;
        case 'Equals':
            return valueDate.isSame(optionDate) ? colorHighlight : undefined;
        case 'Not Equals':
            return !valueDate.isSame(optionDate) ? colorHighlight : undefined;
        default:
            return undefined;
    }
};

const handleDateConditionForRange = (
    condition: DateCondition,
    valueDate: moment.Moment,
    startDate: moment.Moment,
    endDate: moment.Moment
): string | undefined => {
    switch (condition) {
        case 'Between':
            return valueDate.isBetween(startDate, endDate, null, '[]') ? colorHighlight : undefined;
        default:
            return undefined;
    }
};

export const getFieldHighlightColor = <K extends keyof ConsolidateCameraMonitoring>(
    fieldName: K,
    value: ConsolidateCameraMonitoring[K],
    highLightFilters: SmartFilter[]
): string | undefined => {
    console.log('🚀 ~ value!!:', value);
    if (_.isNil(value)) {
        return undefined;
    }

    if (!highLightFilters?.length) return;

    for (const filter of highLightFilters) {
        for (const option of filter.options) {
            if (option.field.includes(fieldName)) {
                const optionValue = option.value;

                switch (filter.type) {
                    case 'Number':
                        if (typeof value === 'number' && !isNaN(value)) {
                            return handleNumberCondition(
                                option.condition as NumberCondition,
                                Number(value),
                                optionValue as number | number[]
                            );
                        }
                        break;
                    case 'String':
                        if (typeof value === 'string') {
                            return handleStringCondition(
                                option.condition as StringCondition,
                                value,
                                optionValue as string
                            );
                        }
                        break;
                    case 'List':
                        if (typeof value === 'number' || typeof value === 'boolean') {
                            return handleListCondition(
                                option.condition as ListCondition,
                                Number(value),
                                optionValue as number[]
                            );
                        }
                        break;
                    case 'Date':
                        if (value instanceof Date || !isNaN(Date.parse(value as string))) {
                            return handleDateCondition(
                                option.condition as DateCondition,
                                new Date(value as any),
                                optionValue as Date | Date[]
                            );
                        }
                        break;
                    default:
                        return undefined;
                }
            }
        }
    }
    return undefined;
};
