import { SxProps, Theme } from '@mui/material';
import color from '@theme/Colors';

export const badgeSx: SxProps<Theme> = {
    '& .MuiBadge-badge': {
        border: `2px solid ${color.white}`,
        fontSize: '9px',
        top: -2,
    },
};

export const tabSx: SxProps<Theme> = {
    '& .MuiTabs-indicator': {
        background: color.success,
    },
    '& .MuiButtonBase-root': {
        px: 1.5,
        fontSize: 14,
        background: color.white,
        color: color.grey600,
        minWidth: 90,
    },
    '& .Mui-selected': {
        color: `${color.success} !important`,
        '&.MuiTab-root': {
            background: color.white,
        },
    },
    borderRadius: 0,
};

export const scrollableDiv: SxProps<Theme> = {
    overflow: 'auto',
    width: '500px',
    '::-webkit-scrollbar': {
        width: '2px',
    },
};

export const popperSx: SxProps<Theme> = {
    p: 2,
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px 0px 0px 1px',
    bgcolor: 'white',
    borderRadius: 1,
};

export const popperModifiers = [
    {
        name: 'offset',
        options: {
            offset: [-100, 25],
        },
    },
];

export const hoverTextSx: SxProps<Theme> = {
    cursor: 'pointer',
    '&:hover': {
        textDecoration: 'underline',
    },
};
