import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { Location, LocationSM } from '@EcamModel/model';
import { AxiosInstance } from 'axios';
import { IGetLocationProps, ILocationHttpController } from '../ILocationHttpController';
import { IListWithECamServiceActive, ILocationSMHttpController } from '../ILocationSMHttpController';

export class LocationHttpController
    extends BaseHttpController<Location>
    implements ILocationHttpController, ILocationSMHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'location', client);
    }

    async list(props: ListProps<Location>): Promise<Paging<Location>> {
        return await this.doPost({ path: 'filter', body: props }).then((res) => res.data);
    }

    async listHasCamera(props: IGetLocationProps): Promise<Paging<Location>> {
        return await this.doPost({ path: 'has-camera', body: props }).then((res) => res.data);
    }

    async listHasLocation(props: IGetLocationProps): Promise<Paging<Location>> {
        return await this.doPost({ path: 'has-location', body: props }).then((res) => res.data);
    }

    async listLocationSM(props: ListProps<LocationSM>): Promise<Paging<LocationSM>> {
        return await this.doPost({ path: 'list-LocationSM', body: props }).then((res) => res.data);
    }

    async listWithECamServiceActive(props: IListWithECamServiceActive): Promise<Paging<LocationSM>> {
        return await this.doPost({ path: 'list-with-ecam-service-active', body: props }).then((res) => res.data);
    }

    async getPaginationWithCameras(props: IGetLocationProps): Promise<Paging<Location>> {
        return await this.doPost({ path: 'get-pagination-with-cameras', body: props }).then((res) => res.data);
    }
}
