import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraTaskType } from '@EcamModel/model/CameraTaskType';
import { AxiosInstance } from 'axios';
import { ICameraTaskTypeHttpController } from '../ICameraTaskTypeHttpController';

export class CameraTaskTypeHttpController extends BaseHttpController<CameraTaskType> implements ICameraTaskTypeHttpController {
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-task-type', client);
    }
}
