export default function CloseNotiIcon() {
    return (
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.05086 6.13664C6.66033 5.74612 6.02717 5.74612 5.63664 6.13664C5.24612 6.52717 5.24612 7.16033 5.63664 7.55086L10.5864 12.5006L5.63664 17.4504C5.24612 17.8409 5.24612 18.474 5.63664 18.8646C6.02717 19.2551 6.66033 19.2551 7.05086 18.8646L12.0006 13.9148L16.9504 18.8646C17.3409 19.2551 17.974 19.2551 18.3646 18.8646C18.7551 18.474 18.7551 17.8409 18.3646 17.4504L13.4148 12.5006L18.3646 7.55086C18.7551 7.16033 18.7551 6.52717 18.3646 6.13664C17.974 5.74612 17.3409 5.74612 16.9504 6.13664L12.0006 11.0864L7.05086 6.13664Z"
                fill="#85858A"
            />
        </svg>
    );
}
