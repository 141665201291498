import CloseNotiIcon from '@assets/jsx-icon/CloseNotiIcon';
import NotificationIcon from '@assets/jsx-icon/NotificationIcon';
import { CameraNotificationType } from '@EcamModel/model/CameraNotification';
import {
    Badge,
    Box,
    ClickAwayListener,
    Fade,
    IconButton,
    Popper,
    Skeleton,
    Stack,
    Tab,
    Tabs,
    Typography,
} from '@mui/material';
import color from '@theme/Colors';
import { useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import CameraNotificationItem from './components/CameraNotificationItem';
import TaskNotificationItem from './components/TaskNotificationItem';
import useNotificationContext, { NotificationContext } from './hooks/NotificationProvider';
import { badgeSx, hoverTextSx, popperModifiers, popperSx, scrollableDiv, tabSx } from './style/sx-props';

export default function CameraNotifications() {
    const notificationContext = useNotificationContext();
    const {
        loading,
        notificationUnread,
        listNotification,
        page,
        setPage,
        tabValue,
        setTabValue,
        open,
        setOpen,
        markAllRead,
        getNotifications,
        handleClose,
    } = notificationContext;

    const anchorRef = useRef<HTMLButtonElement>(null);

    const handleClickAway = (event: Event | React.SyntheticEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        handleClose();
    };

    return (
        <NotificationContext.Provider value={notificationContext}>
            <Box mr={3}>
                <IconButton ref={anchorRef} sx={{ padding: 0 }} onClick={() => setOpen((prevOpen) => !prevOpen)}>
                    <Badge color="error" badgeContent={notificationUnread} max={9} sx={badgeSx}>
                        <NotificationIcon />
                    </Badge>
                </IconButton>

                <ClickAwayListener onClickAway={handleClickAway}>
                    <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        disablePortal
                        sx={popperSx}
                        modifiers={popperModifiers}
                        transition
                    >
                        {({ TransitionProps }) => (
                            <Fade {...TransitionProps} timeout={300}>
                                <Stack bgcolor={'background.paper'} height="80vh">
                                    <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                        <Typography variant="h3">Notifications</Typography>
                                        <IconButton sx={{ p: '2px' }} onClick={handleClose}>
                                            <CloseNotiIcon />
                                        </IconButton>
                                    </Stack>

                                    <Box borderBottom={1} pt={3} borderColor={color.grey200}>
                                        <Tabs value={tabValue} onChange={(e, val) => setTabValue(val)} sx={tabSx}>
                                            <Tab label={`All`} />
                                            {notificationUnread > 0 && <Tab label={`Unread (${notificationUnread})`} />}
                                        </Tabs>
                                    </Box>

                                    {notificationUnread > 0 && (
                                        <Stack
                                            direction={'row'}
                                            justifyContent={'flex-end'}
                                            alignItems={'center'}
                                            mt={2}
                                        >
                                            <Typography onClick={markAllRead} color={color.secondary} sx={hoverTextSx}>
                                                Mark all as read
                                            </Typography>
                                        </Stack>
                                    )}

                                    <Box id="scrollableDiv" sx={scrollableDiv} mt={2}>
                                        {loading ? (
                                            <Stack gap={1}>
                                                {[...Array(5)].map((e, i) => (
                                                    <SkeletonNoti key={i} />
                                                ))}
                                            </Stack>
                                        ) : listNotification?.rows?.length ? (
                                            <InfiniteScroll
                                                dataLength={listNotification?.rows?.length ?? 0}
                                                next={() => {
                                                    setPage((prevPage) => prevPage + 1);
                                                    getNotifications(page + 1);
                                                }}
                                                hasMore={page < listNotification.totalPages}
                                                loader={[...Array(2)].map((e, i) => (
                                                    <SkeletonNoti key={i} />
                                                ))}
                                                scrollThreshold={1}
                                                scrollableTarget="scrollableDiv"
                                                endMessage={
                                                    page > 1 && (
                                                        <Stack alignItems={'center'} pt={2}>
                                                            <Typography>That's all your notifications.</Typography>
                                                        </Stack>
                                                    )
                                                }
                                            >
                                                <Stack gap={1}>
                                                    {listNotification.rows?.map((item, index) => {
                                                        switch (item.Type) {
                                                            case CameraNotificationType.Task:
                                                                return (
                                                                    <TaskNotificationItem
                                                                        key={item.Id}
                                                                        item={item}
                                                                        index={index}
                                                                    />
                                                                );

                                                            default:
                                                                return (
                                                                    <CameraNotificationItem
                                                                        key={item.Id}
                                                                        item={item}
                                                                        index={index}
                                                                    />
                                                                );
                                                        }
                                                    })}
                                                </Stack>
                                            </InfiniteScroll>
                                        ) : (
                                            <Stack
                                                alignItems={'center'}
                                                px={2}
                                                height={'100px'}
                                                justifyContent={'center'}
                                            >
                                                <Typography fontWeight={500}>
                                                    Currently, nothing to notification!
                                                </Typography>
                                                <Typography textAlign={'center'} color={color.grey600}>
                                                    This area will light up with new notifications once there's
                                                    threshold breaches in your camera overview page.
                                                </Typography>
                                            </Stack>
                                        )}
                                    </Box>
                                </Stack>
                            </Fade>
                        )}
                    </Popper>
                </ClickAwayListener>
            </Box>
        </NotificationContext.Provider>
    );
}

const SkeletonNoti = () => {
    return (
        <Stack flex={1} p={'8px 16px'} gap={1}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                <Skeleton variant="text" animation="wave" width={120} />
                <Stack width={'5%'} alignItems="center" justifyItems={'flex-end'}>
                    <Skeleton variant="circular" animation="wave" width={10} height={10} />
                </Stack>
            </Stack>
            {[...Array(2)].map((_, index) => (
                <Skeleton key={index} variant="text" animation="wave" height={18} width={400} />
            ))}
        </Stack>
    );
};
