import { ListProps, Paging } from '@Core';
import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { CameraNotes } from '@EcamModel/model/CameraNotes';
import { CameraNotification } from '@EcamModel/model/CameraNotification';
import { AxiosInstance } from 'axios';
import {
    ICameraNotificationHttpController,
    IMarkCameraNotificationsAsRead,
} from '../ICameraNotificationHttpController';

export class CameraNotificationHttpController
    extends BaseHttpController<CameraNotes>
    implements ICameraNotificationHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'camera-notifications', client);
    }
    async getCameraNoticationUnReadCount(): Promise<number> {
        return await this.doGet({ path: 'get-camera-notication-un-read-count' }).then((res) => res.data);
    }

    async markCameraNotificationsAsRead(props: IMarkCameraNotificationsAsRead): Promise<boolean> {
        return await this.doPost({ path: 'mark-as-read', body: props }).then((res) => res.data);
    }

    async markAllCameraNotificationsAsRead(): Promise<boolean> {
        return await this.doPost({ path: 'mark-all-read' }).then((res) => res.data);
    }

    async getListCameraNotificationByUser(props: ListProps<CameraNotification>): Promise<Paging<CameraNotification>> {
        return await this.doPost({ path: 'get-list-camera-notification-by-user', body: props }).then((res) => res.data);
    }
}
