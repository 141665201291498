import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function FilterIcon(props: Props) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" fill="none" viewBox="0 0 17 16">
            <g clipPath="url(#clip0_3604_3262)">
                <path
                    fill="#85858A"
                    fillRule="evenodd"
                    d="M1.35 3a.75.75 0 100 1.5h14.5a.75.75 0 000-1.5H1.35zM3.6 7.75A.75.75 0 014.35 7h8.5a.75.75 0 010 1.5h-8.5a.75.75 0 01-.75-.75zm3 4a.75.75 0 01.75-.75h2.5a.75.75 0 010 1.5h-2.5a.75.75 0 01-.75-.75z"
                    clipRule="evenodd"
                ></path>
            </g>
            <defs>
                <clipPath id="clip0_3604_3262">
                    <path fill="#fff" d="M0 0H16V16H0z" transform="translate(.6)"></path>
                </clipPath>
            </defs>
        </svg>
    );
}
