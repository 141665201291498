import { ChartData, Chart as ChartJS, ChartOptions, registerables } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Bar } from 'react-chartjs-2';
ChartJS.register(...registerables);

export type BarChart = {
    label: string;
    value: number;
};

type Props = {
    data: BarChart[];
    chartColor: string;
    label: string;
    dataAxisY: boolean;
    dataBattery?: boolean;
};

export default function BarChartCameraStatus(props: Props) {
    const data: ChartData<'bar', number[], string> = {
        labels: props.data?.map((item) => item.label),
        datasets: [
            {
                label: props.label,
                data: props.data?.map((item) => item.value),
                backgroundColor: props.chartColor,
                borderColor: props.chartColor,
            },
        ],
    };

    const options: ChartOptions<'bar'> = {
        responsive: true,
        // set height not crash
        maintainAspectRatio: false,
        plugins: {
            //note
            legend: {
                display: false,
                position: 'bottom' as const,
                align: 'center',
                //set note label font
                labels: {
                    font: {
                        size: 16,
                    },
                    padding: 20,
                    boxWidth: 5,
                    boxHeight: 5,
                    usePointStyle: true,
                    pointStyle: 'circle',
                },
            },

            // Top Label
            datalabels: {
                display: false,
                color: 'black',
                anchor: 'end',
                align: 'top',
                font: {
                    size: 11,
                },
            },
            tooltip: {
                enabled: true,
                callbacks: {
                    label(tooltipItems: any) {
                        return ` ${tooltipItems.dataset.label}: ${tooltipItems.formattedValue}`;
                    },
                },
            },
        },
        layout: {
            padding: {
                top: 20,
            },
        },
        //setting grid
        scales: {
            x: {
                grid: {
                    display: false,
                },
                stacked: true,
            },
            y: {
                grid: {
                    display: false,
                },
                ticks: {
                    //y step
                    stepSize: props.dataAxisY ? 24 : props.dataBattery ? 100 : 500000,
                },

                //y label start at 0
                beginAtZero: true,
                min: 0,
                max: props.dataAxisY ? 24 : props.dataBattery ? 100 : undefined,
                stacked: true,
            },
        },
    };

    return <Bar options={options} data={data} plugins={[ChartDataLabels]} />;
}
