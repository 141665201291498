import { Paging } from '@Core';
import { IUpsertCameraProps } from '@EcamModel/controllers/ICameraHttpController';
import { IGetGeoZoneProps } from '@EcamModel/controllers/IGeoZoneHttpController';
import { ISendActionGet } from '@EcamModel/controllers/IPTZCamConfigureRequestHttpController';
import { CameraWithInfo, ExemptionPeriods, GeoZoneCoordinates, GeoZones, Status } from '@EcamModel/model';
import { CameraView, PhotoSnapShotByPTZ } from '@EcamModel/model/CameraView';
import { PTZSysInfo } from '@EcamModel/model/PTZSysInfo';
import EventLogIcon from '@assets/jsx-icon/EventLogIcon';
import ICNote from '@assets/jsx-icon/ICNote';
import InformationTabsIcon from '@assets/jsx-icon/InformationTabsIcon';
import { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import { DrawerContext, DrawerPage } from '@components/drawer/useDrawer';
import { TabConfig } from '@components/tabs/BaseEnhancedTabs';
import { pushError, pushSuccess, pushWarning } from '@components/toast';
import {
    cameraController,
    cameraPTZConfigureRequestHttpController,
    cameraTrailAuditEventHttpController,
    cameraViewController,
    exemptionController,
    geoZoneController,
} from '@controllers/index';
import usePopUp from '@hooks/usePopUp';
import useScrollToTop from '@hooks/useScrollToTop';
import { Filter, initPaging } from '@pages/cameras/list';
import { useImageDimensions } from '@pages/cameras/live-photos/components/PreviewImage';
import { a11yProps } from '@pages/overview/tabs/useTabsData';
import color from '@theme/Colors';
import { createContext, useContext, useEffect, useRef, useState } from 'react';
import { PiCubeFocusThin, PiFileCsvLight } from 'react-icons/pi';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useBackdrop } from 'src/providers/BackdropProvider';
import useMileSightSolar from './useMilesightSolar';
import TaskIcon from '@assets/jsx-icon/TaskIcon';
import { TabIndex } from '../details';
import { CameraTrailAuditEvent } from '@EcamModel/model/CameraTrailAudit';
import { useQuery } from 'react-query';
import { AxiosError } from '@controllers/axiosClient';
import { useDebounce } from 'usehooks-ts';
import { OwnFilter } from '@pages/overview/components/CancellationReasons';

export const getGeoZone = (_scale: number, prevGeoZonePoints: GeoZoneCoordinates[][]) => {
    return prevGeoZonePoints?.map((polygon) =>
        polygon?.map((point) => ({
            x: point?.XPoint * _scale,
            y: point?.YPoint * _scale,
            geoZoneId: point?.GeoZoneId,
        }))
    );
};

export const PREVIEW_RATIO = 0.5625;

export default function useCameraDetailProvider() {
    const params = useParams<{ id: string }>();
    const { locationId } = useParams();
    const location = useLocation();
    const state = location?.state;

    const navigate = useNavigate();
    const { open, setActiveItem } = useContext(DrawerContext);

    useScrollToTop();
    useEffect(() => {
        setActiveItem(DrawerPage.Cameras);
    }, []);

    const backdrop = useBackdrop();

    const [camera, setCamera] = useState<CameraWithInfo>({} as CameraWithInfo);
    const [loading, setLoading] = useState<boolean>(true);

    const latestBlobPhoto = camera.CameraPhotos;
    const originalBlobPhoto = camera.OriginalPhoto;
    const notAvailable = camera.Status === Status.NotAvailable;
    const captureAt = camera.CameraPhotos?.[0]?.CaptureAt;
    const timeZone = camera.TimeZone;

    const containerRef = useRef<HTMLDivElement | null>(null);
    const [containerSize, setContainerSize] = useState({
        width: window.innerWidth,
        height: window.innerWidth * PREVIEW_RATIO,
    });

    const [wakeUpLoading, setWakeUpLoading] = useState(false);
    const [value, setValue] = useState<number>(state?.tabValue ?? 0);
    const [openIMGPreview, setOpenIMGPreview] = useState<boolean>(false);
    const [photoSnapShotByPTZ, setPhotoSnapShotByPTZ] = useState<PhotoSnapShotByPTZ>({} as PhotoSnapShotByPTZ);

    const blobLatestPhoto = latestBlobPhoto?.[0]?.BlobName;
    const blobOriginalPhoto = originalBlobPhoto?.BlobName;
    const { image: imageLatest, loading: loadingLatest, isError: isErrorLatest } = useImageDimensions(blobLatestPhoto);
    const {
        image: imageOriginal,
        loading: loadingOriginal,
        isError: isErrorOriginal,
    } = useImageDimensions(blobOriginalPhoto);

    const widthImage = 1245;

    const scaleNotFullLatestCameraSolar = containerSize.width / (imageLatest?.width ?? 1920);
    const scaleFullLatestCameraSolar = 1280 / (imageLatest?.width ?? 1920);

    const scaleNotFullOriginalCameraSolar = containerSize.width / (imageOriginal?.width ?? 1920);
    const scaleFullOriginalCameraSolar = 1280 / (imageOriginal?.width ?? 1920);

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Cameras', onBack: true },
        { title: `${camera.Name}`, onBack: false, isColorBlackText: true },
    ];

    const [cameraDetailsPTZ, setCameraDetailsPTZ] = useState<PTZSysInfo>({} as PTZSysInfo);
    const [isLoadingDetailsCameraPTZ, setIsLoadingDetailsCameraPTZ] = useState<boolean>(true);

    const [filteredCamera, setFilteredCamera] = useState<ISendActionGet>({} as ISendActionGet);
    const [filteredListGeoZones, setFilteredListGeoZones] = useState<IGetGeoZoneProps>({} as IGetGeoZoneProps);
    const [listGeoZonesByView, setListGeoZonesByView] = useState<Paging<GeoZones>>({ ...initPaging });

    const [hoveredFrame, setHoveredFrame] = useState<number | null>(null);

    const [anchorElMenuLists, setAnchorElMenuLists] = useState<null | { mouseX: number; mouseY: number }>(null);
    const openMenuLists = Boolean(anchorElMenuLists);

    const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);

    const [imageElement, setImageElement] = useState<HTMLImageElement | null>(null);

    const popUpDeleteGeoZonesOverview = usePopUp();
    const popUpConfigViewCameraPTZ = usePopUp();

    const scaleNotFullCameraPTZ = containerSize.width / (imageElement?.width ?? 1920);
    const scaleFullCameraPTZ = 1280 / (imageElement?.width ?? 1920);

    const [filterTrailAuditEvent, setFilterTrailAuditEvent] = useState<Filter>({
        page: 1,
        from: undefined,
        to: undefined,
        search: '',
    });

    const [filterSortStateTrailAuditEvent, setFilterSortStateTrailAuditEvent] = useState<OwnFilter>({
        sorts: ['-Updated'],
    } as OwnFilter);
    const debouncedValue = useDebounce<string>(filterTrailAuditEvent.search ?? '', 500);

    const setPartialTrailAuditEvent = (e: Partial<Filter>) => {
        setFilterTrailAuditEvent((prev) => ({ ...prev, ...e, page: 1 }));
    };

    const getDetailsCamera = async () => {
        setLoading(true);
        await cameraController
            .get(params.id!)
            .then(async (res) => {
                setCamera(res);
                if (Number(locationId) !== res.Zone?.LocationId) {
                    pushError('Camera does not exist in this location');
                    navigate(`/locations/${locationId}/cameras`);
                }

                const filteredCamera: ISendActionGet = {
                    ip: res.IP,
                    authenticate: {
                        password: res.PassWord,
                        username: res.UserName,
                    },
                };

                if (res.CameraViews?.length && res.CameraViews?.length > 0) {
                    const filteredListGeoZones: IGetGeoZoneProps = {
                        pageSize: 10000,
                        filter: {
                            CameraId: res.Id!,
                            CameraViewId: res.CameraViews?.[0].Id,
                        },
                    };

                    setFilteredListGeoZones(filteredListGeoZones);
                }

                setFilteredCamera(filteredCamera);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setLoading(false);
                popUpConfigViewCameraPTZ.onClose();
            });
    };

    useEffect(() => {
        getDetailsCamera();
    }, [params.id]);

    const handleUpdateCamera = async (_camera: CameraWithInfo) => {
        backdrop.setTrue();
        await cameraController
            .upsertWithSolarCamera(_camera as IUpsertCameraProps)
            .then((res) => {
                setCamera(res);
                pushSuccess('Saved successfully');
            })
            .catch((err) => {})
            .finally(() => backdrop.setFalse());
    };

    const handleAddExemption = (exemption: ExemptionPeriods) => {
        exemptionController.upsert(exemption).then((res) => {
            exemptionController
                .list({
                    filter: {
                        CameraId: camera.Id,
                    },
                })
                .then((res) => {
                    setCamera((prev) => ({ ...prev, ExemptionPeriods: res.rows }));
                    pushSuccess('Added successfully');
                });
        });
    };

    const handleDeleteExemption = (exemptionId: number) => {
        exemptionController.delete(exemptionId.toString()).then((res) => {
            exemptionController
                .list({
                    filter: {
                        CameraId: camera.Id,
                    },
                })
                .then((res) => {
                    setCamera((prev) => ({ ...prev, ExemptionPeriods: res.rows }));
                    pushSuccess('Deleted successfully');
                });
        });
    };

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const tabs: TabConfig[] = [
        {
            label: 'Base information',
            icon: <InformationTabsIcon color={value === 0 ? color.success : '#85858A'} />,
            index: 1,
        },
        {
            label: `Geo-zones ${camera.VendorCameraId !== null ? `(${camera.GeoZones?.length ?? 0})` : ''}`,
            icon: <PiCubeFocusThin color={value === 1 ? color.success : '#85858A'} size={22} />,
            sx: {
                '.MuiTab-iconWrapper': {
                    mb: '2px',
                },
            },
            index: 2,
        },
        {
            label: 'More information',
            icon: <ICNote color={value === 2 ? color.success : '#85858A'} width={17} height={17} />,
            sx: {
                '.MuiTab-iconWrapper': {
                    mb: '2px',
                },
            },
            index: 3,
        },
        {
            label: 'Task',
            icon: <TaskIcon color={value === 3 ? color.success : '#85858A'} width={20} />,
            index: 4,
        },
        {
            label: 'Event log',
            icon: <EventLogIcon color={value === 4 ? color.success : '#85858A'} width={18} height={17} />,
            index: 5,
        },
        {
            label: 'File log',
            icon: <PiFileCsvLight color={value === 5 ? color.success : '#85858A'} fontSize={20} />,
            index: 6,
        },
    ];

    const prevGeoZonePointsCameraSolar: GeoZoneCoordinates[][] =
        (camera.GeoZones?.map((gz) => {
            return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
        }) as GeoZoneCoordinates[][]) ?? [];

    const prevGeoZonePointsCameraPTZ: GeoZoneCoordinates[][] =
        (listGeoZonesByView.rows?.map((gz) => {
            return gz.GeoZoneCoordinates?.sort((a, b) => a.Index - b.Index).concat(gz.GeoZoneCoordinates[0]);
        }) as GeoZoneCoordinates[][]) ?? [];

    const prevGeoZonesCameraSolar = getGeoZone(scaleFullLatestCameraSolar, prevGeoZonePointsCameraSolar);
    const scaledPrevGeoZoneCameraSolar = getGeoZone(scaleNotFullLatestCameraSolar, prevGeoZonePointsCameraSolar);

    const prevGeoZonesCameraPTZ = getGeoZone(scaleFullCameraPTZ, prevGeoZonePointsCameraPTZ);
    const scaledPrevGeoZoneCameraPTZ = getGeoZone(scaleNotFullCameraPTZ, prevGeoZonePointsCameraPTZ);

    const handleClickImagePreview = () => {
        setOpenIMGPreview(true);
    };

    const handleRefreshImage = () => {
        return cameraController
            .getLatestCameraPhoto(camera.Id!)
            .then(async (res) => {
                await refetchTrailAuditEvent();
                const currentPhotoId = camera.CameraPhotos?.[0]?.Id;
                if (!res) {
                    pushWarning("Didn't receive new photos from camera. Please try again");
                } else if (res.Id === currentPhotoId) {
                    pushWarning("Didn't receive new photos from camera. Please try again");
                } else {
                    setCamera((prev) => ({ ...prev, CameraPhotos: [res] }));
                    pushSuccess('Latest photo has been updated');
                }
            })
            .catch((err) => {
                console.log({ err });
                pushError(err?.response?.data?.message || 'Have an error');
            });
    };

    const handleDeleteGeoZoneSuccess = (id: string) => {
        popUpDeleteGeoZonesOverview.onClose();
        pushSuccess('Delete geo-zone successfully');
        setListGeoZonesByView((prevList) => {
            const updatedRows = prevList.rows.filter((geoZone) => geoZone.Id !== Number(id));
            return {
                ...prevList,
                rows: updatedRows,
            };
        });
    };

    const handleDeleteGeoZonesOverView = async (id: string) => {
        setIsLoadingSave(true);
        await geoZoneController
            .deleteGeoZoneOverview(Number(id))
            .then(async (_) => {
                handleDeleteGeoZoneSuccess(id);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
                setAnchorElMenuLists(null);
            });
    };

    const handleDeleteGeoZonesDetail = async (id: string) => {
        setIsLoadingSave(true);
        await geoZoneController
            .delete(id)
            .then((_) => {
                handleDeleteGeoZoneSuccess(id);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
                setAnchorElMenuLists(null);
            });
    };

    const [isLoading, setIsLoading] = useState<boolean>(true);

    const handleGetListGeoZoneByView = async (_filter: IGetGeoZoneProps) => {
        setIsLoading(true);
        await geoZoneController
            .listGeoZoneByCameraView(_filter)
            .then((res) => {
                setListGeoZonesByView(res);
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    useEffect(() => {
        const isLastCall = filteredListGeoZones && Object.keys(filteredListGeoZones).length > 0;

        if (isLastCall) {
            handleGetListGeoZoneByView(filteredListGeoZones);
        }
    }, [filteredListGeoZones]);

    const handleCloseMenuLists = () => {
        setAnchorElMenuLists(null);
    };

    const isPointInPolygon = (point: { x: number; y: number }, polygon: { x: number; y: number }[]) => {
        let isInside = false;
        const { x, y } = point;

        for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
            const xi = polygon[i].x,
                yi = polygon[i].y;
            const xj = polygon[j].x,
                yj = polygon[j].y;

            const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
            if (intersect) isInside = !isInside;
        }

        return isInside;
    };

    const handleMouseMove = (event: any) => {
        const mousePos = {
            x: event.evt.layerX,
            y: event.evt.layerY,
        };

        const hovered = scaledPrevGeoZoneCameraPTZ.findIndex((frame) => isPointInPolygon(mousePos, frame));

        setHoveredFrame(hovered !== -1 ? hovered : null);

        if (hovered !== -1) {
            setAnchorElMenuLists({ mouseX: event.evt.clientX, mouseY: event.evt.clientY });
        } else {
            setAnchorElMenuLists(null);
        }
    };

    const handleResetStyledMenu = () => {
        handleCloseMenuLists();
        setHoveredFrame(null);
    };

    const handleUpsertView = (presetName: string) => {
        setIsLoadingSave(true);
        const _filter: CameraView = {
            Id: camera.CameraViews?.length && camera.CameraViews?.length > 0 ? camera.CameraViews[0].Id : undefined,
            CameraId: camera.Id!,
            CameraViewName: presetName,
            PtzPanPos: photoSnapShotByPTZ.PTZ?.PtzPanPos!,
            PtzZoomPos: photoSnapShotByPTZ.PTZ?.PtzZoomPos!,
            PtzTiltPos: photoSnapShotByPTZ.PTZ?.PtzTiltPos!,
        };
        cameraViewController
            .upsert(_filter)
            .then((res) => {
                pushSuccess('Config camera view successfully');
                setCamera((prevCamera) => {
                    const updatedCameraViews =
                        prevCamera.CameraViews?.map((view) => (view.Id === res.Id ? res : view)) ?? [];

                    if (!updatedCameraViews.some((view) => view.Id === res.Id)) {
                        updatedCameraViews.push(res);
                    }

                    return {
                        ...prevCamera,
                        CameraViews: updatedCameraViews,
                    };
                });

                popUpConfigViewCameraPTZ.onClose();
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            })
            .finally(() => {
                setIsLoadingSave(false);
            });
    };

    useEffect(() => {
        if (filteredCamera && Object.keys(filteredCamera).length > 0) {
            if (camera.PTZCameraId !== null) {
                handleGetDetailsCameraPTZ();
            }
        }
    }, [filteredCamera]);

    const handleGetDetailsCameraPTZ = async () => {
        setIsLoadingDetailsCameraPTZ(true);
        await cameraPTZConfigureRequestHttpController
            .getPTZSystemInfo(filteredCamera)
            .then((res) => {
                setCameraDetailsPTZ(res);
            })
            .catch((err) => {
                pushError(err.response.data.message);
            })
            .finally(() => {
                setIsLoadingDetailsCameraPTZ(false);
            });
    };

    const handleSetOriginalPhoto = async () => {
        backdrop.setTrue();
        await cameraController
            .setOriginalPhoto({
                CameraId: camera.Id!,
                CameraPhotoId: latestBlobPhoto?.[0].Id!,
            })
            .then(async (res) => {
                await refetchTrailAuditEvent();
                pushSuccess('Original photo has been updated');
                setCamera((prev) => ({ ...prev, OriginalPhoto: res }));
            })
            .catch(() => {})
            .finally(() => {
                backdrop.setFalse();
            });
    };

    const getTrailAuditEvent = async (): Promise<Paging<CameraTrailAuditEvent>> => {
        const response = await cameraTrailAuditEventHttpController.list({
            page: filterTrailAuditEvent.page ?? 1,
            From: filterTrailAuditEvent.from ?? undefined,
            To: filterTrailAuditEvent.to ?? undefined,
            pageSize: 10,
            search: {
                content: filterTrailAuditEvent.search ?? '',
                fields: ['UpdatedBy'],
            },
            filter: {
                CameraId: Number(params.id)!,
                AuditEventType: filterTrailAuditEvent.eventLog?.Value,
            },
            sorts: filterSortStateTrailAuditEvent.sorts as any,
        });
        return response;
    };

    const {
        data: trailAuditEvent,
        error,
        isLoading: isLoadingTrailAuditEvent,
        isFetching,
        refetch: refetchTrailAuditEvent,
    } = useQuery<Paging<CameraTrailAuditEvent>, AxiosError>(
        [
            'trailAuditEvent',
            params.id,
            filterTrailAuditEvent.page,
            filterTrailAuditEvent.from,
            filterTrailAuditEvent.to,
            filterTrailAuditEvent.eventLog,
            filterSortStateTrailAuditEvent.sorts,
            debouncedValue,
        ],
        getTrailAuditEvent,
        {
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            onError: (err) => {
                pushError(err?.message);
            },
        }
    );

    useEffect(() => {
        const updateStageSize = () => {
            if (containerRef.current) {
                const width = containerRef.current.offsetWidth;
                const height = width * PREVIEW_RATIO;
                setContainerSize({ width, height });
            } else {
                setTimeout(updateStageSize, 300);
            }
        };

        if (open) {
            setTimeout(updateStageSize, 300);
        } else {
            updateStageSize();
        }
        window.addEventListener('resize', updateStageSize);

        return () => {
            window.removeEventListener('resize', updateStageSize);
        };
    }, [open]);

    return {
        isLoading,
        isFetching,
        isLoadingTrailAuditEvent,
        trailAuditEvent,
        breadcrumbs,
        navigate,
        camera,
        setCamera,
        value,
        handleChangeTabs,
        loading,
        setLoading,
        isLoadingSave,
        setIsLoadingSave,
        wakeUpLoading,
        setWakeUpLoading,
        openIMGPreview,
        setOpenIMGPreview,
        hoveredFrame,
        setHoveredFrame,
        containerSize,
        setContainerSize,
        isLoadingDetailsCameraPTZ,
        setIsLoadingDetailsCameraPTZ,
        cameraDetailsPTZ,
        setCameraDetailsPTZ,
        anchorElMenuLists,
        imageElement,
        setImageElement,
        filteredCamera,
        photoSnapShotByPTZ,
        setPhotoSnapShotByPTZ,
        listGeoZonesByView,
        filteredListGeoZones,
        setFilteredListGeoZones,
        filterTrailAuditEvent,
        setFilterTrailAuditEvent,
        filterSortStateTrailAuditEvent,
        setFilterSortStateTrailAuditEvent,

        imageLatest,
        imageOriginal,
        blobLatestPhoto,
        blobOriginalPhoto,
        notAvailable,
        tabs,
        prevGeoZonesCameraSolar,
        scaleNotFullLatestCameraSolar,
        scaleFullLatestCameraSolar,
        scaledPrevGeoZoneCameraSolar,
        scaleNotFullOriginalCameraSolar,
        scaleFullOriginalCameraSolar,
        scaleNotFullCameraPTZ,
        scaleFullCameraPTZ,
        prevGeoZonesCameraPTZ,
        scaledPrevGeoZoneCameraPTZ,
        widthImage,
        timeZone,
        captureAt,
        openMenuLists,
        containerRef,

        refetchTrailAuditEvent,
        handleAddExemption,
        handleDeleteExemption,
        handleUpdateCamera,
        handleClickImagePreview,
        handleRefreshImage,
        handleDeleteGeoZonesOverView,
        handleDeleteGeoZonesDetail,
        handleMouseMove,
        handleResetStyledMenu,
        handleUpsertView,
        handleSetOriginalPhoto,
        setAnchorElMenuLists,
        setPartialTrailAuditEvent,

        popUpDeleteGeoZonesOverview,
        popUpConfigViewCameraPTZ,

        // setSystemTime,
        // enableValueInContinuousCapture,
        // cellularSettings,
        // setStatus,
        // setSettingsExpected,
        // systemTimeMilesight,
    };
}

type CameraDetailProviderContextType = ReturnType<typeof useCameraDetailProvider>;

export const CameraDetailContext = createContext<CameraDetailProviderContextType>(
    {} as CameraDetailProviderContextType
);

export const useCameraDetailContext = () => useContext(CameraDetailContext);
