import { cameraNotificationHttpController } from '@controllers/index';
import { Paging } from '@Core';
import { CameraNotification, CameraNotificationStatus } from '@EcamModel/model/CameraNotification';
import { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';

export enum TabNotification {
    All,
    Unread,
}

const PAGE_DEFAULT = 1;
const COUNT_NOTIFICATION_DEFAULT = 0;
const timeFectching = 5 * 60 * 1000; // s

export default function useNotificationContext() {
    const [tabValue, setTabValue] = useState(TabNotification.All);
    const [listNotification, setListNotification] = useState({} as Paging<CameraNotification>);
    const [notificationUnread, setNotificationUnread] = useState(COUNT_NOTIFICATION_DEFAULT);
    const [page, setPage] = useState(PAGE_DEFAULT);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!open) return;
        initializeNotifications();
    }, [open, tabValue]);

    const getNotifications = async (page: number) => {
        if (open) {
            page == PAGE_DEFAULT && setLoading(true);
            await cameraNotificationHttpController
                .getListCameraNotificationByUser({
                    pageSize: 10,
                    page,
                    sorts: ['-Created'],
                    filter: {
                        Status: tabValue === TabNotification.Unread ? CameraNotificationStatus.Unread : undefined,
                    },
                })
                .then((res) => {
                    setListNotification((prev) => ({
                        ...res,
                        rows: prev.rows?.length ? prev.rows?.concat(res.rows) : res.rows,
                    }));
                })
                .finally(() => setLoading(false));
        }
    };

    const getCountNotificationUnread = async () => {
        const res = await cameraNotificationHttpController.getCameraNoticationUnReadCount();
        return res || 0;
    };

    useQuery('cameraNotificationUnRead', getCountNotificationUnread, {
        refetchInterval: timeFectching,
        enabled: !open,
        staleTime: Infinity,
        onSuccess: (data) => {
            setNotificationUnread(data);
        },
    });

    const markAllRead = async () => {
        await cameraNotificationHttpController.markAllCameraNotificationsAsRead().then((res) => {
            if (res) {
                setNotificationUnread(COUNT_NOTIFICATION_DEFAULT);
                tabValue === TabNotification.Unread ? setTabValue(TabNotification.All) : initializeNotifications();
            }
        });
    };

    const markRead = (ids: number[], setStatus?: boolean) => {
        cameraNotificationHttpController.markCameraNotificationsAsRead({ CameraNotificationIds: ids }).then((res) => {
            if (res) {
                setNotificationUnread((prev) => prev - 1);
                setStatus &&
                    ids.forEach((id) =>
                        setListNotification((prev) => ({
                            ...prev,
                            rows: prev?.rows.map((item) =>
                                item?.Id === id ? { ...item, Status: CameraNotificationStatus.Read } : item
                            ),
                        }))
                    );
            }
        });
    };

    const initializeNotifications = async () => {
        setListNotification({} as Paging<CameraNotification>);
        setPage(PAGE_DEFAULT);

        getNotifications(PAGE_DEFAULT);

        const count = await getCountNotificationUnread();
        setNotificationUnread(count);
    };

    const handleClose = () => {
        setOpen(false);
        setTabValue(TabNotification.All);
    };

    return {
        loading,
        open,
        notificationUnread,
        listNotification,
        page,
        setPage,
        tabValue,
        setTabValue,
        setOpen,
        markRead,
        markAllRead,
        getNotifications,
        getCountNotificationUnread,
        handleClose,
    };
}

type NotificationProviderContextType = ReturnType<typeof useNotificationContext>;

export const NotificationContext = createContext<NotificationProviderContextType>(
    {} as NotificationProviderContextType
);

export const useNotification = () => useContext(NotificationContext);
