import { CameraNotificationStatus } from '@EcamModel/model/CameraNotification';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useNotification } from '../hooks/NotificationProvider';
import LayoutNotificationItem, { NotificationItemProps } from './LayoutNotificationItem';

export default function TaskNotificationItem(props: NotificationItemProps) {
    const navigate = useNavigate();
    const { markRead, handleClose } = useNotification();
    const { item, index } = props;

    const isUnread = item?.Status === CameraNotificationStatus.Unread;

    const handleMarkRead = () => {
        isUnread && markRead([item?.Id!]);
        handleClose();
        navigate(`/task-management`, {
            state: { cameraName: item?.Camera?.Name, created: item?.Created },
        });
    };

    return (
        <LayoutNotificationItem
            handleMarkRead={handleMarkRead}
            index={index}
            item={item}
            children={<Typography>{item?.Notes}</Typography>}
        />
    );
}
