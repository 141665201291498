import React, { SVGProps } from 'react';

type Props = {} & SVGProps<SVGSVGElement>;

export default function TaskIcon(props: Props) {
    return (
        <svg
            width={props.width ?? 17}
            height={props.height ?? 16}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                stroke={props.color ?? '#85858A'}
                d="M12.37 8.88h5.25m-11.24 0 .75.75 2.25-2.25m2.99 8.5h5.25m-11.24 0 .75.75 2.25-2.25"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                stroke={props.color ?? '#85858A'}
                d="M9 22h6c5 0 7-2 7-7V9c0-5-2-7-7-7H9C4 2 2 4 2 9v6c0 5 2 7 7 7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    );
}
