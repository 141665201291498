import { BaseHttpController } from '@Core/controller/BaseHttpController';
import { ThresholdSetting } from '@EcamModel/model/ThresholdSetting';
import { AxiosInstance } from 'axios';
import { IThresholdSettingHttpController } from '../IThresholdSettingHttpController';

export class ThresholdSettingHttpController
    extends BaseHttpController<ThresholdSetting>
    implements IThresholdSettingHttpController
{
    constructor(serviceUrl: string, client: AxiosInstance) {
        super(serviceUrl, 'threshold-setting', client);
    }

    async getThresholdSetting(): Promise<ThresholdSetting> {
        return await this.doGet({ path: 'get-threshold-setting' }).then((res) => res.data);
    }

    async createThresholdSetting(props: ThresholdSetting): Promise<ThresholdSetting> {
        return await this.doPost({ path: 'create-threshold-setting', body: props }).then((res) => res.data);
    }
}
