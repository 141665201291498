import { BaseModel } from '@Core';
import { TypeInfo } from './Solar4gCamera';

export interface Solar4gImageInfo extends BaseModel {
    DevMac: string;

    DevName: string;

    Brightness: number;

    Contrast: number;

    Saturation: number;

    Sharpness: number;

    Denoise: number;

    DnMode: DnMode;

    DayToNight: number;

    NightToDay: number;

    IrStrength: number;

    InfoType: TypeInfo;
}

export enum DnMode {
    Auto,
    Day,
    Night,
    Schedule
}