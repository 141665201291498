/* eslint-disable react-hooks/exhaustive-deps */
import { Paging } from '@Core';
import {
    CameraType,
    CameraUpdatedToday,
    IGetCameraProps,
    SortByField,
    SortByValue,
} from '@EcamModel/controllers/ICameraHttpController';
import {
    BatteryStatus,
    CameraPriority,
    CameraWithInfo,
    CaptureInfoUploadMode,
    Location,
    PowerSupply,
    PurposeType,
    Status,
    WifiStatus,
} from '@EcamModel/model';
import { ViewPreset } from '@EcamModel/model/CameraView';
import { Zones } from '@EcamModel/model/Zones';
import BaseListRender from '@components/BaseListRender';
import BreadCrumbs, { IBreadCrumbs } from '@components/breadcumbs/BreadCumbs';
import StyledDateRangePicker from '@components/date-range-select/StyledDateRangePicker';
import FilterRoot from '@components/filter/FilterRoot';
import IMultiSelectWithCheckBox from '@components/multi-select/IMultiSelectWithCheckBox';
import StyledAutocomplete from '@components/styled-autocomplete';
import useStyledAutocomplete from '@components/styled-autocomplete/useStyledAutocomplete';
import BaseCustomTabs from '@components/tabs/BaseCustomTabs';
import { TabConfig } from '@components/tabs/BaseEnhancedTabs';
import { pushError, pushSuccess } from '@components/toast';
import { cameraController, locationController } from '@controllers/index';
import useScrollToTop from '@hooks/useScrollToTop';
import { SelectionItem } from '@models/cameras/SelectionItem';
import { MultiSearch } from '@models/search-model/SearchModel';
import { Box, Button, Divider, Grid, Stack, TextField, Typography, styled } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
import { SelectWithType } from '@pages/overview/components/GraphReportsAnalyzingCriteriaAndPCN';
import { ReportsAnalyzingPcnWithData } from '@pages/overview/hook/ReportsAnalyzingCriteriaAndPcnProvider';
import { CustomTabPanel, a11yProps } from '@pages/overview/tabs/useTabsData';
import color from '@theme/Colors';
import _, { debounce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useRef, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { filterUndefinedParams } from 'src/helpers';
import CameraItem from './components/CameraItem';
import SearchTypeSelect from './components/SearchTypeSelect';
import { search } from './helpers';
import { ContraventionAuditEventSelection } from '@pages/verification/pc-details/components/recordedPCNEventLog/configs';

export interface FEFilterOptions {
    page?: number;
    pagePCNsByContraventionDate?: number;
    pageReasonCancellation?: number;
    pageSize?: number;
    search?: MultiSearch;
    location?: Location;
    zones?: Zones[];
    status?: SelectionItem;
    battery?: SelectionItem;
    connection?: SelectionItem;
    emailVerifier?: string;
    emailAuditor?: string;
    auditStatus?: BaseSelect;
    VerificationDateFrom?: Date;
    VerificationDateTo?: Date;
    sortedOrder?: BaseSelect;
    updated?: SelectionItem;
    from?: Date;
    to?: Date;
    sort?: BaseSelect;
}

enum CameraSearchType {
    Name,
    Mac,
}

export type Filter = {
    page: number;
    search?: string;
    searchType?: BaseSelect;
    location?: Location;
    firmware?: BaseSelect;
    zones?: Zones[];
    status?: BaseSelect;
    battery?: BaseSelect;
    powerSupply?: BaseSelect;
    cameraPriority?: BaseSelect;
    connection?: BaseSelect;
    sort?: BaseSelect;
    sortedOrder?: BaseSelect;
    updated?: BaseSelect;
    from?: Date;
    to?: Date;
    fromStart?: Date;
    toStart?: Date;
    fromEnd?: Date;
    toEnd?: Date;
    camVersion?: BaseSelect;
    uploadMode?: BaseSelect;
    eventLog?: BaseSelect;
    verifierEventLog?: ContraventionAuditEventSelection;
    camPurposes?: BaseSelect;
    locationStatus?: BaseSelect;
    locationPriorities?: BaseSelect;
    serviceType?: BaseSelect;
    ping?: BaseSelect;
    perspectiveView?: Partial<ViewPreset>;
    perspectiveDetailView?: Partial<ViewPreset>;
    reportAnalyzing?: SelectWithType<ReportsAnalyzingPcnWithData>;
};

export const initPaging = {
    page: 1,
    pageSize: 10,
    rows: [],
    total: 0,
    totalPages: 0,
};

export type BaseSelect = {
    Id: number;
    Name: string;
    Value: any;
};

const camerasSearchTypes: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Name',
        Value: CameraSearchType.Name,
    },
    {
        Id: 2,
        Name: 'MAC',
        Value: CameraSearchType.Mac,
    },
];

const cameraSortByType: BaseSelect[] = [
    {
        Id: 4,
        Name: 'Camera Name',
        Value: SortByField.CameraName,
    },
    {
        Id: 1,
        Name: 'Zone',
        Value: SortByField.Zone,
    },
    {
        Id: 2,
        Name: 'Last Contact',
        Value: SortByField.Updated,
    },
    {
        Id: 3,
        Name: 'Battery Level',
        Value: SortByField.BatteryLevel,
    },
];

export const cameraSortByStatus: BaseSelect[] = [
    {
        Id: 1,
        Name: 'ASC',
        Value: SortByValue.Asc,
    },
    {
        Id: 2,
        Name: 'DESC',
        Value: SortByValue.Desc,
    },
];

const filterUpdated: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Not updated today',
        Value: CameraUpdatedToday.Updated,
    },
];

const statuses: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Not available',
        Value: Status.NotAvailable,
    },
    {
        Id: 2,
        Name: 'Active',
        Value: Status.Active,
    },
    {
        Id: 3,
        Name: 'Inactive',
        Value: Status.Inactive,
    },
];
export const purposes: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Decommissioned',
        Value: PurposeType.Decommissioned,
    },
    {
        Id: 2,
        Name: 'Live',
        Value: PurposeType.Live,
    },
    {
        Id: 3,
        Name: 'On hold',
        Value: PurposeType.OnHold,
    },
    {
        Id: 4,
        Name: 'Testing',
        Value: PurposeType.Testing,
    },
    {
        Id: 5,
        Name: 'Prospective',
        Value: PurposeType.Prospective,
    },
    {
        Id: 6,
        Name: 'Deleted',
        Value: PurposeType.Deleted,
    },
];

const connections: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Connected',
        Value: WifiStatus.Connected,
    },
    {
        Id: 2,
        Name: 'Disconnected',
        Value: WifiStatus.DisConnected,
    },
];

const batteries: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Critical (0%-20%)',
        Value: BatteryStatus.Critical,
    },
    {
        Id: 2,
        Name: 'Low (21%-50%)',
        Value: BatteryStatus.Low,
    },
    {
        Id: 3,
        Name: 'Normal (51%-100%)',
        Value: BatteryStatus.Normal,
    },
];

export const powerSupplies: BaseSelect[] = [
    {
        Id: 1,
        Name: '45W/180Wh',
        Value: PowerSupply.Kit180Wh,
    },
    {
        Id: 2,
        Name: 'Hybrid',
        Value: PowerSupply.Hybrid,
    },
    {
        Id: 3,
        Name: '100W/555Wh',
        Value: PowerSupply.Kit555Wh,
    },
    {
        Id: 4,
        Name: '100W/962Wh',
        Value: PowerSupply.Kit962Wh,
    },
    {
        Id: 5,
        Name: 'Wired',
        Value: PowerSupply.Wired,
    },
];

export const priorities: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Low',
        Value: CameraPriority.Low,
    },
    {
        Id: 2,
        Name: 'Medium',
        Value: CameraPriority.Medium,
    },
    {
        Id: 3,
        Name: 'High',
        Value: CameraPriority.High,
    },
];

const uploadMode: BaseSelect[] = [
    {
        Id: 1,
        Name: 'Immediacy',
        Value: CaptureInfoUploadMode.IMMEDIACY,
    },
    {
        Id: 2,
        Name: 'Timing',
        Value: CaptureInfoUploadMode.TIMING,
    },
];

export const mappingCameraTypes = {
    [CameraType.PTZ]: 'Camera PTZ',
    [CameraType.Solar]: 'Camera Solar',
};

const initLoading = { location: true, cameras: true };

type CountCamerasInLocation = {
    cameraPTZ?: number;
    cameraSolar?: number;
};

export default function Cameras() {
    const navigate = useNavigate();
    useScrollToTop();
    const prevFilterRef = useRef<IGetCameraProps | null>(null);

    const [loadingState, setLoadingState] = useState<LoadingState>({ ...initLoading });
    const [pagingCameraSolar, setPagingCameraSolar] = useState<Paging<CameraWithInfo>>({ ...initPaging });
    const [pagingCameraPTZ, setPagingCameraPTZ] = useState<Paging<CameraWithInfo>>({ ...initPaging });
    const [zones, setZones] = useState<Zones[]>([]);

    const [countCamerasInLocation, setCountCamerasInLocation] = useState<CountCamerasInLocation>({
        cameraPTZ: 0,
        cameraSolar: 0,
    });

    const [nameLocation, setNameLocation] = useState<string>('');
    const [filter, setFilter] = useState<Filter>({
        page: 1,
        sortedOrder: cameraSortByStatus[0],
        sort: cameraSortByType[0],
        searchType: camerasSearchTypes[0],
    });
    const [searchValue, setSearchValue] = useState('');

    const setPartialLoading = (loadings: Partial<LoadingState>) => {
        setLoadingState((prev) => ({ ...prev, ...loadings }));
    };

    const statusAutocomplete = useStyledAutocomplete({
        list: {
            options: statuses,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const connectionsAutocomplete = useStyledAutocomplete({
        list: {
            options: connections,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const batteryAutocomplete = useStyledAutocomplete({
        list: {
            options: batteries,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const powerSuppliesAutocomplete = useStyledAutocomplete({
        list: {
            options: powerSupplies,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const cameraPrioritiesAutocomplete = useStyledAutocomplete({
        list: {
            options: priorities,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const updatedAutocomplete = useStyledAutocomplete({
        list: {
            options: filterUpdated,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const uploadModeAutocomplete = useStyledAutocomplete({
        list: {
            options: uploadMode,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });

    const purposesAutocomplete = useStyledAutocomplete({
        list: {
            options: purposes,
            isFiltered: (option, searchText) => search(option.Name, searchText),
        },
    });
    const { locationId } = useParams();

    const [searchParams, setSearchParams] = useSearchParams({});

    const page = searchParams.get('page');

    const cameraType = Number(searchParams.get('cameraType'));

    const searchBy = searchParams.get('searchBy');
    const searchType = searchParams.get('searchType');
    const _searchType = camerasSearchTypes.find((s) => s.Id === Number(searchType)) || camerasSearchTypes[0];

    const sortType = searchParams.get('sortType');
    const _sortType = cameraSortByStatus.find((c) => c.Id === Number(sortType));

    const sortBy = searchParams.get('sortBy');
    const _sortBy = cameraSortByType.find((c) => c.Id === Number(sortBy));

    // const locationId = searchParams.get('locationId');

    const zoneIds = searchParams.get('zoneIds');

    const lastContact = searchParams.get('lastContact');
    const _lastContact = filterUpdated.find((u) => u.Id === Number(lastContact));

    const from = searchParams.get('from');
    const to = searchParams.get('to');

    const status = searchParams.get('status');
    const _status = statuses.find((s) => s.Id === Number(status));

    const connection = searchParams.get('connection');
    const _connection = connections.find((s) => s.Id === Number(connection));

    const battery = searchParams.get('battery');
    const _battery = batteries.find((s) => s.Id === Number(battery));

    const powerSupply = searchParams.get('powerSupply');
    const _powerSupply = powerSupplies.find((ps) => ps.Id === Number(powerSupply));

    const cameraPriority = searchParams.get('cameraPriority');
    const _cameraPriority = priorities.find((ps) => ps.Id === Number(cameraPriority));

    const mode = searchParams.get('mode');
    const _uploadMode = uploadMode.find((s) => s.Id === Number(mode));

    const purposesCam = searchParams.get('purposesCam');
    const _purposes = purposes.find((s) => s.Id === Number(purposesCam));

    const [isDeleteCamera, setIsDeleteCamera] = useState<boolean>(false);

    const handleChangeParams = (params: any) => {
        const _searchParams: any = {
            page: 1,
            searchType,
            searchBy,
            sortType,
            sortBy,
            // locationId,
            zoneIds,
            lastContact,
            from,
            to,
            status,
            connection,
            battery,
            powerSupply,
            cameraPriority,
            mode,
            purposesCam,
            cameraType,
            ...params,
        };

        Object.entries(_searchParams).forEach(([key, value]) => {
            const firstPage = key === 'page' && Number(value) === 1;
            // if (!_searchParams['locationId']) delete _searchParams['zoneIds'];
            if (!value || firstPage) delete _searchParams[key];
        });

        setSearchParams(new URLSearchParams(_searchParams));
    };

    const resetSearchParams = (newValue: number) => {
        setSearchValue(searchType === '2' ? searchValue : '');

        const newSearchParams = filterUndefinedParams({
            ...searchParams,
            status: statuses[1].Id.toString(),
            cameraType: newValue.toString(),
            searchBy: searchType === '2' ? searchBy?.toString() : undefined,
            searchType: searchType === '2' ? searchType : undefined,
        });

        setSearchParams(newSearchParams);

        const newFilter = {
            status: _status || statuses[1],
            sortedOrder: cameraSortByStatus[0],
            sort: cameraSortByType[0],
            searchType: searchType === '2' ? camerasSearchTypes[1] : camerasSearchTypes[0],
        } as Filter;

        setFilter(newFilter);
    };

    const [valueTabs, setValueTabs] = useState<number>(cameraType);

    const handleChangeTabs = (event: React.SyntheticEvent, newValue: number) => {
        handleChangeParams({ cameraType: newValue });
        setValueTabs(newValue);
        resetSearchParams(newValue);
    };

    const debSearchClaim = useCallback(
        debounce((nextValue) => {
            setFilter((prev) => ({ ...prev, search: nextValue, page: 1 }));
        }, 300),
        []
    );
    const handleDebSearchClaim = (e: any) => {
        const value = e.target.value;
        setSearchValue(value);
        debSearchClaim(value);
    };

    const getCameras = useCallback(
        async (props?: IGetCameraProps) => {
            setPartialLoading({ cameras: true });

            const _filter: IGetCameraProps = {
                page: Number(page) || 1,
                LocationId: Number(locationId) || undefined,
                ZoneIds: !!zoneIds ? zoneIds.split(',').map(Number) : undefined,
                NotUpdatedToday: Number(lastContact) || undefined,
                pageSize: 10,
                From: !!from ? moment(from).startOf('d').toDate() : undefined,
                To: !!to ? moment(to).startOf('d').toDate() : undefined,
                Battery: _battery?.Value,
                Wifi: _connection?.Value,
                filter: {
                    Status: _status?.Value,
                    PurposeType: _purposes?.Value,
                    PowerSupply: _powerSupply?.Value,
                    Priority: _cameraPriority?.Value,
                },
                SortByField: _sortBy?.Value ?? SortByField.CameraName,
                SortByValue: _sortType?.Value ?? SortByValue.Asc,
                search: {
                    fields: _searchType?.Value === CameraSearchType.Name ? ['Name'] : [],
                    content: _searchType?.Value === CameraSearchType.Name ? searchBy! : '',
                },
                Mac: _searchType?.Value === CameraSearchType.Mac ? searchBy! : undefined,
                UploadMode: _uploadMode?.Value,
                ..._.omit(props),
            };

            // if (_.isEqual(prevFilterRef.current, _filter)) {
            //     setPartialLoading({ cameras: false });
            //     return;
            // }
            // prevFilterRef.current = _filter;

            const getCamerasController: Promise<Paging<CameraWithInfo>> =
                valueTabs === CameraType.Solar
                    ? cameraController.list(_filter)
                    : cameraController.listPTZCamera(_filter);

            await getCamerasController
                .then((res) => {
                    valueTabs === CameraType.Solar ? setPagingCameraSolar(res) : setPagingCameraPTZ(res);
                })
                .finally(() => setPartialLoading({ cameras: false }));
        },
        [
            page,
            locationId,
            zoneIds,
            lastContact,
            from,
            to,
            battery,
            powerSupply,
            cameraPriority,
            connection,
            status,
            sortBy,
            sortType,
            searchBy,
            searchType,
            mode,
            purposesCam,
            cameraType,
            valueTabs,
        ]
    );

    const handleResetFilter = () => {
        setSearchValue('');
        setFilter({
            sortedOrder: cameraSortByStatus[0],
            sort: cameraSortByType[0],
            searchType: camerasSearchTypes[0],
            status: statuses[1],
        } as Filter);

        const newParams = {
            status: statuses[1].Id.toString(),
        };
        setSearchParams(newParams);
    };

    const onChangeStatus = (id: number, checked: boolean) => {
        if (valueTabs === CameraType.Solar) {
            const paging = { ...pagingCameraSolar };
            paging.rows.forEach((c) => {
                if (c.Id === id) {
                    c.Status = checked ? Status.Active : Status.Inactive;
                }
            });
            setPagingCameraSolar({ ...paging });
        } else {
            const paging = { ...pagingCameraPTZ };
            paging.rows.forEach((c) => {
                if (c.Id === id) {
                    c.Status = checked ? Status.Active : Status.Inactive;
                }
            });
            setPagingCameraPTZ({ ...paging });
        }
    };

    const onChangeVerification = (cameraId: number, checked: boolean) => {
        if (valueTabs === CameraType.Solar) {
            const paging = { ...pagingCameraSolar };
            paging.rows.forEach((c) => {
                if (c.Id === cameraId) {
                    c.IsVerificationsEnabled = checked;
                }
            });
            setPagingCameraSolar({ ...paging });
        } else {
            const paging = { ...pagingCameraPTZ };
            paging.rows.forEach((c) => {
                if (c.Id === cameraId) {
                    c.IsVerificationsEnabled = checked;
                }
            });
            setPagingCameraPTZ({ ...paging });
        }
    };

    const onDeleteCamera = (id: number) => {
        cameraController.delete(id.toString()).then((res) => {
            setPagingCameraSolar((prev) => ({
                ...prev,
                rows: [...prev.rows?.filter((item) => item.Id !== id)],
            }));
            pushSuccess('Delete camera successful');
            handleResetFilter();
            setIsDeleteCamera(true);
        });
    };

    useEffect(() => {
        getCameras({});
    }, [
        filter.page,
        filter.search,
        filter.sort,
        filter.sortedOrder,
        filter.zones,
        filter.updated,
        filter.from,
        filter.to,
        filter.status,
        filter.connection,
        filter.battery,
        filter.powerSupply,
        filter.cameraPriority,
        filter.uploadMode,
        filter.camPurposes,
        cameraType,
        valueTabs,
    ]);

    useEffect(() => {
        if (filter.search) {
            getCameras({});
        }
    }, [filter.searchType]);

    useEffect(() => {
        locationController
            .listHasCamera({
                filter: {
                    Id: parseInt(locationId!),
                },
            })
            .then((res) => {
                const _location = res.rows?.find((l) => l.Id === parseInt(locationId!));
                const convertedZones = zoneIds?.split(',').map(Number);
                const _zones = _location?.Zones?.filter((z) => convertedZones?.includes(z.Id!));
                const initialZone = !!zones.length ? _zones : _location?.Zones || ([] as Zones[]);
                setZones(initialZone!);

                const _filter: Partial<Filter> = {
                    location: _location || undefined,
                    zones: _zones || undefined,
                    page: page ? Number(page) : 1,
                    updated: _lastContact || undefined,
                    from: moment(from).startOf('d').toDate(),
                    to: moment(to).endOf('d').toDate(),
                    status: _status || statuses[1],
                    connection: _connection || undefined,
                    battery: _battery || undefined,
                    sortedOrder: _sortType || cameraSortByStatus[0],
                    sort: _sortBy || cameraSortByType[0],
                    searchType: _searchType || camerasSearchTypes[0],
                    uploadMode: _uploadMode || undefined,
                    camPurposes: _purposes || undefined,
                    powerSupply: _powerSupply || undefined,
                    cameraPriority: _cameraPriority || undefined,
                };

                if (!from) delete _filter.from;
                if (!to) delete _filter.to;

                setFilter((prev) => ({
                    ...prev,
                    ..._filter,
                }));
            });

        setSearchValue(searchBy || '');
        handleChangeParams({
            ...searchParams,
            status: _status?.Id || statuses[1].Id,
            cameraType: valueTabs.toString(),
        });
    }, []);

    const breadcrumbs: IBreadCrumbs[] = [
        { title: 'Location list', href: '/locations' },
        { title: `${nameLocation}`, onBack: false, isColorBlackText: true },
    ];

    const tabs: TabConfig[] = [
        {
            label: `Camera Solar (${countCamerasInLocation.cameraSolar ?? 0})`,
            ...a11yProps(0),
            index: 0,
        },
        {
            label: `Camera PTZ (${countCamerasInLocation.cameraPTZ ?? 0})`,
            ...a11yProps(1),
            index: 1,
        },
    ];

    const filteredCameraSortByType =
        valueTabs === CameraType.PTZ
            ? cameraSortByType.filter((item) => item.Id !== 2 && item.Id !== 3)
            : cameraSortByType;

    const getCountCameras = async () => {
        await locationController
            .listHasLocation({
                filter: {
                    Id: Number(locationId),
                },
            })
            .then((res) => {
                setCountCamerasInLocation({
                    cameraSolar: res.rows[0].Solar4gCameraCount ?? 0,
                    cameraPTZ: res.rows[0].PTZCameraCount ?? 0,
                });
                setNameLocation(res.rows[0]?.Name ?? '');
            })
            .catch((err) => {
                pushError(err.response?.data?.message);
            });
    };

    useEffect(() => {
        getCountCameras();
    }, [isDeleteCamera]);

    return (
        <Stack direction={'column'}>
            {nameLocation && (
                <>
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                        <Typography variant="h3">Location: {nameLocation}</Typography>
                        <Button
                            variant="contained"
                            sx={{ minWidth: '150px' }}
                            onClick={() => navigate(`add/${valueTabs}`)}
                        >
                            Add camera
                        </Button>
                    </Stack>
                    <BreadCrumbs breadcrumbs={breadcrumbs} />
                </>
            )}

            <Box mt={2}>
                <BaseCustomTabs tabs={tabs} value={valueTabs} onChange={handleChangeTabs} />

                <FilterRoot
                    baseStyle={{
                        borderRadius: '0px 10px 10px 10px',
                    }}
                    handleResetFilter={() => handleResetFilter()}
                    rightTitleContent={
                        <Grid2 container spacing={2} xs={8}>
                            <Grid2 xs={7}>
                                <TextField
                                    sx={{ '& .MuiOutlinedInput-root': { paddingLeft: 0 } }}
                                    fullWidth
                                    label="Search by"
                                    placeholder="Search..."
                                    onChange={(e) => {
                                        handleDebSearchClaim(e);
                                        handleChangeParams({ searchBy: e.target.value, cameraType: valueTabs });
                                    }}
                                    value={searchValue}
                                    InputProps={{
                                        startAdornment: (
                                            <Stack direction="row">
                                                <SearchTypeSelect
                                                    label="Search by"
                                                    minWidth={80}
                                                    listData={camerasSearchTypes}
                                                    value={filter.searchType!}
                                                    keyEqual="Id"
                                                    keyLabel="Name"
                                                    handleChange={(e) => {
                                                        setFilter((prev) => ({ ...prev, searchType: e }));
                                                        handleChangeParams({ searchType: e.Id, cameraType: valueTabs });
                                                    }}
                                                />
                                                <Divider
                                                    orientation="vertical"
                                                    flexItem
                                                    sx={{
                                                        mx: '5px',
                                                        borderRightWidth: 1,
                                                        borderColor: color.grey600,
                                                    }}
                                                    variant="middle"
                                                />
                                            </Stack>
                                        ),
                                        endAdornment: (
                                            <FiSearch
                                                color="disabled"
                                                size={22}
                                                style={{ color: 'rgba(0, 0, 0, 0.38)', cursor: 'pointer' }}
                                            />
                                        ),
                                    }}
                                />
                            </Grid2>
                            <Grid2 xs={5}>
                                <SearchTypeSelect
                                    startAdornment={
                                        <Stack direction={'row'}>
                                            <SearchTypeSelect
                                                listData={cameraSortByStatus}
                                                value={filter.sortedOrder!}
                                                keyEqual="Id"
                                                keyLabel="Name"
                                                handleChange={(e) => {
                                                    setFilter((prev) => ({ ...prev, sortedOrder: e, page: 1 }));
                                                    handleChangeParams({ sortType: e?.Id, cameraType: valueTabs });
                                                }}
                                                label="Sort by"
                                                minWidth={60}
                                            />
                                            <Divider
                                                orientation="vertical"
                                                flexItem
                                                sx={{
                                                    mx: '5px',
                                                    borderRightWidth: 1,
                                                    borderColor: color.grey600,
                                                }}
                                                variant="middle"
                                            />
                                        </Stack>
                                    }
                                    listData={filteredCameraSortByType}
                                    value={filter.sort!}
                                    keyEqual="Id"
                                    keyLabel="Name"
                                    handleChange={(e) => {
                                        setFilter((prev) => ({ ...prev, sort: e, page: 1 }));
                                        handleChangeParams({ sortBy: e?.Id, cameraType: valueTabs });
                                    }}
                                    label="Sort by"
                                    minWidth={60}
                                />
                            </Grid2>
                        </Grid2>
                    }
                    filterChild={
                        <Grid container spacing={2}>
                            <Grid item xs={valueTabs === CameraType.Solar ? 4 : 6}>
                                <IMultiSelectWithCheckBox
                                    limitTags={3}
                                    label={'Zone'}
                                    keyId={'Id'}
                                    keyLabel={'PublicName'}
                                    placeHolder={'Select zone'}
                                    listData={zones}
                                    isDisable={!zones.length}
                                    value={filter.zones ?? []}
                                    setFilter={(option) => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            zones: option.length ? option : undefined,
                                            page: 1,
                                        }));
                                        const handlerIdsParam = option
                                            .map((item) => encodeURIComponent(item.Id!))
                                            .join(',');
                                        handleChangeParams({ zoneIds: handlerIdsParam, cameraType: valueTabs });
                                    }}
                                    chipSize={150}
                                />
                            </Grid>
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={4}>
                                    <StyledAutocomplete
                                        {...updatedAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Last contact"
                                        value={filter.updated}
                                        placeholder="None"
                                        onChange={(e) => {
                                            setFilter((prev) => ({ ...prev, updated: e, page: 1 }));
                                            handleChangeParams({
                                                lastContact: e?.Id,
                                                cameraType: valueTabs,
                                            });
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={valueTabs === CameraType.Solar ? 4 : 6}>
                                <StyledDateRangePicker
                                    value={{ startDate: filter.from, endDate: filter.to }}
                                    onChange={(value) => {
                                        setFilter((prev) => ({
                                            ...prev,
                                            from: value.startDate,
                                            to: value.endDate,
                                            page: 1,
                                        }));
                                        handleChangeParams({
                                            from: moment(value.startDate).startOf('d').toISOString(),
                                            to: moment(value.endDate).endOf('d').toISOString(),
                                            cameraType: valueTabs,
                                        });
                                    }}
                                    label={'Production date'}
                                    initialSettings={
                                        {
                                            // isInvalidDate: (date: Moment) => {
                                            //     return moment(date).isAfter();
                                            // },
                                        }
                                    }
                                />
                            </Grid>
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={4}>
                                    <StyledAutocomplete
                                        {...cameraPrioritiesAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Priority"
                                        value={filter.cameraPriority}
                                        placeholder="All priorities"
                                        onChange={(o) => {
                                            setFilter((prev) => ({ ...prev, cameraPriority: o, page: 1 }));
                                            handleChangeParams({ cameraPriority: o?.Id, cameraType: valueTabs });
                                        }}
                                    />
                                </Grid>
                            )}
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={4}>
                                    <StyledAutocomplete
                                        {...uploadModeAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Upload mode"
                                        value={filter.uploadMode}
                                        placeholder="All mode"
                                        onChange={(o) => {
                                            setFilter((prev) => ({ ...prev, uploadMode: o, page: 1 }));
                                            handleChangeParams({ mode: o?.Id, cameraType: valueTabs });
                                        }}
                                    />
                                </Grid>
                            )}
                            <Grid item xs={valueTabs === CameraType.Solar ? 4 : 6}>
                                <StyledAutocomplete
                                    {...purposesAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Purposes"
                                    value={filter.camPurposes}
                                    placeholder="All purposes"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, camPurposes: o, page: 1 }));
                                        handleChangeParams({ purposesCam: o?.Id, cameraType: valueTabs });
                                    }}
                                />
                            </Grid>
                            <Grid item xs={valueTabs === CameraType.Solar ? 3 : 6}>
                                <StyledAutocomplete
                                    {...statusAutocomplete}
                                    getOptionLabel={(o) => o.Name}
                                    label="Status"
                                    value={filter.status}
                                    placeholder="All status"
                                    onChange={(o) => {
                                        setFilter((prev) => ({ ...prev, status: o, page: 1 }));
                                        handleChangeParams({ status: o?.Id, cameraType: valueTabs });
                                    }}
                                />
                            </Grid>
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={3}>
                                    <StyledAutocomplete
                                        {...connectionsAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Connection"
                                        value={filter.connection}
                                        placeholder="All connections"
                                        onChange={(o) => {
                                            setFilter((prev) => ({ ...prev, connection: o, page: 1 }));
                                            handleChangeParams({ connection: o?.Id, cameraType: valueTabs });
                                        }}
                                    />
                                </Grid>
                            )}
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={3}>
                                    <StyledAutocomplete
                                        {...batteryAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Battery"
                                        value={filter.battery}
                                        placeholder="All levels"
                                        onChange={(o) => {
                                            setFilter((prev) => ({ ...prev, battery: o, page: 1 }));
                                            handleChangeParams({ battery: o?.Id, cameraType: valueTabs });
                                        }}
                                    />
                                </Grid>
                            )}
                            {valueTabs === CameraType.Solar && (
                                <Grid item xs={3}>
                                    <StyledAutocomplete
                                        {...powerSuppliesAutocomplete}
                                        getOptionLabel={(o) => o.Name}
                                        label="Power supply"
                                        value={filter.powerSupply}
                                        placeholder="All power supplies"
                                        onChange={(o) => {
                                            setFilter((prev) => ({ ...prev, powerSupply: o, page: 1 }));
                                            handleChangeParams({ powerSupply: o?.Id, cameraType: valueTabs });
                                        }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    }
                />
                <Stack mt={3}>
                    <Typography textAlign={'right'} sx={{ color: color.priText }}>
                        Total: {valueTabs === CameraType.Solar ? pagingCameraSolar.total : pagingCameraPTZ.total}
                    </Typography>
                </Stack>
            </Box>
            <CustomTabPanel value={valueTabs} index={0}>
                <BaseListRender
                    styleContainer={{ mt: 2, mb: 5 }}
                    styleList={{ gap: 2 }}
                    paging={pagingCameraSolar}
                    renderedItem={(camera) => (
                        <CameraItem
                            cameraItem={camera}
                            cameraType={valueTabs}
                            locationId={locationId}
                            onChangeStatus={onChangeStatus}
                            onDeleteCamera={onDeleteCamera}
                            onChangeVerification={onChangeVerification}
                        />
                    )}
                    isLoading={loadingState.cameras}
                    setPage={(page) => {
                        setFilter((prev) => ({ ...prev, page }));
                        handleChangeParams({ page: page, cameraType: valueTabs });
                    }}
                />
            </CustomTabPanel>

            <CustomTabPanel value={valueTabs} index={1}>
                <BaseListRender
                    styleContainer={{ mt: 2, mb: 5 }}
                    styleList={{ gap: 2 }}
                    paging={pagingCameraPTZ}
                    renderedItem={(camera) => (
                        <CameraItem
                            cameraItem={camera}
                            cameraType={valueTabs}
                            locationId={locationId}
                            onChangeStatus={onChangeStatus}
                            onDeleteCamera={onDeleteCamera}
                            onChangeVerification={onChangeVerification}
                        />
                    )}
                    isLoading={loadingState.cameras}
                    setPage={(page) => {
                        setFilter((prev) => ({ ...prev, page }));
                        handleChangeParams({ page: page, cameraType: valueTabs });
                    }}
                />
            </CustomTabPanel>
        </Stack>
    );
}

export interface LoadingState {
    location: boolean;
    cameras: boolean;
}
export const ContainerSort = styled(Grid)({
    background: '#FFF',
});

const LabeledContainer = ({ title, children }) => {
    return (
        <Box sx={{ position: 'relative', background: '#fff', border: '1px solid #ddd' }}>
            <label
                className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined MuiFormLabel-colorPrimary MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiInputLabel-outlined css-1hu70xq-MuiFormLabel-root-MuiInputLabel-root"
                data-shrink="true"
                htmlFor=":r5s:"
                id=":r5s:-label"
                // style={{ background: '#fff', width: '80px', textAlign: 'center' }}
            >
                Search by
            </label>

            {children}
        </Box>
    );
};
